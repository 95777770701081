export const initialState = {
    page: 0,
    rowsPerPage: 10,
    question: '',
    answer: ''
};

// ==============================|| AUTH REDUCER ||============================== //

const helpReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_PAGE_HELP':
            return {
                ...state,
                page: actions.payload
            };
        case 'SET_ROWS_PER_PAGE_HELP':
            return {
                ...state,
                rowsPerPage: actions.payload
            };
        case 'SET_NAMEQUESTION_HELP':
            return {
                ...state,
                question: actions.payload
            };
        case 'SET_NAMEANSWER_HELP':
            return {
                ...state,
                answer: actions.payload
            };
        case 'SET_CLEAR_HELP':
            return {
                ...state,
                answer: '',
                question: ''
            };
        default:
            return state;
    }
};

export default helpReducer;
