// assets
import { IconHome } from '@tabler/icons-react';

// constant
const icons = { IconHome };

// ==============================|| HOME MENU ITEMS ||============================== //

const utilities = {
    id: 'home',
    type: 'group',
    title: 'Inicio',
    titleName: true,
    children: [
        {
            id: 'index',
            title: 'Home',
            type: 'item',
            url: '/index',
            icon: icons.IconHome,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        }
    ]
};

export default utilities;
