import { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    Grid,
    TextField,
    useMediaQuery,
    MenuItem,
    Alert,
    Snackbar,
    Hidden
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import Loading from 'components/Loading/Loading';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import { getResourcerClass, postClass, updateClass, getClassPerId, deleteLearningObject } from 'services/class';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const Classe = ({ ...others }) => {
    const params = useParams();
    const themeButton = createTheme({
        status: {
            danger: '#e53e3e'
        },
        palette: {
            primary: {
                main: '#0971f1',
                darker: '#053e85'
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff'
            }
        }
    });
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
    };
    const opacity = {
        visibility: 'hidden'
    };
    const button = {
        display: 'block',
        width: '120px',
        height: '30px'
    };
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 58;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };
    const Demo = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.background.paper
    }));
    const initialStateOptions = {
        courses: []
    };
    const initialValuesEdit = {
        idCourse: params.id ? parseInt(params.id) : '',
        title: '',
        name: '',
        number: '',
        peso: '',
        time: '',
        learningObject: '',
        description: EditorState.createEmpty()
    };
    const refContainer = useRef(null);
    const theme = useTheme();
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [options, setOptions] = useState(initialStateOptions);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [showAddObject, setShowAddObject] = useState(false);
    const [errorUrl, setErrorUrl] = useState([]);
    const [typeObject, setTypeObject] = useState('');
    const [isURL, setIsURL] = useState(false);
    const [dense, setDense] = useState(false);
    const [errorValidate, setErrorValidate] = useState({});
    const [open, setOpen] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [valuesEdit, setValuesEdit] = useState(initialValuesEdit);
    const [inputFields, setInputFields] = useState([
        {
            url: [],
            file: [],
            type: []
        }
    ]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const titlePage = params.action === 'view' ? 'Visualização de Aula' : params.action === 'edit' ? 'Edição de Aula' : 'Cadastro de Aula';
    const isDisabled = params.action === 'view' ? true : false;
    useEffect(() => {
        getResource();
    }, []);
    useEffect(() => {
        viewPerId();
    }, [params.id]);

    function viewPerId() {
        params.action &&
            getClassPerId(params.id).then((resp) => {
                setValuesEdit({
                    ['idCourse']: resp.data.id_course,
                    ['title']: resp.data.title,
                    ['name']: resp.data.name,
                    ['description']: EditorState.createWithContent(
                        ContentState.createFromBlockArray(convertFromHTML(`${resp.data.description}`))
                    ),
                    ['number']: resp.data.number,
                    ['peso']: resp.data.peso,
                    ['time']: resp.data.time,
                    ['learningObject']: resp.data.learningObject
                });
            });
    }

    function getResource() {
        getResourcerClass().then((resp) => setOptions(resp.data));
    }

    const AddObject = () => setShowAddObject(true);
    const optionObject = (event) => {
        setTypeObject(event.target.value);
        const list = [...inputFields];
        const value = null;
        const name = 'url';
        list[0][name] = value;
        setInputFields(list);
    };
    const addInputField = (index, typeObject) => {
        setInputFields([
            ...inputFields,
            {
                url: [],
                file: [],
                type: []
            }
        ]);
    };
    const removeInputFields = (index) => {
        const rows = [...inputFields];
        const rowsUpload = [...uploadedFiles];
        rows.splice(index, 1);
        rowsUpload.splice(index, 1);
        setInputFields(rows);
        setUploadedFiles(rowsUpload);
    };
    const handleChangeInput = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
        setIsURL(true);
    };
    const handleChangeFile = (index, evnt) => {
        const value = evnt;
        const name = 'file';
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
        handleUpload(value);
    };
    const handleChangeType = (index, evnt) => {
        const value = evnt.target.value;
        const name = 'type';
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
    };
    const validationUrl = (index) => {};
    const handleUpload = (files) => {
        const file = [files];
        const uploadedFile = file.map((file) => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),

            progress: 0,
            uploaded: false,
            error: false,
            url: null
        }));
        setUploadedFiles(uploadedFiles.concat(uploadedFile));
    };
    const deleteLearningObjectById = (idDestroyLearningObject) => {
        deleteLearningObject(idDestroyLearningObject)
            .then((resp) => {
                setError('');
                setSuccess(resp.data.success);
                setTimeout(() => {
                    document.location.reload(true);
                    setSuccess('');
                }, 500);
            })
            .catch((e) => {
                console.log(e);
                setSuccess('');
                setError(e.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Olá,
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Insira somente URL do Youtube ou Vimeo
                    </Typography>
                </Box>
            </Modal>
            <Formik
                initialValues={{
                    idCourse: valuesEdit.idCourse,
                    title: valuesEdit.title,
                    name: valuesEdit.name,
                    number: valuesEdit.number,
                    peso: valuesEdit.peso,
                    time: valuesEdit.time,
                    description: valuesEdit.description,
                    learningObject: valuesEdit.learningObject,
                    submit: null
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    idCourse: Yup.number().required('Curso da aula é obrigatório'),
                    number: Yup.number().required('Número da aula é obrigatório'),
                    peso: Yup.number().required('Peso da aula é obrigatório'),
                    title: Yup.string().max(255).trim().required('Título obrigatório'),
                    name: Yup.string().max(255).trim().required('Tópico obrigatório')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                        if (typeObject == 1) {
                            let index = Object.keys(inputFields);
                            index.map((value) => {
                                if (
                                    inputFields[value].url.includes('https://www.youtube.com') ||
                                    inputFields[value].url.includes('https://vimeo.com/')
                                ) {
                                    const errors = [...errorUrl];
                                    errors[value] = null;
                                    setErrorUrl(errors);
                                    setDisableButton(false);
                                } else {
                                    throw 'Insira somente URL do Youtube ou Vimeo';
                                }
                            });
                        }
                        setLoading(true);
                        const data = new FormData();
                        let uploadedFile = uploadedFiles.filter((desc) => !desc.url);
                        let resultado = '';
                        let isFileUrl = false;
                        let attachment = false;
                        for (let i = 0; i < uploadedFile.length; i++) {
                            if (uploadedFile[i].file) {
                                data.append(`file${i}`, uploadedFile[i].file);
                                resultado = i;
                                attachment = true;
                            }
                        }
                        isURL == true && attachment == true ? (isFileUrl = true) : (isFileUrl = false);
                        data.append('idCourse', values.idCourse);
                        data.append('title', values.title);
                        data.append('name', values.name);
                        data.append('description', `${draftToHtml(convertToRaw(values.description.getCurrentContent()))}`);
                        data.append('number', values.number);
                        data.append('peso', values.peso);
                        data.append('time', values.time);
                        data.append('qtd', resultado);
                        data.append('isAttachment', attachment);
                        data.append('isFileUrl', isFileUrl);
                        data.append('learningObject', JSON.stringify(inputFields));
                        data.append('id_type', typeObject);
                        if (params.action === 'edit') {
                            data.append('_method', 'put');
                            updateClass(params.id, data, {
                                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                            })
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    viewPerId();
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        navigate('/aulas');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    setError(e.response.data.error);
                                });
                        } else {
                            postClass(data, {
                                headers: { 'content-type': 'application/json', 'Access-Control-Allow-Origin': '*' }
                            })
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        navigate('/aulas');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    if (e.response.data?.errorValidate?.file[0]) {
                                        setErrorValidate(e.response.data.errorValidate.file[0]);
                                    }
                                    setError(e.response.data.error);
                                });
                        }
                    } catch (err) {
                        console.error(err);
                        setError(err);
                        const errors = [...errorUrl];
                        errors[value] = 'A URL deve ser do Youtube ou Vimeo';
                        handleOpen();
                        setDisableButton(true);
                        setErrorUrl(errors);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <>
                        {loading && (
                            <Grid container alignItems="center" justifyContent="center">
                                <MainCard
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Loading color="#015641" type="spinningBubbles" />
                                </MainCard>
                            </Grid>
                        )}
                        <MainCard spacing={gridSpacing} style={{ padding: 15, margin: 25 }}>
                            {error || alert ? (
                                <Snackbar open={true} autoHideDuration={6000}>
                                    <Alert
                                        severity={error ? 'error' : success ? 'success' : ''}
                                        sx={{
                                            width: '100%',
                                            backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                            color: '#FFF'
                                        }}
                                    >
                                        {error ? error : success ? success : ''}
                                    </Alert>
                                </Snackbar>
                            ) : (
                                ''
                            )}
                            <div style={{ display: loading ? 'none' : 'block' }}>
                                <form noValidate onSubmit={handleSubmit} {...others}>
                                    <h3>{titlePage}</h3>
                                    <hr></hr>
                                    <Grid container spacing={matchDownSM ? 2 : 2}>
                                        <Grid item xs={12} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.title && errors.title) || Boolean(errorValidate.title)}
                                                id="outlined-title"
                                                type="text"
                                                label="Título"
                                                value={values.title}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="title"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.title && errors.title
                                                        ? errors.title
                                                        : errorValidate.title
                                                        ? errorValidate.title
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.name && errors.name) || Boolean(errorValidate.name)}
                                                id="name"
                                                type="text"
                                                label="Tópico"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="name"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.name && errors.name ? errors.name : errorValidate.name ? errorValidate.name : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.idCourse && errors.idCourse)}
                                                select
                                                label="Curso da aula"
                                                id="idCourse"
                                                type="text"
                                                value={values.idCourse}
                                                name="idCourse"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                MenuProps={MenuProps}
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.idCourse && errors.idCourse
                                                        ? errors.idCourse
                                                        : errorValidate.idCourse
                                                        ? errorValidate.idCourse
                                                        : ''
                                                }
                                            >
                                                {options.courses.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.number && errors.number) || Boolean(errorValidate.number)}
                                                id="number"
                                                type="number"
                                                label="Número"
                                                value={values.number}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="number"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.number && errors.number
                                                        ? errors.number
                                                        : errorValidate.number
                                                        ? errorValidate.number
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.peso && errors.peso) || Boolean(errorValidate.peso)}
                                                id="peso"
                                                type="number"
                                                label="Pontuação"
                                                value={values.peso}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="peso"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.peso && errors.peso ? errors.peso : errorValidate.peso ? errorValidate.peso : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.time && errors.time) || Boolean(errorValidate.time)}
                                                id="time"
                                                type="number"
                                                label="Tempo estimado (minutos)"
                                                value={values.time}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="time"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.time && errors.time ? errors.time : errorValidate.time ? errorValidate.time : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid container spacing={matchDownSM ? 0 : 2}>
                                            <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                                {values.description && (
                                                    <Editor
                                                        editorStyle={{
                                                            height: '200px',
                                                            width: '100%',
                                                            border: '1px solid #DCDCDC',
                                                            borderRadius: '25px',
                                                            padding: '20px'
                                                        }}
                                                        toolbar={{
                                                            options: ['inline', 'list', 'textAlign', 'fontFamily', 'fontSize', 'link'],
                                                            inline: {
                                                                options: ['bold', 'italic', 'underline']
                                                            },
                                                            list: {
                                                                options: ['unordered', 'ordered', 'indent', 'outdent']
                                                            },
                                                            textAlign: {
                                                                options: ['left', 'center', 'right']
                                                            },
                                                            fontFamily: {
                                                                options: [
                                                                    'Arial',
                                                                    'Georgia',
                                                                    'Impact',
                                                                    'Tahoma',
                                                                    'Times New Roman',
                                                                    'Verdana'
                                                                ]
                                                            }
                                                        }}
                                                        editorState={values.description}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={(e) => setFieldValue('description', e)}
                                                        disabled={isDisabled}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={8} sx={{ marginTop: 3 }}>
                                            {params.action !== 'view' && (
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6}>
                                                        <h3>Objetos de aprendizado</h3>
                                                        <Demo>
                                                            {valuesEdit.learningObject.length > 0 &&
                                                                valuesEdit.learningObject.map((value) => (
                                                                    <List dense={dense} key={value.id}>
                                                                        <ListItem
                                                                            secondaryAction={
                                                                                <IconButton edge="end" aria-label="delete">
                                                                                    <DeleteIcon
                                                                                        onClick={() => deleteLearningObjectById(value.id)}
                                                                                    />
                                                                                </IconButton>
                                                                            }
                                                                        >
                                                                            {value.size > 0 ? (
                                                                                <>
                                                                                    <ListItemAvatar>
                                                                                        <Avatar src={value.url} />
                                                                                    </ListItemAvatar>
                                                                                    <ListItemText
                                                                                        primary="Objeto de aprendizado do tipo arquivo"
                                                                                        secondary={value.typeLearningObject}
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <ListItemAvatar>
                                                                                        <Avatar>
                                                                                            <FolderIcon />
                                                                                        </Avatar>
                                                                                    </ListItemAvatar>
                                                                                    <ListItemText
                                                                                        primary={value.url}
                                                                                        secondary={value.typeLearningObject}
                                                                                    />
                                                                                </>
                                                                            )}
                                                                        </ListItem>
                                                                    </List>
                                                                ))}
                                                        </Demo>
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {params.action !== 'view' && (
                                                <Button variant="text" onClick={AddObject}>
                                                    Adicionar objeto de aprendizado
                                                    <AddCircleOutlineRoundedIcon />
                                                </Button>
                                            )}
                                            {showAddObject && (
                                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="objects">
                                                    <FormControlLabel
                                                        value={3}
                                                        control={<Radio />}
                                                        label="Imagem"
                                                        onChange={optionObject}
                                                    />
                                                    <FormControlLabel value={1} control={<Radio />} label="Vídeo" onChange={optionObject} />
                                                    <FormControlLabel
                                                        value={2}
                                                        control={<Radio />}
                                                        label="Arquivo"
                                                        onChange={optionObject}
                                                    />
                                                </RadioGroup>
                                            )}
                                        </Grid>
                                        {typeObject == 3 && (
                                            <>
                                                <Grid container>
                                                    {inputFields.map((data, index) => {
                                                        const { url } = data;
                                                        return (
                                                            <Grid container spacing={matchDownSM ? 0 : 0} sx={{ marginLeft: 2 }}>
                                                                <Grid item xs={12} sm={2} sx={{ marginTop: 1 }} key={index}>
                                                                    <RadioGroup
                                                                        row
                                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                                        name="objects"
                                                                    >
                                                                        <FormControlLabel
                                                                            value="1"
                                                                            control={<Radio />}
                                                                            label="URL"
                                                                            onChange={(evnt) => handleChangeType(index, evnt)}
                                                                        />
                                                                        <FormControlLabel
                                                                            value="2"
                                                                            control={<Radio />}
                                                                            label="Arquivo"
                                                                            onChange={(evnt) => handleChangeType(index, evnt)}
                                                                        />
                                                                    </RadioGroup>
                                                                </Grid>
                                                                {parseInt(inputFields[index].type) == 1 && (
                                                                    <>
                                                                        <Grid item xs={12} sm={4} sx={{ marginTop: 1 }} key={index}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="urlImage"
                                                                                type="text"
                                                                                label="URL"
                                                                                onBlur={handleBlur}
                                                                                name="url"
                                                                                disabled={isDisabled}
                                                                                onChange={(evnt) =>
                                                                                    handleChangeInput(index, evnt, typeObject)
                                                                                }
                                                                                value={url}
                                                                            />
                                                                        </Grid>
                                                                        <Grid xs={12} sm={4} sx={{ marginTop: 2 }}>
                                                                            {inputFields.length !== 1 ? (
                                                                                <Button
                                                                                    variant="text"
                                                                                    onClick={() => removeInputFields(index)}
                                                                                >
                                                                                    <DeleteOutlineOutlinedIcon />
                                                                                </Button>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                            <Button variant="text" onClick={addInputField}>
                                                                                <AddOutlinedIcon />
                                                                            </Button>
                                                                        </Grid>
                                                                    </>
                                                                )}{' '}
                                                                {parseInt(inputFields[index].type) == 2 && (
                                                                    <>
                                                                        <Grid item xs={12} sm={2} sx={{ marginTop: 2 }} key={index}>
                                                                            <Button variant="contained" component="label" size="small">
                                                                                Upload
                                                                                <input
                                                                                    type="file"
                                                                                    style={{ display: 'none' }}
                                                                                    name="file"
                                                                                    id="file"
                                                                                    label="Foto"
                                                                                    opacity={opacity}
                                                                                    disabled={isDisabled}
                                                                                    accept={
                                                                                        'application/msword, application/vnd.ms-excel, application/pdf'
                                                                                    }
                                                                                    onChange={(evnt) =>
                                                                                        handleChangeFile(index, evnt.target.files[0])
                                                                                    }
                                                                                />
                                                                            </Button>
                                                                            <b>
                                                                                {uploadedFiles[index] !== undefined
                                                                                    ? uploadedFiles[index].name
                                                                                    : ''}
                                                                            </b>
                                                                        </Grid>
                                                                        <Grid xs={12} sm={4} sx={{ marginTop: 1 }}>
                                                                            {inputFields.length !== 1 ? (
                                                                                <Button
                                                                                    variant="text"
                                                                                    onClick={() => removeInputFields(index)}
                                                                                >
                                                                                    <DeleteOutlineOutlinedIcon />
                                                                                </Button>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                            <Button variant="text" onClick={addInputField}>
                                                                                <AddOutlinedIcon />
                                                                            </Button>
                                                                        </Grid>
                                                                    </>
                                                                )}
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </>
                                        )}
                                        {typeObject == 1 && (
                                            <>
                                                <Grid container>
                                                    {inputFields.map((data, index) => {
                                                        const { url } = data;
                                                        return (
                                                            <Grid container spacing={matchDownSM ? 0 : 0} sx={{ marginLeft: 2 }}>
                                                                <Grid item xs={12} sm={4} sx={{ marginTop: 1 }} key={index}>
                                                                    <TextField
                                                                        fullWidth
                                                                        id="urlImage"
                                                                        type="text"
                                                                        label="URL"
                                                                        onBlur={handleBlur}
                                                                        name="url"
                                                                        disabled={isDisabled}
                                                                        onChange={(evnt) => handleChangeInput(index, evnt)}
                                                                        value={url}
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} sm={4} sx={{ marginTop: 2 }}>
                                                                    {inputFields.length !== 1 ? (
                                                                        <Button variant="text" onClick={() => removeInputFields(index)}>
                                                                            <DeleteOutlineOutlinedIcon />
                                                                        </Button>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    <Button variant="text" onClick={() => addInputField(index, typeObject)}>
                                                                        <AddOutlinedIcon />
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </>
                                        )}
                                        {typeObject == 2 && (
                                            <>
                                                <Grid container>
                                                    {inputFields.map((data, index) => {
                                                        const { url } = data;
                                                        return (
                                                            <Grid container spacing={matchDownSM ? 0 : 0} sx={{ marginLeft: 2 }}>
                                                                <Grid item xs={12} sm={2} sx={{ marginTop: 2 }} key={index}>
                                                                    <Button variant="contained" component="label" size="small">
                                                                        Upload
                                                                        <input
                                                                            type="file"
                                                                            name="file"
                                                                            id="file"
                                                                            style={{ display: 'none' }}
                                                                            label="Foto"
                                                                            opacity={opacity}
                                                                            disabled={isDisabled}
                                                                            onChange={(evnt) =>
                                                                                handleChangeFile(index, evnt.target.files[0])
                                                                            }
                                                                        />
                                                                    </Button>
                                                                    <b>
                                                                        {uploadedFiles[index] !== undefined
                                                                            ? uploadedFiles[index].name
                                                                            : ''}
                                                                    </b>
                                                                </Grid>
                                                                <Grid xs={12} sm={4} sx={{ marginTop: 1 }}>
                                                                    {inputFields.length !== 1 ? (
                                                                        <Button variant="text" onClick={() => removeInputFields(index)}>
                                                                            <DeleteOutlineOutlinedIcon />
                                                                        </Button>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    <Button variant="text" onClick={addInputField}>
                                                                        <AddOutlinedIcon />
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                    <Grid container alignItems="end" justifyContent="end" sx={{ mt: 3 }}>
                                        <Grid item>
                                            <Box sx={{ mt: 2, mr: 3 }}>
                                                <ThemeProvider theme={themeButton}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="button"
                                                            variant="contained"
                                                            color="neutral"
                                                            onClick={() => navigate(-1)}
                                                        >
                                                            Voltar
                                                        </Button>
                                                    </AnimateButton>
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                        {params.action === 'view' ? (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            // disabled={isSubmitting}
                                                            component={Link}
                                                            to={`/aula/${params.id}/edit`}
                                                            fullWidth
                                                            size="large"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Editar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        ) : (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            validateUrl={validationUrl}
                                                            disableElevation
                                                            disabled={isSubmitting || disableButton}
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Salvar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                </form>
                            </div>
                        </MainCard>
                    </>
                )}
            </Formik>
        </>
    );
};

export default Classe;
