import { useState, useEffect } from 'react';
import { getBiAccess } from 'services/bi';
import DonutChart from '../DonutChart';

const TypeAccess = () => {
    const [data, setData] = useState([]);
    const options = {
        pieHole: 0.4,
        is3D: false,
        legend: { position: 'bottom' },
        colors: ['#73A4F7', '#51A744']
    };

    useEffect(() => {
        getDataBi();
    }, []);
    function getDataBi() {
        getBiAccess().then((resp) => setData(resp.data));
    }

    return (
        <>
            <h1 style={{ color: 'black' }}>Tipo de acesso</h1>
            <DonutChart height="90%" chartType="PieChart" data={data} optionsConfig={options} />;
        </>
    );
};

export default TypeAccess;
