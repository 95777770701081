import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import { IconBrandGoogleAnalytics } from '@tabler/icons-react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import LoginIcon from '@mui/icons-material/Login';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Tooltip from '@mui/material/Tooltip';
import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getUser, deleteUser, changeStatus, loginUser, reportUser, defineLevelStudent } from '../../services/user';
import { getSpeaker, deleteSpeaker } from '../../services/speakers';
import BasicModal from '../Modal/BasicModal';
import SnackBar from '../SnackBar/SnackBar';
import { useNavigate } from 'react-router-dom';
import Avatar from '../Avatar/Avatar';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import { useDispatch, useSelector } from 'react-redux';
import { HML } from 'baseURL/baseURL';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import ModalFilter from 'components/Modal/ModalFilter';
import { Button, MenuItem, TextField } from '@mui/material';
import { set } from 'date-fns';
function TablePaginationActions(props) {
    const theme = useTheme();

    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};

export default function CustomPaginationActionsTable(props) {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#015641',
            color: theme.palette.common.white
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));
    const navigate = useNavigate();
    const [idDestroyUser, setIdDestroyUser] = useState('');
    const [listPage, setListPage] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [page, setPage] = useState(0);
    const rowsPerPageUser = useSelector((state) => state.user.rowsPerPage);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openModalUpLevel, setOpenModalUpLevel] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [validateInputs, setValidateInputs] = useState({ note: '' });
    const [snackBarType, setSnackBarType] = useState('');
    const [userSelected, setUserSelected] = useState(null);
    const [userLevelNote, setUserLevelNote] = useState({
        internship_note: null,
        mentorship_note: null
    });
    const [userLevelSelected, setUserLevelSelected] = useState(null);
    const idUser = useSelector((state) => state.auth.user.id) || '';

    const studentLevels = [
        { id: 5, name: '5 estrelas - Prata' },
        { id: 6, name: '1 estrela - Ouro' },
        { id: 7, name: '2 estrelas - Ouro' }
    ];

    const { getData, data } = props;

    const handleCloseModal = () => setOpenModal(false);
    const handleOpenDestroy = (idDestroy, page) => {
        setIdDestroyUser(idDestroy);
        setListPage(page);
        setOpenModal(true);
    };
    const loginAnotherUser = (idUser) => {
        const tokenAdm = localStorage.getItem('token');
        loginUser(idUser)
            .then((resp) => {
                setSnackBarMessage('Logando em outro usuário...');
                setOpenSnackBar(true);
                let token = resp.data.access_token;
                let personification = resp.data.user.administrator.id;
                setTimeout(() => {
                    localStorage.setItem('token', tokenAdm);
                    window.open(`${HML}/auth/admin?token=${token}&personification=${personification}`, '_blank');
                }, 1500);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const handlerClick = () => {
        setOpenSnackBar(false);
    };
    const deleteUserById = () => {
        deleteUser(idDestroyUser)
            .then((resp) => {
                getUser();
                setSnackBarMessage('Usuário deletado com sucesso!');
                setOpenSnackBar(true);
                handleCloseModal();
                setTimeout(() => {
                    navigate('/index');
                }, 1500);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const deleteSpeakerById = () => {
        deleteSpeaker(idDestroyUser)
            .then((resp) => {
                getSpeaker();
                setSnackBarMessage('Palestrante deletado com sucesso!');
                setOpenSnackBar(true);
                handleCloseModal();
                setTimeout(() => {
                    navigate('/index');
                }, 1500);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0
        }
    }));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        getData(page, rowsPerPage);
    }, [rowsPerPage, page]);

    const changeStatusActive = (event) => {
        const id = event.target.id;
        changeStatus(id)
            .then((resp) => getData(page, rowsPerPage))
            .catch((resp) => getData(page, rowsPerPage));
    };

    const submitDefineLevelStudent = () => {
        if (
            (userLevelSelected.id === 6 && userLevelNote.internship_note === null) ||
            (userLevelSelected.id === 7 && userLevelNote.mentorship_note === null)
        ) {
            setValidateInputs({ note: 'Preencha o campo de nota' });
            return;
        }

        let data = {
            id_level_experience: userLevelSelected.id,
            id_user: userSelected.id,
            internship_note: userLevelSelected.id === 6 ? userLevelNote.internship_note : null,
            mentorship_note: userLevelSelected.id === 7 ? userLevelNote.mentorship_note : null,
            passed: 1
        };

        defineLevelStudent(data)
            .then((resp) => {
                setSnackBarMessage('Nível atualizado com sucesso!');
                setOpenSnackBar(true);
                setOpenModalUpLevel(false);
                getData(page, rowsPerPage);
                setValidateInputs({ note: '' });
            })
            .catch((e) => {
                console.log(e);
                setValidateInputs({ note: '' });
            });
    };

    return (
        <>
            <TableContainer sx={{ mt: 2, height: '100%', padding: 3 }} component={Paper}>
                <BasicModal
                    open={openModal}
                    title="Excluir Usuário"
                    handleClose={handleCloseModal}
                    description="Tem certeza que deseja excluir o usuário?"
                    onDelete={listPage == 'user' ? deleteUserById : deleteSpeakerById}
                />
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            {props.speaker !== 'true' && <StyledTableCell align="center">{props.action}</StyledTableCell>}
                            <StyledTableCell align="center">{props.header1}</StyledTableCell>
                            <StyledTableCell align="center">{props.header2}</StyledTableCell>
                            <StyledTableCell align="center">{props.header3}</StyledTableCell>
                            <StyledTableCell align="center">{props.nivel}</StyledTableCell>
                            <StyledTableCell align="center">{props.header4}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.speaker !== 'true'
                            ? data.map((value) => (
                                  <StyledTableRow>
                                      <StyledTableCell align="center" style={{ width: 50 }}>
                                          <Toggle
                                              id={`${value.id}`}
                                              checked={value.disabled == 1 ? false : true}
                                              onChange={changeStatusActive}
                                              disabled={idUser == value.id ? true : false}
                                          />
                                      </StyledTableCell>
                                      <StyledTableCell component="th" scope="row" style={{ width: 250 }} align="center">
                                          {value.name}
                                      </StyledTableCell>
                                      <StyledTableCell style={{ width: 200 }} align="center">
                                          {value.email}
                                      </StyledTableCell>
                                      <StyledTableCell style={{ width: 160 }} align="center">
                                          {value.role}
                                      </StyledTableCell>
                                      <StyledTableCell style={{ width: 160 }} align="center">
                                          {value.level}
                                      </StyledTableCell>
                                      <StyledTableCell style={{ width: 320 }} align="center">
                                          <div style={{ display: 'flex', justifyContent: 'start' }}>
                                              <Tooltip title="Logar">
                                                  <IconButton
                                                      disabled={
                                                          value.disabled == 1 ? true : false || value.role != 'Administrador' ? false : true
                                                      }
                                                      onClick={() => loginAnotherUser(value.id)}
                                                  >
                                                      <LoginIcon />
                                                  </IconButton>
                                              </Tooltip>

                                              {value.role === 'Aluno' && (
                                                  <>
                                                      <Tooltip title="Definir nível">
                                                          <IconButton
                                                              onClick={() => {
                                                                  setOpenModalUpLevel(true);
                                                                  setUserSelected(value);
                                                                  setUserLevelNote({
                                                                      internship_note: value.internship_note,
                                                                      mentorship_note: value.mentorship_note
                                                                  });
                                                              }}
                                                          >
                                                              <IconBrandGoogleAnalytics />
                                                          </IconButton>
                                                      </Tooltip>
                                                      <Tooltip title="Relatório">
                                                          <IconButton onClick={() => props.download(value.id, value.name)}>
                                                              <PdfIcon />
                                                          </IconButton>
                                                      </Tooltip>
                                                  </>
                                              )}
                                              <Tooltip title="Visualizar">
                                                  <IconButton onClick={() => navigate('/usuario/' + value.id + '/view')}>
                                                      <ViewIcon />
                                                  </IconButton>
                                              </Tooltip>
                                              <Tooltip title="Editar">
                                                  <IconButton onClick={() => navigate('/usuario/' + value.id + '/edit')}>
                                                      <EditIcon />
                                                  </IconButton>
                                              </Tooltip>
                                              <Tooltip title="Excluir">
                                                  <IconButton onClick={() => handleOpenDestroy(value.id, 'user')}>
                                                      <DeleteIcon />
                                                  </IconButton>
                                              </Tooltip>
                                          </div>
                                      </StyledTableCell>
                                  </StyledTableRow>
                              ))
                            : data.map(
                                  (value) => (
                                      value.file.length == 0 && (value.file = ['{ url: null }']),
                                      value.file.map((values) => (
                                          <StyledTableRow>
                                              {/* <StyledTableCell>
                                              <Toggle
                                                  id={`${row.id}`}
                                                  checked={row.disabled == 1 ? true : false}
                                                  onChange={changeStatusActive}
                                              />
                                          </StyledTableCell> */}
                                              <StyledTableCell style={{ width: 80 }} align="center">
                                                  <Avatar src={values.url} />
                                              </StyledTableCell>
                                              <StyledTableCell component="th" scope="row" style={{ width: 250 }} align="center">
                                                  {value.name}
                                              </StyledTableCell>
                                              <StyledTableCell
                                                  style={{ maxWidth: 320, wordWrap: 'break-all' }}
                                                  align="center"
                                                  whitespace="pre-wrap"
                                              >
                                                  {value.description}
                                              </StyledTableCell>
                                              <StyledTableCell style={{ width: 160 }} align="right">
                                                  <Tooltip title="Visualizar">
                                                      <IconButton onClick={() => navigate(props.page + value.id + '/view')}>
                                                          <ViewIcon />
                                                      </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Editar">
                                                      <IconButton onClick={() => navigate(props.page + value.id + '/edit')}>
                                                          <EditIcon />
                                                      </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Excluir">
                                                      <IconButton onClick={() => handleOpenDestroy(value.id, 'speaker')}>
                                                          <DeleteIcon />
                                                      </IconButton>
                                                  </Tooltip>
                                              </StyledTableCell>
                                          </StyledTableRow>
                                      ))
                                  )
                              )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageUser != null ? [rowsPerPageUser] : [5, 10, 25]}
                                colSpan={3}
                                count={props.total}
                                rowsPerPage={rowsPerPageUser != null ? rowsPerPageUser : rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page'
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
                <SnackBar
                    openSnackBar={openSnackBar}
                    message={snackBarMessage}
                    type={snackBarType}
                    setOpenSnackBar={setOpenSnackBar}
                    onClick={handlerClick}
                />
            </TableContainer>

            <ModalFilter
                open={openModalUpLevel}
                title="Definir nível do aluno"
                handleClose={() => setOpenModalUpLevel(false)}
                content={
                    <form>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Selecione um nível"
                            variant="outlined"
                            select
                            value={userLevelSelected?.id || ''}
                            onChange={(event) => {
                                const selectedLevel = studentLevels.find((level) => level.id === event.target.value);
                                setUserLevelSelected(selectedLevel);
                            }}
                        >
                            {studentLevels.map(
                                (level) => (
                                    // userSelected?.id_level < level.id && (
                                    <MenuItem key={level.id} value={level.id}>
                                        {level.name}
                                    </MenuItem>
                                )
                                // )
                            )}
                        </TextField>

                        <div style={{ marginTop: '1rem' }}>
                            {userLevelSelected?.id === 6 && (
                                <>
                                    <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        label="Nota do aluno no estágio"
                                        variant="outlined"
                                        type="number"
                                        onChange={(event) => {
                                            setUserLevelNote({ ...userLevelNote, internship_note: event.target.value });
                                        }}
                                        value={userLevelNote?.internship_note}
                                    />
                                    {validateInputs.note && <p style={{ color: 'red' }}>{validateInputs.note}</p>}
                                </>
                            )}
                            {userLevelSelected?.id === 7 && (
                                <>
                                    <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        label="Nota do aluno na mentoria"
                                        variant="outlined"
                                        type="number"
                                        onChange={(event) => {
                                            setUserLevelNote({ ...userLevelNote, mentorship_note: event.target.value });
                                        }}
                                        value={userLevelNote?.mentorship_note}
                                    />
                                    {validateInputs.note && <p style={{ color: 'red' }}>{validateInputs.note}</p>}
                                </>
                            )}
                        </div>

                        <Button
                            fullWidth
                            size="large"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={submitDefineLevelStudent}
                        >
                            Atualizar nível
                        </Button>
                    </form>
                }
            />
        </>
    );
}
