import { Chart } from 'react-google-charts';

export default function DonutChart({ data, optionsConfig, chartType, height }) {
    const options = {
        pieHole: 0.4,
        is3D: false,
        legend: { position: 'bottom' },
        colors: ['#73A4F7', '#51A744'],
        ...optionsConfig
    };

    return <Chart chartType={chartType} height={height || '100%'} width="100%" data={data} options={options} />;
}
