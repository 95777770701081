import { HeaderStyled, ButtonMenu } from './styles';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
const Header = () => {
    const [optionSelected, setOptionSelected] = useState('DadosGerais');
    const options = ['DadosGerais', 'Eventos', 'Cursos', 'Biblioteca', 'Favoritos'];
    const dispatch = useDispatch();

    const handleChangeOption = (newValue) => {
        dispatch({ type: 'SET_OPTION_MENU', payload: newValue });
        setOptionSelected(newValue);
    };
    return (
        <HeaderStyled>
            {options.map((value) => (
                <ButtonMenu
                    onClick={() => handleChangeOption(value)}
                    style={
                        optionSelected !== value
                            ? {
                                  borderRadius: '8px 8px 0px 0px',
                                  backgroundColor: '#fff'
                              }
                            : {
                                  borderRadius: '8px 8px 0px 0px',
                                  backgroundColor: '#3a6647',
                                  color: '#fff'
                              }
                    }
                >
                    {value === 'DadosGerais' ? 'Dados Gerais' : value}
                </ButtonMenu>
            ))}
        </HeaderStyled>
    );
};

export default Header;
