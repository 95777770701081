export const initialState = {
    page: 0,
    rowsPerPage: 10,
    typeLibrary: ''
};

const libraryReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_PAGE_LIBRARY':
            return {
                ...state,
                page: actions.payload
            };
        case 'SET_ROWS_PER_PAGE_LIBRARY':
            return {
                ...state,
                rowsPerPage: actions.payload
            };
        case 'SET_TYPEOBJECTLIBRARY_LIBRARY':
            return {
                ...state,
                typeLibrary: actions.payload
            };
        case 'SET_CLEAR_TYPEOBJECTLIBRARY':
            return {
                ...state,
                typeLibrary: ''
            };
        default:
            return state;
    }
};

export default libraryReducer;
