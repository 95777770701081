import PropTypes from 'prop-types';
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router';

import HomeComponent from 'views/pages/Home/HomeComponent';

// ==============================|| MAIN ROUTING ||============================== //

const AuthRouter = ({ children }) => {
    let token = localStorage.getItem('token');
    return token ? children : <Navigate to="/login" />;
};
AuthRouter.propTypes = {
    children: PropTypes.object
};
const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/index',
            element: (
                <AuthRouter>
                    <HomeComponent />
                </AuthRouter>
            )
        }
        // {
        //     path: '/fechamento_fazenda',
        //     element: (
        //         <AuthRouter>
        //             <CloseFarm />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: '/painel/:id',
        //     element: (
        //         <AuthRouter>
        //             <PanelBi />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: '/perfil',
        //     element: (
        //         <AuthRouter>
        //             <ManagerProfile />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'usuarios',
        //     element: (
        //         <AuthRouter>
        //             <GridUser />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'novo_usuario',
        //     element: (
        //         <AuthRouter>
        //             <User />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'usuario/:id/:action',
        //     element: (
        //         <AuthRouter>
        //             <User />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'fazendas',
        //     element: (
        //         <AuthRouter>
        //             <GridFarm />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'nova_fazenda',
        //     element: (
        //         <AuthRouter>
        //             <Farm />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'fazenda/:id/:action',
        //     element: (
        //         <AuthRouter>
        //             <Farm />
        //         </AuthRouter>
        //     )
        // },
        // ,
        // {
        //     path: 'novo_indicador_cliente',
        //     element: (
        //         <AuthRouter>
        //             <IndicatorClient />
        //         </AuthRouter>
        //     )
        // }
    ]
};

export default MainRoutes;
