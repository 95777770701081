// assets
import { IconBrandGoogleAnalytics, IconBuildingWarehouse, IconUser, IconWindmill } from '@tabler/icons-react';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import Help from '@mui/icons-material/Help';

// constant
const icons = { IconUser, IconWindmill, IconBuildingWarehouse, IconBrandGoogleAnalytics };

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const registrationCenter = {
    id: 'utilities',
    type: 'group',
    title: '',
    children: [
        {
            id: 'usuarios',
            title: 'Usuarios',
            type: 'item',
            url: '/usuarios',
            icon: icons.IconUser,
            breadcrumbs: true,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'palestrantes',
            title: 'Palestrantes',
            type: 'item',
            url: '/palestrantes',
            icon: AccountBoxOutlinedIcon,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'eventos',
            title: 'Eventos',
            type: 'item',
            url: '/eventos',
            icon: icons.IconWindmill,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'cursos',
            title: 'Cursos',
            type: 'item',
            url: '/cursos',
            icon: SchoolOutlinedIcon,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'aulas',
            title: 'Aulas',
            type: 'item',
            url: '/aulas',
            icon: OndemandVideoOutlinedIcon,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'niveis',
            title: 'Niveis',
            type: 'item',
            url: '/niveis',
            icon: icons.IconBrandGoogleAnalytics
        },
        {
            id: 'habilidades',
            title: 'Habilidades',
            type: 'item',
            url: '/habilidades',
            icon: BookmarkAddOutlinedIcon,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'biblioteca',
            title: 'Biblioteca',
            type: 'item',
            url: '/biblioteca',
            icon: MenuBookOutlinedIcon,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'publicacoes',
            title: 'Publicacoes',
            type: 'item',
            url: '/publicacoes',
            icon: BackupTableOutlinedIcon,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'questionario',
            title: 'Questionarios',
            type: 'item',
            url: '/questionario',
            icon: QuizOutlinedIcon,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'help',
            title: 'Ajuda',
            type: 'item',
            url: '/ajuda',
            icon: Help,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'northStarMetric',
            title: 'North Star Metric',
            type: 'item',
            url: '/north_star_metric',
            icon: ExploreOutlinedIcon,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        }
        /*{
            id: 'coordenadores',
            title: 'Coordenadores',
            type: 'item',
            url: '/coordenadores',
            icon: icons.IconUser,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        },
        {
            id: 'centros_custo',
            title: 'Centro de Custo',
            type: 'item',
            url: '/centros_custo',
            icon: icons.IconBuildingWarehouse,
            breadcrumbs: false,
            color: 'success',
            variant: 'success'
        }*/
    ]
};

export default registrationCenter;
