import { useState, useEffect, useRef, forwardRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Button, FormHelperText, Grid, TextField, MenuItem, useMediaQuery, Alert, Snackbar } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import Loading from 'components/Loading/Loading';
import {
    postPublication,
    getPublicationPerId,
    updatePublication,
    getResourcePublication,
    getAdvertisementPublication
} from 'services/publication';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const Advertisement = ({ ...others }) => {
    const params = useParams();
    const themeButton = createTheme({
        status: {
            danger: '#e53e3e'
        },
        palette: {
            primary: {
                main: '#0971f1',
                darker: '#053e85'
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff'
            }
        }
    });
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 58;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    const initialValuesEdit = {
        idType: '',
        typeAdvertisement: '',
        event: '',
        course: '',
        library: '',
        descriptionAdvertisement: '',
        allFromLibrary: ''
    };

    const initialStateOptions = {
        periods: []
    };

    const theme = useTheme();
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [options, setOptions] = useState(initialStateOptions);
    const [data, setData] = useState('');
    const [error, setError] = useState('');
    const [errorValidate, setErrorValidate] = useState({});
    const [disableButton, setDisableButton] = useState(false);
    const [valuesEdit, setValuesEdit] = useState(initialValuesEdit);

    const titlePage =
        params.action === 'view' ? 'Visualização de Anúncio' : params.action === 'edit' ? 'Edição de Anúncio' : 'Cadastro de Anúncio';
    const isDisabled = params.action === 'view' ? true : false;

    useEffect(() => {
        viewPerId();
    }, [params.id]);

    useEffect(() => {
        getAllResourcePublication();
    }, [params.id]);

    function getAllResourcePublication() {
        getResourcePublication().then((resp) => setOptions(resp.data));
    }

    function getAllData(idType) {
        getAdvertisementPublication(idType).then((resp) => setData(resp.data));
    }

    function viewPerId() {
        params.action &&
            getPublicationPerId(params.id).then((resp) => {
                setValuesEdit({
                    ['typeAdvertisement']: resp.data.id_type_advertisement,
                    ['library']: resp.data.id_library || '',
                    ['allFromLibrary']: resp.data.library || '',
                    ['event']: resp.data.id_event || '',
                    ['course']: resp.data.id_course || '',
                    ['descriptionAdvertisement']: resp.data.descriptionAdvertisement || ''
                });
                getAdvertisementPublication(resp.data.id_type_advertisement).then((response) => {
                    setData(response.data);
                    // setData((prevData) => [...prevData, resp.data.library]);
                });
            });
    }
    console.log(data);
    return (
        <>
            <Formik
                initialValues={{
                    idType: valuesEdit.idType,
                    typeAdvertisement: valuesEdit.typeAdvertisement,
                    event: valuesEdit.event || '',
                    library: valuesEdit.library || '',
                    course: valuesEdit.course || '',
                    descriptionAdvertisement: valuesEdit.descriptionAdvertisement || '',
                    submit: null
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({})}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }

                        const data = new FormData();

                        setLoading(true);
                        data.append('idType', 3);
                        data.append('idTypeAdvertisement', values.typeAdvertisement);
                        switch (values.typeAdvertisement) {
                            case 1:
                                data.append('idCourse', values.course || '');
                                data.append('idEvent', '');
                                break;
                            case 2:
                                data.append('idEvent', values.event || '');
                                data.append('idCourse', '');
                                break;
                            case 4:
                                data.append('idLibrary', values.library || '');
                                data.append('idCourse', '');
                                data.append('idEvent', '');
                                break;
                            case 5:
                                data.append('idLibrary', values.library || '');
                                data.append('idCourse', '');
                                data.append('idEvent', '');
                                data.append('descriptionAdvertisement', values.descriptionAdvertisement);
                                break;
                        }

                        if (params.action === 'edit') {
                            data.append('_method', 'put');
                            updatePublication(params.id, data, {
                                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                            })
                                .then((resp) => {
                                    setError('');
                                    viewPerId();
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        setLoading(false);
                                        navigate('/publicacoes');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    setError(e.response.data.error);
                                });
                        } else {
                            postPublication(data, {
                                headers: { 'content-type': 'application/json', 'Access-Control-Allow-Origin': '*' }
                            })
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        navigate('/publicacoes');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    setErrorValidate(e.response.data.error);
                                    setError(e.response.data.error);
                                });
                        }
                    } catch (err) {
                        console.error(err);
                        setError(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <>
                        {loading && (
                            <Grid container alignItems="center" justifyContent="center">
                                <MainCard style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Loading color="#015641" type="spinningBubbles" />
                                </MainCard>
                            </Grid>
                        )}
                        <MainCard spacing={gridSpacing} style={{ padding: 15, margin: 25 }}>
                            {error || alert ? (
                                <Snackbar open={true} autoHideDuration={6000}>
                                    <Alert
                                        severity={error ? 'error' : success ? 'success' : ''}
                                        sx={{
                                            width: '100%',
                                            backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                            color: '#FFF'
                                        }}
                                    >
                                        {error ? error : success ? success : ''}
                                    </Alert>
                                </Snackbar>
                            ) : (
                                ''
                            )}
                            <div style={{ display: loading ? 'none' : 'block' }}>
                                <form noValidate onSubmit={handleSubmit} {...others}>
                                    <h3>{titlePage}</h3>
                                    <hr></hr>
                                    <Grid container spacing={matchDownSM ? 2 : 2}>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.typeAdvertisement && errors.typeAdvertisement)}
                                                select
                                                label="Tipo"
                                                id="typeAdvertisement"
                                                type="text"
                                                value={values.typeAdvertisement || ''}
                                                name="typeAdvertisement"
                                                onBlur={handleBlur}
                                                onChange={function idTypeAdvertisement(e) {
                                                    setFieldValue('typeAdvertisement', e.target.value), getAllData(e.target.value);
                                                }}
                                                MenuProps={MenuProps}
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.typeAdvertisement && errors.typeAdvertisement
                                                        ? errors.typeAdvertisement
                                                        : errorValidate.typeAdvertisement
                                                        ? errorValidate.typeAdvertisement
                                                        : ''
                                                }
                                            >
                                                {options.typeAdvertisement !== undefined &&
                                                    options.typeAdvertisement.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.description}
                                                        </MenuItem>
                                                    ))}
                                            </TextField>
                                        </Grid>
                                        {values.typeAdvertisement === 1 && (
                                            <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                                <TextField
                                                    fullWidth
                                                    error={Boolean(touched.course && errors.course)}
                                                    select
                                                    label="Curso"
                                                    id="course"
                                                    type="text"
                                                    value={values.course}
                                                    name="course"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    MenuProps={MenuProps}
                                                    disabled={isDisabled}
                                                    helperText={
                                                        touched.course && errors.course
                                                            ? errors.course
                                                            : errorValidate.course
                                                            ? errorValidate.course
                                                            : ''
                                                    }
                                                >
                                                    {data !== '' &&
                                                        data.map((value) => (
                                                            <MenuItem key={value.id} value={value.id}>
                                                                {value.name}
                                                            </MenuItem>
                                                        ))}
                                                </TextField>
                                            </Grid>
                                        )}
                                        {values.typeAdvertisement === 2 && (
                                            <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                                <TextField
                                                    fullWidth
                                                    error={Boolean(touched.event && errors.event)}
                                                    select
                                                    label="Evento"
                                                    id="event"
                                                    type="text"
                                                    value={values.event || ''}
                                                    name="event"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    MenuProps={MenuProps}
                                                    disabled={isDisabled}
                                                    helperText={
                                                        touched.event && errors.event
                                                            ? errors.event
                                                            : errorValidate.event
                                                            ? errorValidate.event
                                                            : ''
                                                    }
                                                >
                                                    {data !== '' &&
                                                        data.map((value) => (
                                                            <MenuItem key={value.id} value={value.id}>
                                                                {value.title}
                                                            </MenuItem>
                                                        ))}
                                                </TextField>
                                            </Grid>
                                        )}
                                        {values.typeAdvertisement === 4 && (
                                            <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                                <TextField
                                                    fullWidth
                                                    error={Boolean(touched.library && errors.library)}
                                                    select
                                                    label="Arquivo da Biblioteca"
                                                    id="library"
                                                    type="text"
                                                    value={values.library || ''}
                                                    name="library"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    MenuProps={MenuProps}
                                                    disabled={isDisabled}
                                                    helperText={
                                                        touched.library && errors.library
                                                            ? errors.library
                                                            : errorValidate.library
                                                            ? errorValidate.library
                                                            : ''
                                                    }
                                                >
                                                    {data !== '' &&
                                                        data.map((value) => (
                                                            <MenuItem key={value.id} value={value.id}>
                                                                {value.title}
                                                            </MenuItem>
                                                        ))}
                                                </TextField>
                                            </Grid>
                                        )}
                                        {values.typeAdvertisement === 5 && (
                                            <>
                                                <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                                    <TextField
                                                        fullWidth
                                                        error={Boolean(touched.library && errors.library)}
                                                        select
                                                        label="Arquivo da Biblioteca"
                                                        id="library"
                                                        type="text"
                                                        value={values.library || ''}
                                                        name="library"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        MenuProps={MenuProps}
                                                        disabled={isDisabled}
                                                        helperText={
                                                            touched.library && errors.library
                                                                ? errors.library
                                                                : errorValidate.library
                                                                ? errorValidate.library
                                                                : ''
                                                        }
                                                    >
                                                        {data !== '' &&
                                                            data.map((value) => (
                                                                <MenuItem key={value.id} value={value.id}>
                                                                    {value.title}
                                                                </MenuItem>
                                                            ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                        error={
                                                            Boolean(touched.descriptionAdvertisement && errors.descriptionAdvertisement) ||
                                                            Boolean(errorValidate.descriptionAdvertisement)
                                                        }
                                                        id="descriptionAdvertisement"
                                                        type="text"
                                                        label="Descrição"
                                                        value={values.descriptionAdvertisement}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="descriptionAdvertisement"
                                                        disabled={isDisabled}
                                                        helperText={
                                                            touched.descriptionAdvertisement && errors.descriptionAdvertisement
                                                                ? errors.descriptionAdvertisement
                                                                : errorValidate.descriptionAdvertisement
                                                                ? errorValidate.descriptionAdvertisement
                                                                : ''
                                                        }
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                    <Grid container alignItems="end" justifyContent="end" sx={{ mt: 3 }}>
                                        {params.action === 'edit' && (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <ThemeProvider theme={themeButton}>
                                                        <AnimateButton>
                                                            <Button
                                                                disableElevation
                                                                disabled={isSubmitting}
                                                                fullWidth
                                                                size="large"
                                                                type="button"
                                                                variant="contained"
                                                                color="neutral"
                                                                onClick={() => {
                                                                    if (valuesEdit.event !== '') {
                                                                        navigate(`/evento/${valuesEdit.event}/edit`);
                                                                    } else if (valuesEdit.course !== '') {
                                                                        navigate(`/curso/${valuesEdit.course}/edit`);
                                                                    } else {
                                                                        navigate(`/arquivo/${valuesEdit.library}/edit`);
                                                                    }
                                                                }}
                                                            >
                                                                Editar item
                                                            </Button>
                                                        </AnimateButton>
                                                    </ThemeProvider>
                                                </Box>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <Box sx={{ mt: 2, mr: 3 }}>
                                                <ThemeProvider theme={themeButton}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="button"
                                                            variant="contained"
                                                            color="neutral"
                                                            onClick={() => navigate(-1)}
                                                        >
                                                            Voltar
                                                        </Button>
                                                    </AnimateButton>
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                        {params.action === 'view' ? (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            // disabled={isSubmitting}
                                                            component={Link}
                                                            to={`/anuncio/${params.id}/edit`}
                                                            fullWidth
                                                            size="large"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Editar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        ) : (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting || disableButton}
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Salvar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                </form>
                            </div>
                        </MainCard>
                    </>
                )}
            </Formik>
        </>
    );
};

export default Advertisement;
