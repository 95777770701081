import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { Checkbox } from '@mui/material';
import { useFormikContext } from 'formik';

const CheckboxInput = ({ id, item, value, setValue, disabled }) => {
    const { label, optional } = item;

    return (
        <Container
            control={
                <Checkbox
                    checked={value === true}
                    onChange={() => {
                        setValue(!value);
                    }}
                    color="primary"
                />
            }
            label={`${label}${optional ? '' : '*'}`}
            name={id}
            disabled={disabled}
        />
    );
};

CheckboxInput.propTypes = {
    item: PropTypes.object.isRequired,
    value: PropTypes.any,
    id: PropTypes.string,
    setValue: PropTypes.func,
    disabled: PropTypes.bool
};

export default CheckboxInput;
