import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const NorthStarMetric = () => {
    return (
        <MainCard spacing={gridSpacing} style={{ padding: 5, margin: 0 }}>
            <iframe
                title="North Star Metric - BI"
                width="100%"
                height="1000px"
                src="https://app.powerbi.com/reportEmbed?reportId=dae79665-dcda-4ea9-b121-86da8b6952bb&autoAuth=true&ctid=8f449adf-09d9-439f-a048-7f40a1fecbbf"
                frameborder="0"
                allowFullScreen="false"
            ></iframe>
        </MainCard>
    );
};

export default NorthStarMetric;
