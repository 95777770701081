import PropTypes from 'prop-types';

// project imports
import MainLayout from 'layout/MainLayout';
import { Navigate } from 'react-router';

// ==============================|| MAIN ROUTING ||============================== //

const AuthRouter = ({ children }) => {
    let token = localStorage.getItem('token');
    return token ? children : <Navigate to="/login" />;
};
AuthRouter.propTypes = {
    children: PropTypes.object
};
const SystemRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        // {
        //     path: 'fluxos',
        //     element: (
        //         <AuthRouter>
        //             <GridFlow />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'novo_fluxo',
        //     element: (
        //         <AuthRouter>
        //             <Flow />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'fluxo/:id/:action',
        //     element: (
        //         <AuthRouter>
        //             <Flow />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'topicos',
        //     element: (
        //         <AuthRouter>
        //             <GridTopic />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'novo_topico',
        //     element: (
        //         <AuthRouter>
        //             <Topic />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'topico/:id/:action',
        //     element: (
        //         <AuthRouter>
        //             <Topic />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'listas',
        //     element: (
        //         <AuthRouter>
        //             <GridList />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'nova_lista',
        //     element: (
        //         <AuthRouter>
        //             <List />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'lista/:id/:action',
        //     element: (
        //         <AuthRouter>
        //             <List />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'itens_listas',
        //     element: (
        //         <AuthRouter>
        //             <GridListItem />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'novo_item_lista',
        //     element: (
        //         <AuthRouter>
        //             <ListItem />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'item_lista/:id/:action',
        //     element: (
        //         <AuthRouter>
        //             <ListItem />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'indicadores',
        //     element: (
        //         <AuthRouter>
        //             <GridIndicator />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'novo_indicador',
        //     element: (
        //         <AuthRouter>
        //             <Indicator />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: 'indicador/:id/:action',
        //     element: (
        //         <AuthRouter>
        //             <Indicator />
        //         </AuthRouter>
        //     )
        // }
    ]
};

export default SystemRoutes;
