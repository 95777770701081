import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { GridMainContainer, Divider, ListStyled } from './styles';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';

export default function FilterList(props) {
    const CustomColorCheckbox = withStyles({
        root: {
            '&$checked': {
                color: '#3a6647'
            }
        },
        checked: {}
    })((props) => <Checkbox color="default" {...props} />);

    const [checked, setChecked] = React.useState([]);
    const dispatch = useDispatch();
    const filtered = useSelector((state) => state.bi.filtered);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
        switch (props.originFilter) {
            case 'competence':
                dispatch({ type: 'SET_COMPETENCE_FILTER', payload: newChecked.length > 0 ? newChecked : null });
                dispatch({ type: 'SET_FILTERED', payload: !filtered });
                break;
            case 'course':
                dispatch({ type: 'SET_COURSE_FILTER', payload: newChecked.length > 0 ? newChecked : null });
                dispatch({ type: 'SET_FILTERED', payload: !filtered });
                break;
            case 'speaker':
                dispatch({ type: 'SET_SPEAKER_FILTER', payload: newChecked.length > 0 ? newChecked : null });
                dispatch({ type: 'SET_FILTERED', payload: !filtered });
                break;
            case 'event':
                dispatch({ type: 'SET_EVENT_FILTER', payload: newChecked.length > 0 ? newChecked : null });
                dispatch({ type: 'SET_FILTERED', payload: !filtered });
                break;
            case 'typeEvent':
                dispatch({ type: 'SET_TYPE_EVENT_FILTER', payload: newChecked.length > 0 ? newChecked : null });
                dispatch({ type: 'SET_FILTERED', payload: !filtered });
                break;
            case 'resource':
                dispatch({ type: 'SET_TYPE_RESOURCE_FILTER', payload: newChecked.length > 0 ? newChecked : null });
                dispatch({ type: 'SET_FILTERED', payload: !filtered });
                break;
            default:
                break;
        }
    };
    return (
        <GridMainContainer container>
            <h4>{props.title}</h4>
            <Divider />
            <ListStyled sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', maxHeight: 200, overflow: 'auto' }}>
                {props.competences?.map((value, key) => (
                    <ListItem key={key} disablePadding>
                        <ListItemButton role={undefined} onClick={handleToggle(value.id)} dense>
                            <ListItemIcon>
                                <CustomColorCheckbox
                                    edge="start"
                                    checked={checked.indexOf(value.id) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': value.id }}
                                />
                            </ListItemIcon>
                            <ListItemText id={key} primary={value.name || value.description || value.title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </ListStyled>
        </GridMainContainer>
    );
}
