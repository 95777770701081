export const initialState = {
    page: 0,
    rowsPerPage: 10,
    dateEvent: '',
    valueDateEvent: ''
};

const eventReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_PAGE_EVENT':
            return {
                ...state,
                page: actions.payload
            };
        case 'SET_ROWS_PER_PAGE_EVENT':
            return {
                ...state,
                rowsPerPage: actions.payload
            };
        case 'SET_DATEEVENT_EVENT':
            return {
                ...state,
                dateEvent: actions.payload
            };
        case 'SET_VALUEDATEEVENT_EVENT':
            return {
                ...state,
                valueDateEvent: actions.payload
            };
        case 'SET_CLEAR_EVENT':
            return {
                ...state,
                dateEvent: '',
                valueDateEvent: ''
            };
        default:
            return state;
    }
};

export default eventReducer;
