import styled from 'styled-components';
import { MobileDateTimePicker, DateTimePicker } from '@mui/x-date-pickers';

import { TextField } from '@mui/material';

export const ContainerDesktop = styled(DateTimePicker)`
    width: 100%;
`;

export const ContainerMobile = styled(MobileDateTimePicker)`
    width: 100%;
`;

export const Input = styled(TextField)`
    width: 100%;
`;
