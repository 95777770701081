import { app } from './Api';

const getHelp = async (page, qtdPerPage, question, answer) => {
    return app.get(`help?page=${page}&qtdPerPage=${qtdPerPage}&question=${question || ''}&answer=${answer || ''}`);
};
const searchAllHelp = async (question, answer) => {
    return app.get(`help?question=${question || ''}&answer=${answer || ''}`);
};
const deleteHelp = async (id) => {
    return app.delete(`help/${id}`);
};
const getHelpPerId = async (id) => {
    return app.get(`help/${id}`);
};
const updateHelp = async (id, data) => {
    return app.put(`help/${id}`, data);
};
const postHelp = async (data) => {
    return app.post('help', data);
};

export { getHelp, deleteHelp, getHelpPerId, updateHelp, postHelp, searchAllHelp };
