import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: ${(props) => (props.loading ? 'center' : 'flex-start')};
    align-items: ${(props) => (props.loading ? 'center' : 'flex-start')};
    cursor: pointer;
    > h1 {
        width: 100%;
        color: black;
    }
`;
export const RankColumn = styled.div`
    width: ${(props) => (props.totalItems <= 5 ? '100%' : '50%')};
    display: flex;
    flex-direction: column;
`;
export const RankItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 2.5px 5px;
    gap: 10px;
    border-bottom: 1px solid #c1c1c1;
    color: #25272e;
    font-weight: 400;
    overflow: hidden;
    cursor: pointer;
    > strong {
        min-width: 20px;
        max-width: 20px;
        text-align: right;
    }
    > span.image {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 32px;
        max-width: 32px;
        > img {
            min-width: 32px;
            max-width: 32px;
            min-height: 32px;
            max-height: 32px;
            border-radius: 50%;
            overflow: hidden;
        }
    }
    > span.user {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
