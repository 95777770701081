import { app } from './Api';

const getResourcerAbility = async () => {
    return app.get(`get_resource_skill`);
};
const postAbility = async (data) => {
    return app.post('skill', data);
};
const updateAbility = async (id, data) => {
    return app.post(`skill/${id}`, data);
};
const getAbilityPerId = async (id) => {
    return app.get(`skill/${id}`);
};
const getAllAbilities = async () => {
    return app.get(`skill`);
};
const deleteAbility = async (id) => {
    return app.delete(`skill/${id}`);
};
const getAbility = async (page, qtdPerPage, name) => {
    return app.get(`skill?page=${page}&qtdPerPage=${qtdPerPage}&name=${name || ''}`);
};
const searchAbilitys = async (name) => {
    return app.get(`skill?name=${name || ''}`);
};
export { getResourcerAbility, postAbility, updateAbility, getAbilityPerId, getAllAbilities, deleteAbility, getAbility, searchAbilitys };
