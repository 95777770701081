// material-ui
import { Box, debounce, Grid, TextField } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MENU_OPEN } from 'store/actions';
import Table from '../../../components/Table/Table';
import UserIcon from '@mui/icons-material/Person';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getSpeaker, searchSpeaker } from '../../../services/speakers';
import AddButton from 'components/Buttons/AddButton';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#015641',
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

const Speakers = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [searchTerm, setSearchTerm] = useState('');

    const debouncedSearch = useCallback(
        debounce((name) => {
            searchSpeaker(name).then((resp) => {
                setSpeaker(resp.data);
                dispatch({ type: 'SET_ROWS_PER_PAGE_USER', payload: parseInt(resp.data.total) });
            });
        }, 300),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        if (searchTerm) {
            debouncedSearch(searchTerm);
        } else {
            getAllSpeakers();
        }
    }, [searchTerm]);

    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'palestrantes');
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: 'palestrantes' });
        }
    }, []);

    const initialValue = {
        speakers: []
    };

    const [speaker, setSpeaker] = useState(initialValue);

    const getAllSpeakers = (page, rowsPerPage) => {
        getSpeaker(page + 1, rowsPerPage).then((resp) => setSpeaker(resp.data));
    };

    const searchSpeakers = (name) => {
        searchSpeaker(name).then((resp) => setSpeaker(resp.data));
    };

    useEffect(() => {
        getAllSpeakers();
    }, []);

    return (
        <Grid
            style={{
                backgroundColor: '#FFFFFF ',
                backgroundPosition: '50% 50%',
                backgroundSize: 'cover',
                width: '100%',
                height: '100%',
                padding: 0,
                margin: 0
            }}
            xs={12}
            md={12}
            sm={12}
            container
            item
            alignItems="center"
            padding={5}
        >
            <Grid container spacing={matchDownSM ? 2 : 2}>
                <Grid item xs={12} sm={6} md={8} sx={{ marginTop: 3 }}>
                    <h1
                        style={{
                            font: 'normal normal bold 35px/44px Roboto, sans-serif',
                            letterSpacing: '0px',
                            color: 'var(--unnamed-color-015641)',
                            Color: '#015641',
                            opacity: 1,
                            padding: 15,
                            marginLeft: '2%'
                        }}
                    >
                        <UserIcon /> PALESTRANTES
                    </h1>
                </Grid>
                <Grid item xs={12} sm={6} md={4} sx={{ marginTop: 8 }}>
                    <Box display="flex" justifyContent="right" alignItems="right" marginRight={2}>
                        <TextField
                            id="search"
                            label="Pesquisar"
                            fullWidth
                            variant="outlined"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Box>
                </Grid>
            </Grid>
            <hr style={{ width: '95%', marginTop: 0 }}></hr>
            <h3
                style={{
                    font: 'normal normal 300 18px/22px Roboto, sans-serif',
                    letterSpacing: '0px',
                    Color: '#00000',
                    opacity: 1,
                    padding: 15,
                    marginLeft: '2%'
                }}
            >
                Lista de Palestrante
            </h3>
            <Table
                header1="Foto"
                header2="Nome"
                header3="Descrição"
                header4="Ações"
                data={speaker.data ? speaker.data.map((value) => value) : []}
                speaker="true"
                page="/palestrante/"
                total={speaker.total || 0}
                getData={getAllSpeakers}
            ></Table>
            <AddButton href="/novo_palestrante" />
        </Grid>
    );
};

export default Speakers;
