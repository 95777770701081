import axios from 'axios';
import { URL } from 'baseURL/baseURL';

const baseURL = URL;

const app = axios.create({
    baseURL
});

const setAuthHeader = (config) => {
    return {
        ...config,
        headers: {
            ...config.headers,
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    };
};

app.interceptors.request.use(
    (config) => {
        return setAuthHeader(config);
    },
    (error) => Promise.reject(error)
);

const getUsers = async (page, qtdPerPage, name) => {
    const response = await app.get(`user?page=${page}&qtdPerPage=${qtdPerPage}&name=${name || ''}`);
    console.log('Response Headers:', response.config.headers); // Log the headers to verify Authorization header
    return response;
};

const auth = axios.create({
    baseURL
});

const blob = axios.create({
    baseURL,
    responseType: 'blob'
});

blob.interceptors.request.use(
    (config) => {
        return setAuthHeader(config);
    },
    (error) => Promise.reject(error)
);

export { auth, app, blob };
