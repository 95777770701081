import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { TablePaginationActions } from '../../../components/Pagination/TablePaginationActions';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate, Link } from 'react-router-dom';
import BasicModal from '../../../components/Modal/BasicModal';
import ModalFilter from 'components/Modal/ModalFilter';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import FilterIcon from '@mui/icons-material/FilterAlt';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import { MENU_OPEN } from 'store/actions';
import { getLibrary, deleteLibrary, changeStatusLibrary, searchLibrarys, getResourcerLibrary } from 'services/library';
import 'react-toggle/style.css';
import AnimateButton from 'ui-component/extended/AnimateButton';
import Toggle from 'react-toggle';
import { Grid, Box, MenuItem, TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import debounce from 'lodash.debounce';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#015641',
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

export default function GridLibrary() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const rowsPerPage = useSelector((state) => state.library.rowsPerPage);
    const page = useSelector((state) => state.library.page);
    const typeLibrary = useSelector((state) => state.library.typeLibrary);
    const [librarys, setLibrarys] = React.useState([]);
    const [success, setSuccess] = React.useState('');
    const [error, setError] = React.useState('');
    const [idDestroyLibrary, setIdDestroyLibrary] = React.useState('');
    const [openModal, setOpenModal] = React.useState(false);
    const [openModalFilter, setOpenModalFilter] = React.useState(false);
    const [options, setOptions] = React.useState('');
    const [searchTerm, setSearchTerm] = React.useState('');

    const debouncedSearch = React.useCallback(
        debounce((title) => {
            searchLibrary(title);
        }, 300),
        []
    );

    const withLink = (to, children) => <Link to={to}>{children}</Link>;
    const actions = [
        { icon: withLink('/novo_arquivo', <MenuBookOutlinedIcon sx={{ color: '#015641', marginTop: 1 }} />), name: 'Novo arquivo' },
        { icon: withLink('#/', <FilterIcon sx={{ color: '#015641', marginTop: 1 }} />), name: 'Filtros', operation: 'filtros' }
    ];

    const handleOpenDestroy = (idDestroy) => {
        setIdDestroyLibrary(idDestroy);
        setOpenModal(true);
    };

    const handleCloseModal = () => setOpenModal(false);

    const handleChangePage = (library, newPage) => {
        dispatch({ type: 'SET_PAGE_LIBRARY', payload: newPage });
    };

    const handleChangeRowsPerPage = (library) => {
        dispatch({ type: 'SET_ROWS_PER_PAGE_LIBRARY', payload: parseInt(library.target.value, 10) });
        dispatch({ type: 'SET_PAGE_LIBRARY', payload: 0 });
    };

    const handleCloseModalFilter = () => setOpenModalFilter(false);

    const getAllResourceLibrary = () => {
        getResourcerLibrary().then((resp) => setOptions(resp.data));
    };

    const getAllLibrarys = () => {
        getLibrary(page + 1, rowsPerPage, typeLibrary).then((resp) => setLibrarys(resp.data));
    };

    const searchLibrary = (title) => {
        searchLibrarys(title, typeLibrary).then((resp) => setLibrarys(resp.data));
    };

    const changeStatusActive = (event) => {
        const id = event.target.id;
        let checked = event.target.checked === true ? 1 : 0;
        const data = {
            post: checked
        };
        changeStatusLibrary(id, data)
            .then(() => getAllLibrarys())
            .catch(() => getAllLibrarys());
    };

    const deleteLibraryById = () => {
        handleCloseModal();
        deleteLibrary(idDestroyLibrary)
            .then((resp) => {
                getAllLibrarys();
                setError('');
                setSuccess(resp.data.success);
                setIdDestroyLibrary('');
                setTimeout(() => {
                    setSuccess('');
                }, 3000);
            })
            .catch((e) => {
                console.log(e);
                setSuccess('');
                setError(e.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    };

    React.useEffect(() => {
        if (searchTerm) {
            debouncedSearch(searchTerm);
        } else {
            getAllLibrarys();
        }
    }, [searchTerm, page, rowsPerPage]);

    React.useEffect(() => {
        getAllResourceLibrary();
    }, []);

    React.useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'biblioteca');
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: 'biblioteca' });
        }
    }, []);

    return (
        <>
            <BasicModal
                open={openModal}
                title="Excluir Arquivo"
                handleClose={handleCloseModal}
                description="Tem certeza que deseja excluir o arquivo?"
                onDelete={deleteLibraryById}
            />
            <ModalFilter
                open={openModalFilter}
                title="Filtros"
                handleClose={handleCloseModalFilter}
                content={
                    <>
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={12} sm={12} m sx={{ marginTop: 3 }}>
                                <TextField
                                    fullWidth
                                    select
                                    label="Tipo de Biblioteca"
                                    id="typeObject"
                                    type="text"
                                    value={typeLibrary}
                                    name="typeObject"
                                    onChange={(e) => dispatch({ type: 'SET_TYPEOBJECTLIBRARY_LIBRARY', payload: e.target.value })}
                                >
                                    {options !== '' &&
                                        options.typeLearningObject.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.description}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" justifyContent="center" sx={{ mt: 3 }}>
                            <Grid item>
                                <Box sx={{ mt: 2, mr: 3 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="error"
                                            onClick={(e) => [
                                                dispatch({ type: 'SET_CLEAR_TYPEOBJECTLIBRARY' }),
                                                setOpenModalFilter(false),
                                                getAllLibrarys()
                                            ]}
                                        >
                                            Limpar Busca
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={{ mt: 2, mr: 3 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            fullWidth
                                            size="large"
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) => [searchLibrary(), setOpenModalFilter(false)]}
                                        >
                                            Buscar
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                }
            />
            <TableContainer sx={{ mt: 2, height: '100%', padding: 3 }} component={Paper}>
                <Grid container spacing={matchDownSM ? 2 : 2}>
                    <Grid item xs={12} sm={6} md={8} sx={{ marginTop: 3 }}>
                        <h1
                            style={{
                                font: 'normal normal bold 35px/44px Roboto, sans-serif',
                                letterSpacing: '0px',
                                color: 'var(--unnamed-color-015641)',
                                Color: '#015641',
                                opacity: 1,
                                padding: 15,
                                marginLeft: '2%'
                            }}
                        >
                            <MenuBookOutlinedIcon /> BIBLIOTECA
                        </h1>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ marginTop: 8 }}>
                        <Box display="flex" justifyContent="right" alignItems="right" marginRight={2}>
                            <TextField
                                id="search"
                                label="Pesquisar"
                                fullWidth
                                variant="outlined"
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <hr style={{ width: '95%', marginTop: 0 }}></hr>
                <h3
                    style={{
                        font: 'normal normal 300 18px/22px Roboto, sans-serif',
                        letterSpacing: '0px',
                        Color: '#00000',
                        opacity: 1,
                        padding: 15,
                        marginLeft: '2%'
                    }}
                >
                    Gerencie a biblioteca
                </h3>
                {error || success ? (
                    <Snackbar open={true} autoHideDuration={6000}>
                        <Alert
                            severity={error ? 'error' : success ? 'success' : ''}
                            sx={{
                                width: '100%',
                                backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                color: '#FFF'
                            }}
                        >
                            {error ? error : success ? success : ''}
                        </Alert>
                    </Snackbar>
                ) : (
                    ''
                )}
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Título</StyledTableCell>
                            <StyledTableCell>Pontos</StyledTableCell>
                            <StyledTableCell>Tipo</StyledTableCell>
                            <StyledTableCell>Ações</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {librarys.data &&
                            librarys.data.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell>
                                        <Toggle id={`${row.id}`} checked={row.post === 1 ? true : false} onChange={changeStatusActive} />
                                    </StyledTableCell>
                                    <StyledTableCell>{row.title}</StyledTableCell>
                                    <StyledTableCell>{row.point}</StyledTableCell>
                                    <StyledTableCell>{row.typeLearningObject}</StyledTableCell>
                                    <Tooltip title="Visualizar">
                                        <IconButton onClick={() => navigate({ pathname: `/arquivo/${row.id}/view` })}>
                                            <ViewIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Editar">
                                        <IconButton onClick={() => navigate({ pathname: `/arquivo/${row.id}/edit` })}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Excluir">
                                        <IconButton onClick={() => handleOpenDestroy(row.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </StyledTableRow>
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={3}
                                count={librarys.total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'Registros por Página'
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <SpeedDial
                direction="left"
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                FabProps={{
                    sx: {
                        bgcolor: '#015641',
                        '&:hover': {
                            bgcolor: '#0a8f53'
                        }
                    }
                }}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={(e) => (action.operation === 'filtros' ? [e.stopPropagation(), setOpenModalFilter(true)] : '')}
                    />
                ))}
            </SpeedDial>
        </>
    );
}
