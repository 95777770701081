export const initialState = {
    page: 0,
    rowsPerPage: 10,
    nameListItem: '',
    idList: ''
};

// ==============================|| AUTH REDUCER ||============================== //

const listItemReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_PAGE_LISTITEM':
            return {
                ...state,
                page: actions.payload
            };
        case 'SET_ROWS_PER_PAGE_LISTITEM':
            return {
                ...state,
                rowsPerPage: actions.payload
            };
        case 'SET_NAMELISTITEM_LISTITEM_FILTER':
            return {
                ...state,
                nameListItem: actions.payload
            };
        case 'SET_IDLIST_LISTITEM_FILTER':
            return {
                ...state,
                idList: actions.payload
            };
        case 'SET_CLEAR_LISTITEM_FILTER':
            return {
                ...state,
                nameListItem: '',
                idList: ''
            };
        default:
            return state;
    }
};

export default listItemReducer;
