import React, { useEffect, useState } from 'react';
import { Container, RankColumn, RankItem } from './styles';
import { CircularProgress, Tooltip } from '@mui/material';
import { getRankingBI } from '../../../services/bi';
import UserRankingFull from '../UserRankingFull';

const UserRanking = () => {
    const [loading, setLoading] = useState(true);
    const [ranking, setRanking] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    const handleRankingModal = () => {
        setOpenModal(!openModal);
    };

    useEffect(() => {
        getRankingBI().then((resp) => {
            setRanking(() => resp.content.ranking);
            setLoading(() => false);
        });
    }, []);
    if (loading) {
        return (
            <Container loading>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container onClick={handleRankingModal}>
            <h1>Ranking de usuários</h1>
            <RankColumn totalItems={ranking.length}>
                {ranking.slice(0, 5).map((rank, key) => (
                    <Tooltip followCursor key={`ranking-${key}`} title={`${rank.access} acessos`} placement={'top'}>
                        <RankItem>
                            <strong>{key + 1}º</strong>
                            <span className={'image'}>
                                <img src={rank.user_image} alt="" />
                            </span>
                            <span className={'user'}>{rank.user}</span>
                        </RankItem>
                    </Tooltip>
                ))}
            </RankColumn>
            {ranking.length > 5 && (
                <RankColumn>
                    {ranking.slice(5, 100).map((rank, key) => (
                        <Tooltip followCursor key={`ranking-${key + 5}`} title={`${rank.access} acessos`} placement={'top'}>
                            <RankItem>
                                <strong>{key + 5 + 1}º</strong>
                                <span className={'image'}>
                                    <img src={rank.user_image} alt={rank.user} />
                                </span>
                                <span className={'user'}>{rank.user}</span>
                            </RankItem>
                        </Tooltip>
                    ))}
                </RankColumn>
            )}
            {<UserRankingFull open={openModal} handleClose={handleRankingModal} />}
        </Container>
    );
};
export default UserRanking;
