export const initialState = {
    page: 0,
    rowsPerPage: 10
};

const courseReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_PAGE_COURSE':
            return {
                ...state,
                page: actions.payload
            };
        case 'SET_ROWS_PER_PAGE_COURSE':
            return {
                ...state,
                rowsPerPage: actions.payload
            };
        default:
            return state;
    }
};

export default courseReducer;
