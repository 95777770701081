import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// material-ui
import { useTheme, createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Box, Button, FormHelperText, Grid, TextField, useMediaQuery, Alert, Snackbar } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import SnackBar from '../../../components/SnackBar/SnackBar';
// assets
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import Loading from 'components/Loading/Loading';
import { getSpeakerPerId, postSpeaker, updateSpeaker } from 'services/speakers';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
const FormRegister = ({ ...others }) => {
    const params = useParams();
    const themeButton = createTheme({
        status: {
            danger: '#e53e3e'
        },
        palette: {
            primary: {
                main: '#0971f1',
                darker: '#053e85'
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff'
            }
        }
    });
    const Input = styled('input')({
        display: 'none',
        backgroundColor: '#015641'
    });

    const initialValuesEdit = {
        nameSpeaker: '',
        descriptionSpeaker: '',
        file: ''
    };

    const theme = useTheme();
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [errorValidate, setErrorValidate] = useState({});
    const [valuesEdit, setValuesEdit] = useState(initialValuesEdit);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackBarType, setSnackBarType] = useState('');
    const [file, setFile] = useState(initialValuesEdit);
    const [image, setImage] = useState([]);
    const titlePage =
        params.action === 'view'
            ? 'Visualização de Palestrante'
            : params.action === 'edit'
            ? 'Edição de Palestrante'
            : 'Cadastro de Palestrante';
    const isDisabled = params.action === 'view' ? true : false;
    useEffect(() => {
        viewPerId();
    }, [params.id]);
    function viewPerId() {
        params.action &&
            getSpeakerPerId(params.id).then((resp) => {
                console.log('resp view per id SPEAKER-> ', resp);
                setValuesEdit({
                    ['nameSpeaker']: resp.data.name,
                    ['descriptionSpeaker']: resp.data.description,
                    ['file']: resp.data.file.length > 0 ? resp.data.file[0].url : ''
                });
            });
    }
    useEffect(() => {
        setPhoto();
        return () => {
            setPhoto();
        };
    }, []);
    function setPhoto() {
        setFile(initialValuesEdit);
    }
    const handlerClick = () => {
        setOpenSnackBar(false);
    };
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };

    const handleDrop = (dropped) => {
        setImage(dropped[0]);
    };
    return (
        <>
            <Formik
                initialValues={{
                    nameSpeaker: valuesEdit.nameSpeaker,
                    descriptionSpeaker: valuesEdit.descriptionSpeaker,
                    file: valuesEdit.file,
                    submit: null
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    nameSpeaker: Yup.string().max(255).trim().required('Nome obrigatório')
                    //description: Yup.string().description('Digite uma descrição válida').max(255).required('Descrição obrigatória')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                        const data = new FormData();
                        data.append('nameSpeaker', values.nameSpeaker);
                        data.append('descriptionSpeaker', values.descriptionSpeaker);
                        data.append('file', values.file);
                        if (params.action === 'edit') {
                            data.append('_method', 'put');
                            updateSpeaker(params.id, data, {
                                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                            })
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    viewPerId();
                                    setSnackBarMessage('Palestrante editado com sucesso!');
                                    setOpenSnackBar(true);
                                    setLoading(true);
                                    setTimeout(() => {
                                        setSnackBarMessage('');
                                        navigate('/palestrantes');
                                    }, 1500);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    setErrorValidate(e.response.values.errorValidate);
                                    setError(e.response.values.error);
                                });
                        } else {
                            setLoading(true);
                            postSpeaker(data, {
                                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                            })
                                .then((resp) => {
                                    setError('');
                                    setSnackBarMessage('Palestrante cadastrado com sucesso!');
                                    setOpenSnackBar(true);
                                    setTimeout(() => {
                                        setSnackBarMessage('');
                                        navigate('/palestrantes');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    setErrorValidate(e.response.values.errorValidate);
                                    setError(e.response.values.error);
                                });
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <>
                        {loading && (
                            <Grid container alignItems="center" justifyContent="center">
                                <MainCard style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Loading color="#015641" type="spinningBubbles" />
                                </MainCard>
                            </Grid>
                        )}
                        <MainCard spacing={gridSpacing} style={{ padding: 15, margin: 25 }}>
                            {error || alert ? (
                                <Snackbar open={true} autoHideDuration={6000}>
                                    <Alert
                                        severity={error ? 'error' : success ? 'success' : ''}
                                        sx={{
                                            width: '100%',
                                            backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                            color: '#FFF'
                                        }}
                                    >
                                        {error ? error : success ? success : ''}
                                    </Alert>
                                </Snackbar>
                            ) : (
                                ''
                            )}
                            <div style={{ display: loading ? 'none' : 'block' }}>
                                <form noValidate onSubmit={handleSubmit} {...others}>
                                    <h3>{titlePage}</h3>
                                    <hr></hr>
                                    <Grid container alignItems="center" justifyContent="center">
                                        <Dropzone
                                            onDrop={(e) => setFieldValue('file', e[0])}
                                            style={{ width: '150px', height: '150px', BorderStyle: 'none', border: 0 }}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps()}>
                                                    <AvatarEditor
                                                        onPositionChange={''}
                                                        disablecanvasrotation
                                                        width={150}
                                                        height={150}
                                                        image={values.file}
                                                        borderRadius={100}
                                                        border={1}
                                                        style={{
                                                            borderRadius: 100,
                                                            BorderStyle: 'none',
                                                            verticalAlign: 'middle',
                                                            cursor: 'pointer',
                                                            marginBlockStart: '1em',
                                                            marginBlockEnd: '1em',
                                                            marginInlineStart: '40px',
                                                            marginInlineEnd: '40px'
                                                        }}
                                                        // scale={1.2}
                                                        // className="rounded-circle"
                                                    />
                                                    <input {...getInputProps()} />
                                                </div>
                                            )}
                                        </Dropzone>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.name && errors.name) || Boolean(errorValidate.name)}
                                                id="outlined-nameSpeaker"
                                                type="text"
                                                label="Nome do palestrante"
                                                value={values.nameSpeaker}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="nameSpeaker"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.name && errors.name ? errors.name : errorValidate.name ? errorValidate.name : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={
                                                    Boolean(touched.description && errors.description) || Boolean(errorValidate.description)
                                                }
                                                id="outlined-descriptionSpeaker"
                                                placeholder="Descrição do palestrante"
                                                multiline
                                                rows={4}
                                                label="Descrição"
                                                value={values.descriptionSpeaker}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="descriptionSpeaker"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.email && errors.email
                                                        ? errors.email
                                                        : errorValidate.email
                                                        ? errorValidate.email
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="end" justifyContent="end" sx={{ mt: 3 }}>
                                        <Grid item>
                                            <Box sx={{ mt: 2, mr: 3 }}>
                                                <ThemeProvider theme={themeButton}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="button"
                                                            variant="contained"
                                                            color="neutral"
                                                            onClick={() => navigate(-1)}
                                                        >
                                                            Voltar
                                                        </Button>
                                                    </AnimateButton>
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                        {params.action === 'view' ? (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            // disabled={isSubmitting}
                                                            component={Link}
                                                            to={`/palestrante/${params.id}/edit`}
                                                            fullWidth
                                                            size="large"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Editar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        ) : (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Salvar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                </form>
                            </div>
                        </MainCard>
                    </>
                )}
            </Formik>
            <SnackBar
                openSnackBar={openSnackBar}
                message={snackBarMessage}
                type={snackBarType}
                setOpenSnackBar={setOpenSnackBar}
                onClick={handlerClick}
            />
        </>
    );
};

export default FormRegister;
