import { app } from './Api';

const getCourses = async (page, qtdPerPage, title) => {
    return app.get(`course?page=${page}&qtdPerPage=${qtdPerPage}&title=${title || ''}`);
};
const searchCourses = async (title) => {
    return app.get(`course?title=${title || ''}`);
};
const deleteCourse = async (id) => {
    return app.delete(`course/${id}`);
};
const changeStatusCourse = async (id, data) => {
    return app.put(`change_status_course/${id}`, data);
};
const getCoursePerId = async (id) => {
    return app.get(`course/${id}`);
};
const updateCourse = async (id, data) => {
    return app.post(`course/${id}`, data);
};
const postCourse = async (data) => {
    return app.post('course', data);
};
const getResourcerCourse = async () => {
    return app.get(`get_resource_course`);
};
const backToTheTopCourse = async (data) => {
    return app.post(`back_course_top`, data);
};

export {
    getCourses,
    deleteCourse,
    changeStatusCourse,
    getCoursePerId,
    updateCourse,
    postCourse,
    getResourcerCourse,
    backToTheTopCourse,
    searchCourses
};
