// material-ui
import styled from 'styled-components';
import Fundo from '../../../components/Images/fundo.png';

// background-image: url('https://drive.google.com/uc?id=1ZdNLTJrGAkwKdcS9M5G09V0g5-I1NWSc');
const AuthWrapper1 = styled.div`
    background-image: url(${Fundo});
    background-size: cover !important;
    background-position: center center;
    background-repeat: no-repeat;
`;

export default AuthWrapper1;
