import { app } from './Api';

const getLibrary = async (page, qtdPerPage, typeLibrary) => {
    return app.get(`library?page=${page}&qtdPerPage=${qtdPerPage}&idTypeLearningObject=${typeLibrary || ''}`);
};
const searchLibrarys = async (title, typeLibrary) => {
    return app.get(`library?title=${title || ''}&idTypeLearningObject=${typeLibrary || ''}`);
};
const postLibrary = async (data) => {
    return app.post('library', data);
};
const updateLibrary = async (id, data) => {
    return app.post(`library/${id}`, data);
};
const getResourcerLibrary = async () => {
    return app.get(`get_resource_library`);
};
const getLibraryPerId = async (id) => {
    return app.get(`library/${id}`);
};
const deleteLibrary = async (id) => {
    return app.delete(`library/${id}`);
};
const changeStatusLibrary = async (id, data) => {
    return app.put(`change_status_library/${id}`, data);
};
const getAllEvents = async () => {
    return app.get(`get_event_finished`);
};
export {
    getLibrary,
    postLibrary,
    updateLibrary,
    getResourcerLibrary,
    getLibraryPerId,
    deleteLibrary,
    changeStatusLibrary,
    searchLibrarys,
    getAllEvents
};
