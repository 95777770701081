import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { TablePaginationActions } from '../../../components/Pagination/TablePaginationActions';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import BasicModal from '../../../components/Modal/BasicModal';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import { Grid, Box } from '@mui/material';
import { MENU_OPEN } from 'store/actions';
import 'react-toggle/style.css';
import AddButton from 'components/Buttons/AddButton';
import { getQuiz, deleteQuiz } from 'services/quiz';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#015641',
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

export default function GridQuiz() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const page = useSelector((state) => state.quiz.page);
    const rowsPerPage = useSelector((state) => state.quiz.rowsPerPage);
    const [quiz, setQuiz] = React.useState('');
    const [success, setSuccess] = React.useState('');
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [error, setError] = React.useState('');
    const [idDestroyQuiz, setIdDestroyQuiz] = React.useState('');
    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenDestroy = (idDestroy) => {
        setIdDestroyQuiz(idDestroy);
        setOpenModal(true);
    };
    const handleCloseModal = () => setOpenModal(false);
    const handleChangePage = (quiz, newPage) => {
        dispatch({ type: 'SET_PAGE_QUIZ', payload: newPage });
    };
    const handleChangeRowsPerPage = (quiz) => {
        dispatch({ type: 'SET_ROWS_PER_PAGE_QUIZ', payload: parseInt(quiz.target.value, 10) });
        dispatch({ type: 'SET_PAGE_QUIZ', payload: 0 });
    };

    const getAllQuiz = () => {
        getQuiz(page + 1, rowsPerPage).then((resp) => setQuiz(resp.data));
    };

    const deleteQuizById = () => {
        handleCloseModal();
        deleteQuiz(idDestroyQuiz)
            .then((resp) => {
                getAllQuiz();
                setError('');
                setSuccess(resp.data.success);
                setIdDestroyQuiz('');
                setTimeout(() => {
                    setSuccess('');
                }, 3000);
            })
            .catch((e) => {
                console.log(e);
                setSuccess('');
                setError(e.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    };

    React.useEffect(() => {
        getAllQuiz();
    }, [page, rowsPerPage]);

    React.useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'questionario');
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: 'questionario' });
        }
    }, []);

    return (
        <>
            <BasicModal
                open={openModal}
                title="Excluir Questionário"
                handleClose={handleCloseModal}
                description="Tem certeza que deseja excluir o questionário?"
                onDelete={deleteQuizById}
            />
            <TableContainer sx={{ mt: 2, height: '100%', padding: 3 }} component={Paper}>
                <Grid container spacing={matchDownSM ? 2 : 2}>
                    <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                        <h1
                            style={{
                                font: 'normal normal bold 35px/44px Roboto, sans-serif',
                                letterSpacing: '0px',
                                color: 'var(--unnamed-color-015641)',
                                Color: '#015641',
                                opacity: 1,
                                padding: 15,
                                marginLeft: '2%'
                            }}
                        >
                            <QuizOutlinedIcon /> QUESTIONÁRIOS
                        </h1>
                    </Grid>
                </Grid>
                <hr style={{ width: '95%', marginTop: 0 }}></hr>
                <h3
                    style={{
                        font: 'normal normal 300 18px/22px Roboto, sans-serif',
                        letterSpacing: '0px',
                        Color: '#00000',
                        opacity: 1,
                        padding: 15,
                        marginLeft: '2%'
                    }}
                >
                    Gerencie seus questionários
                </h3>
                {error || success ? (
                    <Snackbar open={true} autoHideDuration={6000}>
                        <Alert
                            severity={error ? 'error' : success ? 'success' : ''}
                            sx={{
                                width: '100%',
                                backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                color: '#FFF'
                            }}
                        >
                            {error ? error : success ? success : ''}
                        </Alert>
                    </Snackbar>
                ) : (
                    ''
                )}
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Título</StyledTableCell>
                            <StyledTableCell>Aula</StyledTableCell>
                            <StyledTableCell>Curso</StyledTableCell>
                            <StyledTableCell>Ações</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {quiz !== '' &&
                            quiz.data.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell>{row.title}</StyledTableCell>
                                    <StyledTableCell>{row.class}</StyledTableCell>
                                    <StyledTableCell>{row.course}</StyledTableCell>
                                    <Tooltip title="Visualizar">
                                        <IconButton onClick={() => navigate({ pathname: `/questionario/${row.id}/view` })}>
                                            <ViewIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Editar">
                                        <IconButton onClick={() => navigate({ pathname: `/questionario/${row.id}/edit` })}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Excluir">
                                        <IconButton onClick={() => handleOpenDestroy(row.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </StyledTableRow>
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={3}
                                count={quiz.total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'Registros por Página'
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <AddButton href="/novo_questionario" />
        </>
    );
}
