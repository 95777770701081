import { combineReducers } from 'redux';
import authReducer from './authReducer';

// reducer import
import customizationReducer from './customizationReducer';
import listItemReducer from './listItemReducer';
import listReducer from './listReducer';
import userReducer from './userReducer';
import eventReducer from './eventReducer';
import classeReducer from './classeReducer';
import courseReducer from './courseReducer';
import levelReducer from './levelReducer';
import speakerReducer from './speakerReducer';
import abilityReducer from './ability';
import libraryReducer from './libraryReducer';
import publicationReducer from './publicationReducer';
import quizReducer from './quizReducer';
import helpReducer from './helpReducer';
import BIReducer from './BIReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    auth: authReducer,
    user: userReducer,
    list: listReducer,
    listItem: listItemReducer,
    event: eventReducer,
    classe: classeReducer,
    level: levelReducer,
    ability: abilityReducer,
    course: courseReducer,
    speaker: speakerReducer,
    library: libraryReducer,
    publication: publicationReducer,
    quiz: quizReducer,
    help: helpReducer,
    bi: BIReducer
});

export default reducer;
