import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box,
    Button,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    useMediaQuery,
    MenuItem,
    Alert,
    Snackbar
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import SnackBar from '../../../components/SnackBar/SnackBar';
// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import Loading from 'components/Loading/Loading';
import { getUserPerId, postUser, updateUser, getResourcerUser } from 'services/user';

const FormRegister = ({ ...others }) => {
    const params = useParams();
    const themeButton = createTheme({
        status: {
            danger: '#e53e3e'
        },
        palette: {
            primary: {
                main: '#0971f1',
                darker: '#053e85'
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff'
            }
        }
    });
    const initialValuesEdit = {
        name: '',
        email: '',
        role: ''
    };

    const theme = useTheme();
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [errorValidate, setErrorValidate] = useState({});
    const [valuesEdit, setValuesEdit] = useState(initialValuesEdit);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showNewPasswordAdm, setShowNewPasswordAdm] = useState(false);
    const [showConfirmNewPasswordAdm, setShowConfirmNewPasswordAdm] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackBarType, setSnackBarType] = useState('');
    const [options, setOptions] = useState('');
    const titlePage =
        params.action === 'view' ? 'Visualização de Usuário' : params.action === 'edit' ? 'Edição de Usuário' : 'Cadastro de Usuário';
    const isDisabled = params.action === 'view' ? true : false;

    const viewPerId = () => {
        params.action &&
            getUserPerId(params.id).then((resp) => {
                setValuesEdit({
                    name: resp.data.name,
                    email: resp.data.email,
                    role: resp.data.id_role
                });
            });
    };

    useEffect(() => {
        getResource();
    }, []);

    useEffect(() => {
        viewPerId();
    }, [params.id]);

    const handlerClick = () => {
        setOpenSnackBar(false);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleClickShowNewPasswordAdm = () => {
        setShowNewPasswordAdm(!showNewPasswordAdm);
    };

    const handleClickShowConfirmNewPasswordAdm = () => {
        setShowConfirmNewPasswordAdm(!showConfirmNewPasswordAdm);
    };

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseDownNewPasswordAdm = (event) => {
        event.preventDefault();
    };

    const handleMouseDownConfirmNewPasswordAdm = (event) => {
        event.preventDefault();
    };

    function getResource() {
        getResourcerUser().then((resp) => setOptions(resp.data.roles));
    }

    return (
        <>
            <Formik
                initialValues={{
                    name: valuesEdit.name,
                    email: valuesEdit.email,
                    password: '',
                    confirm_password: '',
                    submit: null,
                    role: valuesEdit.role,
                    new_password_adm: '',
                    confirm_new_password_adm: ''
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(255).trim().required('Nome obrigatório'),
                    email: Yup.string().email('Digite um email válido').max(255).required('Email obrigatório'),
                    password: !params.action ? Yup.string().max(255).required('Senha obrigatório') : '',
                    role: Yup.string().required('Escolha um perfil para esse usuário'),
                    confirm_password: !params.action
                        ? Yup.string()
                              .max(255)
                              .oneOf([Yup.ref('password'), null], 'As senhas não conferem')
                              .required('Confirmar Senha obrigatório')
                        : '',
                    new_password_adm: Yup.string().max(255),
                    confirm_new_password_adm: Yup.string()
                        .max(255)
                        .oneOf([Yup.ref('new_password_adm'), null], 'As senhas não conferem')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }

                        if (params.action === 'edit') {
                            if (values.new_password_adm != '' && values.confirm_new_password_adm == '') {
                                setError('Preencha o campo de confirmar senha');
                                setTimeout(() => {
                                    setError('');
                                }, 1500);
                                return;
                            }
                            updateUser(params.id, { ...values })
                                .then(() => {
                                    setError('');
                                    setLoading(false);
                                    viewPerId();
                                    setSnackBarMessage('Usuário editado com sucesso!');
                                    setOpenSnackBar(true);
                                    setLoading(true);
                                    setTimeout(() => {
                                        setSnackBarMessage('');
                                        navigate('/usuarios');
                                    }, 1500);
                                })
                                .catch((e) => {
                                    console.log(e);
                                    setLoading(false);
                                    setSuccess('');
                                    setErrorValidate(e.response.data.errorValidate);
                                    setError(e.response.data.error);
                                });
                        } else {
                            setLoading(true);
                            postUser({ ...values })
                                .then(() => {
                                    setError('');
                                    setSnackBarMessage('Usuário cadastrado com sucesso!');
                                    setOpenSnackBar(true);
                                    setTimeout(() => {
                                        setSnackBarMessage('');
                                        navigate('/usuarios');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    setErrorValidate(e.response.data.errorValidate);
                                    setError(e.response.data.error);
                                });
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <>
                        {loading && (
                            <Grid container alignItems="center" justifyContent="center">
                                <MainCard style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Loading color="#015641" type="spinningBubbles" />
                                </MainCard>
                            </Grid>
                        )}
                        <MainCard spacing={gridSpacing} style={{ padding: 15, margin: 25 }}>
                            {error || alert ? (
                                <Snackbar open={true} autoHideDuration={6000}>
                                    <Alert
                                        severity={error ? 'error' : success ? 'success' : ''}
                                        sx={{
                                            width: '100%',
                                            backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                            color: '#FFF'
                                        }}
                                    >
                                        {error ? error : success ? success : ''}
                                    </Alert>
                                </Snackbar>
                            ) : (
                                ''
                            )}
                            <div style={{ display: loading ? 'none' : 'block' }}>
                                <form noValidate onSubmit={handleSubmit} {...others}>
                                    <h3>{titlePage}</h3>
                                    <hr></hr>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.name && errors.name) || Boolean(errorValidate.name)}
                                                id="outlined-name"
                                                type="text"
                                                label="Nome"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="name"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.name && errors.name ? errors.name : errorValidate.name ? errorValidate.name : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.email && errors.email) || Boolean(errorValidate.email)}
                                                id="outlined-email"
                                                type="email"
                                                label="Email"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="email"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.email && errors.email
                                                        ? errors.email
                                                        : errorValidate.email
                                                        ? errorValidate.email
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    {!params.action && (
                                        <Grid container spacing={matchDownSM ? 0 : 2}>
                                            <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                                <TextField
                                                    fullWidth
                                                    error={Boolean(touched.password && errors.password) || Boolean(errorValidate.password)}
                                                    id="outlined-adornment-password-register"
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={values.password}
                                                    name="password"
                                                    label="Senha"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={isDisabled}
                                                    helperText={
                                                        touched.password && errors.password
                                                            ? errors.password
                                                            : errorValidate.password
                                                            ? errorValidate.password
                                                            : ''
                                                    }
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    edge="end"
                                                                    size="large"
                                                                >
                                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                                <TextField
                                                    fullWidth
                                                    error={
                                                        Boolean(touched.confirm_password && errors.confirm_password) ||
                                                        Boolean(errorValidate.confirm_password)
                                                    }
                                                    id="outlined-adornment-confirm_password-register"
                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                    value={values.confirm_password}
                                                    name="confirm_password"
                                                    label="Confirme a Senha"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={isDisabled}
                                                    helperText={
                                                        touched.confirm_password && errors.confirm_password
                                                            ? errors.confirm_password
                                                            : errorValidate.confirm_password
                                                            ? errorValidate.confirm_password
                                                            : ''
                                                    }
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle confirm_password visibility"
                                                                    onClick={handleClickShowConfirmPassword}
                                                                    onMouseDown={handleMouseDownConfirmPassword}
                                                                    edge="end"
                                                                    size="large"
                                                                >
                                                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.role && errors.role) || Boolean(errorValidate.role)}
                                                select
                                                label="Perfil"
                                                id="role"
                                                type="text"
                                                value={values.role || ''}
                                                name="role"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.role && errors.role ? errors.role : errorValidate.role ? errorValidate.role : ''
                                                }
                                            >
                                                {options &&
                                                    options.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.description}
                                                        </MenuItem>
                                                    ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    {params.action === 'edit' && (
                                        <Grid container spacing={matchDownSM ? 0 : 2}>
                                            <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                                <TextField
                                                    fullWidth
                                                    error={
                                                        Boolean(touched.new_password_adm && errors.new_password_adm) ||
                                                        Boolean(errorValidate.new_password_adm)
                                                    }
                                                    id="outlined-adornment-password-register"
                                                    type={showNewPasswordAdm ? 'text' : 'password'}
                                                    value={values.new_password_adm}
                                                    name="new_password_adm"
                                                    label="Alterar Senha do Usuário"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={isDisabled}
                                                    helperText={
                                                        touched.new_password_adm && errors.new_password_adm
                                                            ? errors.new_password_adm
                                                            : errorValidate.new_password_adm
                                                            ? errorValidate.new_password_adm
                                                            : ''
                                                    }
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowNewPasswordAdm}
                                                                    onMouseDown={handleMouseDownNewPasswordAdm}
                                                                    edge="end"
                                                                    size="large"
                                                                >
                                                                    {showNewPasswordAdm ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                                <TextField
                                                    fullWidth
                                                    error={
                                                        Boolean(touched.confirm_new_password_adm && errors.confirm_new_password_adm) ||
                                                        Boolean(errorValidate.confirm_new_password_adm)
                                                    }
                                                    id="outlined-adornment-password-register"
                                                    type={showConfirmNewPasswordAdm ? 'text' : 'password'}
                                                    value={values.confirm_new_password_adm}
                                                    name="confirm_new_password_adm"
                                                    label="Confirme a Senha"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={isDisabled}
                                                    helperText={
                                                        touched.confirm_new_password_adm && errors.confirm_new_password_adm
                                                            ? errors.confirm_new_password_adm
                                                            : errorValidate.confirm_new_password_adm
                                                            ? errorValidate.confirm_new_password_adm
                                                            : ''
                                                    }
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowConfirmNewPasswordAdm}
                                                                    onMouseDown={handleMouseDownConfirmNewPasswordAdm}
                                                                    edge="end"
                                                                    size="large"
                                                                >
                                                                    {showConfirmNewPasswordAdm ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid container alignItems="end" justifyContent="end" sx={{ mt: 3 }}>
                                        <Grid item>
                                            <Box sx={{ mt: 2, mr: 3 }}>
                                                <ThemeProvider theme={themeButton}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="button"
                                                            variant="contained"
                                                            color="neutral"
                                                            onClick={() => navigate(-1)}
                                                        >
                                                            Voltar
                                                        </Button>
                                                    </AnimateButton>
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                        {params.action === 'view' ? (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            // disabled={isSubmitting}
                                                            component={Link}
                                                            to={`/usuario/${params.id}/edit`}
                                                            fullWidth
                                                            size="large"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Editar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        ) : (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Salvar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                </form>
                            </div>
                        </MainCard>
                    </>
                )}
            </Formik>
            <SnackBar
                openSnackBar={openSnackBar}
                message={snackBarMessage}
                type={snackBarType}
                setOpenSnackBar={setOpenSnackBar}
                onClick={handlerClick}
            />
        </>
    );
};

export default FormRegister;
