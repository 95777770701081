import { GraphicContainer, GraphicTypeAccessContainer } from './styles';
import { Grid } from '@mui/material';
import BIUserEngagement from '../userEngagement';
import TypeAcess from '../typeAccess/index';
import AccessMonth from '../AccessMonth';
import UserRanking from '../UserRanking';
import RealTimeUsers from '../realTimeUsers';

const BIgeneralData = () => {
    return (
        <Grid container padding={0.5} spacing={2}>
            <Grid item xs={3.5}>
                <GraphicTypeAccessContainer>
                    <TypeAcess />
                </GraphicTypeAccessContainer>
            </Grid>
            <Grid item xs={3.5}>
                <GraphicTypeAccessContainer>
                    <AccessMonth />
                </GraphicTypeAccessContainer>
            </Grid>
            <Grid item xs={5}>
                <GraphicContainer className={'implemented'}>
                    <UserRanking />
                </GraphicContainer>
            </Grid>
            <Grid item xs={12}>
                <RealTimeUsers />
            </Grid>
            <Grid item xs={12}>
                <BIUserEngagement />
            </Grid>
        </Grid>
    );
};

export default BIgeneralData;
