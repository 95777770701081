import { app } from './Api';

const postLevel = async (data) => {
    return app.post('level', data);
};
const getLevels = async (page, qtdPerPage, name) => {
    return app.get(`level?page=${page}&qtdPerPage=${qtdPerPage}&name=${name}`);
};
const getLevelPerId = async (id) => {
    return app.get(`level/${id}`);
};
const updateLevel = async (id, data) => {
    return app.put(`level/${id}`, data);
};
const deleteLevel = async (id) => {
    return app.delete(`level/${id}`);
};
const getLastLevel = async () => {
    return app.get(`last_level`);
};
export { postLevel, getLevels, getLevelPerId, updateLevel, deleteLevel, getLastLevel };
