import { app } from './Api';

const getSpeaker = async (page, qtdPerPage) => {
    return app.get(`speaker?page=${page || 0}&qtdPerPage=${qtdPerPage || 10}`);
};
const searchSpeaker = async (name) => {
    return app.get(`speaker?name=${name || ''}`);
};
const postSpeaker = async (data) => {
    return app.post('speaker', data);
};
const updateSpeaker = async (id, data) => {
    return app.post(`speaker/${id}`, data);
};
const getSpeakerPerId = async (id) => {
    return app.get(`speaker/${id}`);
};
const deleteSpeaker = async (id) => {
    return app.delete(`speaker/${id}`);
};
export { getSpeaker, postSpeaker, updateSpeaker, getSpeakerPerId, deleteSpeaker, searchSpeaker };
