import home from './home';
import options from './options';

// ==============================|| MENU ITEMS ||============================== //
function menuItens(idRole) {
    let menuItems = {};
    menuItems = {
        items: [home, options]
    };
    return menuItems;
}

export default menuItens;
