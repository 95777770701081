import React from 'react';
import { Chart } from 'react-google-charts';
import PropTypes from 'prop-types';

const Bar = (props) => {
    const options = {
        title: props.title,
        chartArea: { width: '100%', height: '100%' },
        legend: { position: 'top' },
        explorer: { axis: 'horizontal', keepInBounds: false },
        colors: ['#ED9F4A', '#73A4F7', '#51A744'],
        tooltip: { ignoreBounds: true },
        ...props.options
    };
    return props.allData.length > 1 ? (
        <Chart chartType="Bar" width={props.width} height={props.height || '400px'} data={props.allData} options={options} />
    ) : (
        <h1>Sem resultado na busca...</h1>
    );
};

Bar.propTypes = {
    title: PropTypes.string,
    width: PropTypes.string,
    allData: PropTypes.array.isRequired,
    options: PropTypes.object
};
export default Bar;
