import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
import AuthLogin3 from 'views/pages/authentication/login/Login';
import AuthRegister3 from 'views/pages/authentication/login/Register';
import RememberPassword from 'views/pages/authentication/forgotPassword/ForgotPassword';
import Emailforgotpassword from 'views/pages/authentication/forgotPassword/EmailForgotPassword';

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin3 />
        },
        {
            path: '/',
            element: <AuthLogin3 />
        },
        {
            path: '/pages/register/register3',
            element: <AuthRegister3 />
        },
        {
            path: '/emailforgotpassword',
            element: <Emailforgotpassword />
        },
        {
            path: '/remember_password',
            element: <RememberPassword />
        }
    ]
};

export default AuthenticationRoutes;
