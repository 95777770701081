import { app } from './Api';

const getPublication = async (page, qtdPerPage, idType, descriptionPost) => {
    return app.get(`publication?page=${page}&qtdPerPage=${qtdPerPage}&idType=${idType}&descriptionPost=${descriptionPost || ''}`);
};
const searchPublication = async (idType, descriptionPost) => {
    return app.get(`publication?idType=${idType}&descriptionPost=${descriptionPost || ''}`);
};
const deletePublication = async (id) => {
    return app.delete(`publication/${id}`);
};
const getResoucesFilters = async () => {
    return app.get(`get_resource_publication`);
};
const postPublication = async (data) => {
    return app.post('publication', data);
};
const updatePublication = async (id, data) => {
    return app.post(`publication/${id}`, data);
};
const getPublicationPerId = async (id) => {
    return app.get(`publication/${id}`);
};
const getSkill = async () => {
    return app.get(`skill`);
};
const getCompetence = async () => {
    return app.get(`competence`);
};
const getResourcePublication = async () => {
    return app.get(`get_resource_publication`);
};
const getAdvertisementPublication = async (idType) => {
    return app.get(`get_advertisement_publication?idType=${idType}`);
};
const backPublicationToTop = async (id) => {
    return app.post(`back_publication_top/${id}`);
};
const changeStatusPublication = async (id) => {
    return app.put(`change_status_publication/${id}`);
};
export {
    getPublication,
    deletePublication,
    getResoucesFilters,
    postPublication,
    updatePublication,
    getPublicationPerId,
    getSkill,
    getCompetence,
    getResourcePublication,
    getAdvertisementPublication,
    backPublicationToTop,
    searchPublication,
    changeStatusPublication
};
