import { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    Grid,
    TextField,
    OutlinedInput,
    useMediaQuery,
    MenuItem,
    Select,
    InputLabel,
    Alert,
    Snackbar
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import Loading from 'components/Loading/Loading';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import { getResourcerLibrary, postLibrary, getLibraryPerId, updateLibrary, getAllEvents } from 'services/library';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FolderIcon from '@mui/icons-material/Folder';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const Library = ({ ...others }) => {
    const params = useParams();
    const themeButton = createTheme({
        status: {
            danger: '#e53e3e'
        },
        palette: {
            primary: {
                main: '#0971f1',
                darker: '#053e85'
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff'
            }
        }
    });
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
    };
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 58;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };
    const Demo = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.background.paper
    }));
    const initialStateOptions = {
        skills: [],
        competences: [],
        idEvent: []
    };
    const initialValuesEdit = {
        title: '',
        time: '',
        point: '',
        idEvent: '',
        description: EditorState.createEmpty(),
        competence: [],
        skill: [],
        learningObject: ''
    };
    const theme = useTheme();
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [options, setOptions] = useState(initialStateOptions);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [showAddObject, setShowAddObject] = useState(false);
    const [errorUrl, setErrorUrl] = useState([]);
    const [typeObject, setTypeObject] = useState('');
    const [dense, setDense] = useState(false);
    const [errorValidate, setErrorValidate] = useState({});
    const [disableButton, setDisableButton] = useState(false);
    const [valuesEdit, setValuesEdit] = useState(initialValuesEdit);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [inputFields, setInputFields] = useState([
        {
            url: [],
            file: [],
            type: []
        }
    ]);
    const titlePage =
        params.action === 'view'
            ? 'Visualização de Biblioteca'
            : params.action === 'edit'
            ? 'Edição de Biblioteca'
            : 'Cadastro de Biblioteca';
    const isDisabled = params.action === 'view' ? true : false;

    useEffect(() => {
        getResource();
    }, []);

    useEffect(() => {
        viewPerId();
    }, [params.id]);

    function viewPerId() {
        params.action &&
            getLibraryPerId(params.id).then((resp) => {
                setValuesEdit({
                    ['title']: resp.data.title,
                    ['time']: resp.data.time,
                    ['idEvent']: resp.data.id_event,
                    ['point']: resp.data.point,
                    ['description']: EditorState.createWithContent(
                        ContentState.createFromBlockArray(convertFromHTML(`${resp.data.description}`))
                    ),
                    ['competence']: resp.data.competence.map((res) => res.desription),
                    ['skill']: resp.data.skill.map((res) => res.name),
                    ['learningObject']: resp.data.learningObject
                });
            });
    }

    function getResource() {
        getResourcerLibrary().then((resp) =>
            getAllEvents().then((res) =>
                setOptions({
                    skills: resp.data.skills,
                    competences: resp.data.competences,
                    idEvent: res.data
                })
            )
        );
    }

    function AddObject() {
        setShowAddObject(true);
    }

    function optionObject(event) {
        setTypeObject(event.target.value);
        const list = [...inputFields];
        const value = null;
        const name = 'url';
        list[0][name] = value;
        setInputFields(list);
    }

    function handleChangeInput(index, evnt, typeObject) {
        const { name, value } = evnt.target;
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
    }

    function handleChangeFile(index, evnt) {
        const value = evnt;
        const name = 'file';
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
        handleUpload(value);
    }

    function handleChangeType(index, evnt, typeObject) {
        const value = evnt.target.value;
        const name = 'type';
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
        typeObject == 1 && handleChangeInput(index, evnt, typeObject);
    }

    function handleUpload(files) {
        const file = [files];
        const uploadedFile = file.map((file) => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),

            progress: 0,
            uploaded: false,
            error: false,
            url: null
        }));
        setUploadedFiles(uploadedFiles.concat(uploadedFile));
    }

    function getIdsCompetence(valueCompetence) {
        let ids = [];
        valueCompetence.forEach((element) => {
            let valueId = options.competences.filter((desc) => desc.description === element)[0];
            ids = [...ids, valueId];
        });
        return ids;
    }

    function getIdsSkills(valueSkills) {
        let ids = [];
        valueSkills.forEach((element) => {
            let valueId = options.skills.filter((desc) => desc.name === element)[0];
            ids = [...ids, valueId];
        });
        return ids;
    }

    return (
        <>
            <Formik
                initialValues={{
                    title: valuesEdit.title,
                    time: valuesEdit.time,
                    idEvent: valuesEdit.idEvent,
                    point: valuesEdit.point,
                    description: valuesEdit.description,
                    skill: valuesEdit.skill || [],
                    competence: valuesEdit.competence || [],
                    learningObject: valuesEdit.learningObject,
                    submit: null
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    skill: Yup.array().required('Habilidade é obrigatório').min(1, 'Necessário escolher habilidade'),
                    competence: Yup.array().required('Competência é obrigatório').min(1, 'Necessário escolher competência'),
                    point: Yup.number().required('Pontuação é obrigatório'),
                    title: Yup.string().max(255).trim().required('Título obrigatório')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                        if (inputFields[0].type == '1') {
                            switch (typeObject) {
                                case '1':
                                    if (inputFields[0].url !== null) {
                                        if (
                                            inputFields[0].url.includes('https://www.youtube.com') ||
                                            inputFields[0].url.includes('https://vimeo.com/')
                                        ) {
                                            const errors = [...errorUrl];
                                            errors[0] = null;
                                            setErrorUrl(errors);
                                            setDisableButton(false);
                                        } else {
                                            throw 'Insira somente URL do Youtube ou Vimeo';
                                        }
                                    }
                                    break;
                                case '7':
                                    if (inputFields[0].url !== null) {
                                        if (inputFields[0].url.includes('.pdf')) {
                                            const errors = [...errorUrl];
                                            errors[0] = null;
                                            setErrorUrl(errors);
                                            setDisableButton(false);
                                        } else {
                                            throw 'Insira uma URL válida. Exemplo: agronomia.com.br/microbiologia.pdf (Deve conter .pdf)';
                                        }
                                    }
                                    break;
                                case '8':
                                    if (inputFields[0].url !== null) {
                                        if (inputFields[0].url.includes('.xlsx')) {
                                            const errors = [...errorUrl];
                                            errors[0] = null;
                                            setErrorUrl(errors);
                                            setDisableButton(false);
                                        } else {
                                            throw 'Insira uma URL válida. Exemplo: agronomia.com.br/microbiologia.xlsx (Deve conter .xlsx)';
                                        }
                                    }
                                    break;
                            }
                        } else {
                            switch (typeObject) {
                                case '3':
                                    if (inputFields[0].file.name !== undefined) {
                                        if (
                                            inputFields[0].file.name.includes('.png') ||
                                            inputFields[0].file.name.includes('.jpeg') ||
                                            inputFields[0].file.name.includes('.jpg')
                                        ) {
                                            const errors = [...errorUrl];
                                            errors[0] = null;
                                            setErrorUrl(errors);
                                            setDisableButton(false);
                                        } else {
                                            throw 'Somente é aceito arquivos do tipo imagem (.jpeg, .jpg, .png)';
                                        }
                                    }
                                    break;
                                case '7':
                                    if (inputFields[0].file.name !== undefined) {
                                        if (inputFields[0].file.name.includes('.pdf')) {
                                            const errors = [...errorUrl];
                                            errors[0] = null;
                                            setErrorUrl(errors);
                                            setDisableButton(false);
                                        } else {
                                            throw 'Somente é aceito arquivos do tipo PDF (.pdf)';
                                        }
                                    }
                                    break;
                                case '8':
                                    if (inputFields[0].file.name !== undefined) {
                                        if (inputFields[0].file.name.includes('.xlsx')) {
                                            const errors = [...errorUrl];
                                            errors[0] = null;
                                            setErrorUrl(errors);
                                            setDisableButton(false);
                                        } else {
                                            throw 'Somente é aceito arquivos do tipo excel (.xlsx)';
                                        }
                                    }
                                    break;
                                case '9':
                                    if (inputFields[0].file.name !== undefined) {
                                        if (inputFields[0].file.name.includes('.docx')) {
                                            const errors = [...errorUrl];
                                            errors[0] = null;
                                            setErrorUrl(errors);
                                            setDisableButton(false);
                                        } else {
                                            throw 'Somente é aceito arquivos do tipo word (.docx)';
                                        }
                                        break;
                                    }
                            }
                        }
                        const skillsIds = getIdsSkills(values.skill).map((item) => item.id);
                        const competencesIds = getIdsCompetence(values.competence).map((item) => item.id);

                        const data = new FormData();
                        let uploadedFile = uploadedFiles.filter((desc) => !desc.url);
                        let resultado = '';
                        let attachment = false;
                        for (let i = 0; i < uploadedFile.length; i++) {
                            if (uploadedFile[i].file) {
                                data.append(`file${i}`, uploadedFile[i].file);
                                resultado = i;
                                attachment = true;
                            }
                        }
                        if (valuesEdit.learningObject.length == 0) {
                            if (
                                (attachment == false && inputFields[0].type.length == 0) ||
                                (inputFields[0].url == null && attachment == false)
                            ) {
                                throw 'É necessário inserir algum objeto de aprendizado';
                            }
                        }
                        setLoading(true);
                        data.append('title', values.title);
                        data.append('time', values.time);
                        data.append('idEvent', values.idEvent !== null ? values.idEvent : '');
                        data.append('point', values.point);
                        data.append('qtd', resultado);
                        data.append('description', `${draftToHtml(convertToRaw(values.description.getCurrentContent()))}`);
                        data.append('isAttachment', attachment);
                        data.append('learningObject', JSON.stringify(inputFields));
                        data.append(`competence`, JSON.stringify(competencesIds));
                        data.append(`skill`, JSON.stringify(skillsIds));
                        data.append('id_type', typeObject);
                        if (params.action === 'edit') {
                            data.append('_method', 'put');
                            updateLibrary(params.id, data, {
                                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                            })
                                .then((resp) => {
                                    setError('');
                                    viewPerId();
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        setLoading(false);
                                        navigate('/biblioteca');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    setError(e.response.data.error);
                                });
                        } else {
                            postLibrary(data, {
                                headers: { 'content-type': 'application/json', 'Access-Control-Allow-Origin': '*' }
                            })
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        navigate('/biblioteca');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    console.log(e);
                                    setLoading(false);
                                    setSuccess('');
                                    if (e.response?.data?.errorValidate?.file[0]) {
                                        setErrorValidate(e.response.data.errorValidate.file[0]);
                                    }
                                    setError(e.response.data.error);
                                });
                        }
                    } catch (err) {
                        console.error(err);
                        setError(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <>
                        {loading && (
                            <Grid container alignItems="center" justifyContent="center">
                                <MainCard
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Loading color="#015641" type="spinningBubbles" />
                                </MainCard>
                            </Grid>
                        )}
                        <MainCard spacing={gridSpacing} style={{ padding: 15, margin: 25 }}>
                            {error || alert ? (
                                <Snackbar open={true} autoHideDuration={6000}>
                                    <Alert
                                        severity={error ? 'error' : success ? 'success' : ''}
                                        sx={{
                                            width: '100%',
                                            backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                            color: '#FFF'
                                        }}
                                    >
                                        {error ? error : success ? success : ''}
                                    </Alert>
                                </Snackbar>
                            ) : (
                                ''
                            )}
                            <div style={{ display: loading ? 'none' : 'block' }}>
                                <form noValidate onSubmit={handleSubmit} {...others}>
                                    <h3>{titlePage}</h3>
                                    <hr></hr>
                                    <Grid container spacing={matchDownSM ? 2 : 2}>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.title && errors.title) || Boolean(errorValidate.title)}
                                                id="outlined-title"
                                                type="text"
                                                label="Título"
                                                value={values.title}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="title"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.title && errors.title
                                                        ? errors.title
                                                        : errorValidate.title
                                                        ? errorValidate.title
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 2 : 2}>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                //error={Boolean(touched.title && errors.title) || Boolean(errorValidate.title)}
                                                id="outlined-time"
                                                type="text"
                                                label="Tempo estimado"
                                                value={values.time}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="time"
                                                disabled={isDisabled}
                                                // helperText={
                                                //     touched.title && errors.title
                                                //         ? errors.title
                                                //         : errorValidate.title
                                                //         ? errorValidate.title
                                                //         : ''
                                                // }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.point && errors.point) || Boolean(errorValidate.point)}
                                                id="point"
                                                type="number"
                                                label="Pontuação"
                                                value={values.point}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="point"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.point && errors.point
                                                        ? errors.point
                                                        : errorValidate.point
                                                        ? errorValidate.point
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 2 : 2}>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                            <FormControl
                                                sx={{ width: '100%' }}
                                                error={Boolean(touched.skill && errors.skill) || Boolean(errorValidate.skill)}
                                            >
                                                <InputLabel id="skills">Habilidades</InputLabel>
                                                <Select
                                                    labelId="skill"
                                                    onBlur={handleBlur}
                                                    multiple
                                                    name="skill"
                                                    id="skill"
                                                    value={values.skill}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            'skill',
                                                            typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                                                        )
                                                    }
                                                    input={<OutlinedInput label="Habilidades" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                    disabled={isDisabled}
                                                >
                                                    {options.skills.map((option) => (
                                                        <MenuItem key={option.id} value={option.name}>
                                                            <Checkbox checked={values.skill.indexOf(option.name) > -1} />
                                                            <ListItemText primary={option.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errorValidate.skill && (
                                                    <FormHelperText error id="standard-weight-helper-text--skill">
                                                        {errorValidate.skill}
                                                    </FormHelperText>
                                                )}
                                                {touched.skill && errors.skill && (
                                                    <FormHelperText error id="standard-weight-helper-text--skill">
                                                        {errors.skill}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                            <FormControl
                                                sx={{ width: '100%' }}
                                                error={
                                                    Boolean(touched.competence && errors.competence) || Boolean(errorValidate.competence)
                                                }
                                            >
                                                <InputLabel id="competence">Competências</InputLabel>
                                                <Select
                                                    labelId="competence"
                                                    onBlur={handleBlur}
                                                    multiple
                                                    name="competence"
                                                    id="competence"
                                                    value={values.competence}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            'competence',
                                                            typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                                                        )
                                                    }
                                                    input={<OutlinedInput label="Competências" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                    disabled={isDisabled}
                                                >
                                                    {options.competences.map((option) => (
                                                        <MenuItem key={option.id} value={option.description}>
                                                            <Checkbox checked={values.competence.indexOf(option.description) > -1} />
                                                            <ListItemText primary={option.description} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errorValidate.competence && (
                                                    <FormHelperText error id="standard-weight-helper-text--competence">
                                                        {errorValidate.competence}
                                                    </FormHelperText>
                                                )}
                                                {touched.competence && errors.competence && (
                                                    <FormHelperText error id="standard-weight-helper-text--competence">
                                                        {errors.competence}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                select
                                                label="Evento"
                                                id="idEvent"
                                                type="text"
                                                value={values.idEvent || ''}
                                                name="idEvent"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={isDisabled}
                                            >
                                                {options.idEvent &&
                                                    options.idEvent.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.title}
                                                        </MenuItem>
                                                    ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            {values.description && (
                                                <Editor
                                                    editorStyle={{
                                                        height: '200px',
                                                        width: '100%',
                                                        border: '1px solid #DCDCDC',
                                                        borderRadius: '25px',
                                                        padding: '20px'
                                                    }}
                                                    toolbar={{
                                                        options: ['inline', 'list', 'textAlign', 'fontFamily', 'fontSize', 'link'],
                                                        inline: {
                                                            options: ['bold', 'italic', 'underline']
                                                        },
                                                        list: {
                                                            options: ['unordered', 'ordered', 'indent', 'outdent']
                                                        },
                                                        textAlign: {
                                                            options: ['left', 'center', 'right']
                                                        },
                                                        fontFamily: {
                                                            options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana']
                                                        }
                                                    }}
                                                    editorState={values.description}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={(e) => setFieldValue('description', e)}
                                                    disabled={isDisabled}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={8} sx={{ marginTop: 3 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <h3>Objetos de aprendizado</h3>
                                                    <Demo>
                                                        {valuesEdit.learningObject.length > 0 && (
                                                            <List
                                                                dense={dense}
                                                                key={valuesEdit.learningObject[valuesEdit.learningObject.length - 1].id}
                                                            >
                                                                <ListItem>
                                                                    {valuesEdit.learningObject[valuesEdit.learningObject.length - 1].size >
                                                                    0 ? (
                                                                        <>
                                                                            <ListItemAvatar>
                                                                                <FolderIcon />
                                                                            </ListItemAvatar>
                                                                            <ListItemText primary="Objeto de aprendizado do tipo arquivo" />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <ListItemAvatar>
                                                                                <Avatar>
                                                                                    <FolderIcon />
                                                                                </Avatar>
                                                                            </ListItemAvatar>
                                                                            <ListItemText
                                                                                primary={
                                                                                    valuesEdit.learningObject[
                                                                                        valuesEdit.learningObject.length - 1
                                                                                    ].url
                                                                                }
                                                                                secondary={
                                                                                    valuesEdit.learningObject[
                                                                                        valuesEdit.learningObject.length - 1
                                                                                    ].typeLearningObject
                                                                                }
                                                                            />
                                                                        </>
                                                                    )}
                                                                </ListItem>
                                                            </List>
                                                        )}
                                                    </Demo>
                                                </Grid>
                                            </Grid>
                                            {params.action === 'edit' && (
                                                <Button variant="text" onClick={AddObject}>
                                                    Editar objeto de aprendizado
                                                    <AddCircleOutlineRoundedIcon />
                                                </Button>
                                            )}
                                            {params.action !== 'edit' && params.action !== 'view' && (
                                                <Button variant="text" onClick={AddObject}>
                                                    Adicionar objeto de aprendizado
                                                    <AddCircleOutlineRoundedIcon />
                                                </Button>
                                            )}
                                            {showAddObject && (
                                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="objects">
                                                    <FormControlLabel
                                                        value={3}
                                                        control={<Radio />}
                                                        label="Imagem"
                                                        onChange={optionObject}
                                                    />
                                                    <FormControlLabel value={1} control={<Radio />} label="Vídeo" onChange={optionObject} />
                                                    <FormControlLabel value={7} control={<Radio />} label="PDF" onChange={optionObject} />
                                                    <FormControlLabel value={8} control={<Radio />} label="Excel" onChange={optionObject} />
                                                    <FormControlLabel value={9} control={<Radio />} label="Word" onChange={optionObject} />
                                                </RadioGroup>
                                            )}
                                        </Grid>
                                        {typeObject == 3 && (
                                            <>
                                                <Grid container>
                                                    {inputFields.map((data, index) => {
                                                        const { url } = data;
                                                        return (
                                                            <Grid container spacing={matchDownSM ? 0 : 0} sx={{ marginLeft: 2 }}>
                                                                <Grid item xs={12} sm={2} sx={{ marginTop: 1 }} key={index}>
                                                                    <RadioGroup
                                                                        row
                                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                                        name="objects"
                                                                    >
                                                                        <FormControlLabel
                                                                            value="1"
                                                                            control={<Radio />}
                                                                            label="URL"
                                                                            onChange={(evnt) => handleChangeType(index, evnt)}
                                                                        />
                                                                        <FormControlLabel
                                                                            value="2"
                                                                            control={<Radio />}
                                                                            label="Arquivo"
                                                                            onChange={(evnt) => handleChangeType(index, evnt)}
                                                                        />
                                                                    </RadioGroup>
                                                                </Grid>
                                                                {parseInt(inputFields[index].type) == 1 && (
                                                                    <>
                                                                        <Grid item xs={12} sm={4} sx={{ marginTop: 1 }} key={index}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="urlImage"
                                                                                type="text"
                                                                                label="URL"
                                                                                onBlur={handleBlur}
                                                                                name="url"
                                                                                disabled={isDisabled}
                                                                                onChange={(evnt) =>
                                                                                    handleChangeInput(index, evnt, typeObject)
                                                                                }
                                                                                value={url}
                                                                            />
                                                                        </Grid>
                                                                    </>
                                                                )}{' '}
                                                                {parseInt(inputFields[index].type) == 2 && (
                                                                    <>
                                                                        <Grid item xs={12} sm={4} sx={{ marginTop: 2 }} key={index}>
                                                                            <input
                                                                                type="file"
                                                                                name="file"
                                                                                id="file"
                                                                                label="Foto"
                                                                                disabled={isDisabled}
                                                                                onChange={(evnt) =>
                                                                                    handleChangeFile(index, evnt.target.files[0])
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </>
                                                                )}
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </>
                                        )}
                                        {typeObject == 1 && (
                                            <>
                                                <Grid container>
                                                    {inputFields.map((data, index) => {
                                                        const { url } = data;
                                                        return (
                                                            <Grid container spacing={matchDownSM ? 0 : 0} sx={{ marginLeft: 2 }}>
                                                                <Grid item xs={12} sm={4} sx={{ marginTop: 1 }} key={index}>
                                                                    <TextField
                                                                        fullWidth
                                                                        id="urlVideo"
                                                                        type="text"
                                                                        label="URL"
                                                                        onBlur={handleBlur}
                                                                        name="url"
                                                                        disabled={isDisabled}
                                                                        onChange={(evnt) => handleChangeType(index, evnt, typeObject)}
                                                                        value={url}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </>
                                        )}
                                        {typeObject == 7 && (
                                            <>
                                                <Grid container>
                                                    {inputFields.map((data, index) => {
                                                        const { url } = data;
                                                        return (
                                                            <Grid container spacing={matchDownSM ? 0 : 0} sx={{ marginLeft: 2 }}>
                                                                <Grid item xs={12} sm={2} sx={{ marginTop: 1 }} key={index}>
                                                                    <RadioGroup
                                                                        row
                                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                                        name="objects"
                                                                    >
                                                                        <FormControlLabel
                                                                            value="1"
                                                                            control={<Radio />}
                                                                            label="URL"
                                                                            onChange={(evnt) => handleChangeType(index, evnt)}
                                                                        />
                                                                        <FormControlLabel
                                                                            value="2"
                                                                            control={<Radio />}
                                                                            label="Arquivo"
                                                                            onChange={(evnt) => handleChangeType(index, evnt)}
                                                                        />
                                                                    </RadioGroup>
                                                                </Grid>
                                                                {parseInt(inputFields[index].type) == 1 && (
                                                                    <>
                                                                        <Grid item xs={12} sm={4} sx={{ marginTop: 1 }} key={index}>
                                                                            <TextField
                                                                                fullWidth
                                                                                id="urlPdf"
                                                                                type="text"
                                                                                label="URL"
                                                                                onBlur={handleBlur}
                                                                                name="url"
                                                                                disabled={isDisabled}
                                                                                onChange={(evnt) =>
                                                                                    handleChangeInput(index, evnt, typeObject)
                                                                                }
                                                                                value={url}
                                                                            />
                                                                        </Grid>
                                                                    </>
                                                                )}{' '}
                                                                {parseInt(inputFields[index].type) == 2 && (
                                                                    <>
                                                                        <Grid item xs={12} sm={4} sx={{ marginTop: 2 }} key={index}>
                                                                            <input
                                                                                type="file"
                                                                                name="file"
                                                                                id="file"
                                                                                label="Foto"
                                                                                disabled={isDisabled}
                                                                                onChange={(evnt) =>
                                                                                    handleChangeFile(index, evnt.target.files[0])
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </>
                                                                )}
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </>
                                        )}
                                        {typeObject == 8 && (
                                            <>
                                                <Grid container>
                                                    {inputFields.map((data, index) => {
                                                        const { url } = data;
                                                        return (
                                                            <Grid container spacing={matchDownSM ? 0 : 0} sx={{ marginLeft: 2 }}>
                                                                <Grid item xs={12} sx={{ marginTop: 2 }} key={index}>
                                                                    <input
                                                                        type="file"
                                                                        name="file"
                                                                        id="file"
                                                                        label="Foto"
                                                                        disabled={isDisabled}
                                                                        onChange={(evnt) => handleChangeFile(index, evnt.target.files[0])}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </>
                                        )}
                                        {typeObject == 9 && (
                                            <>
                                                <Grid container>
                                                    {inputFields.map((data, index) => {
                                                        const { url } = data;
                                                        return (
                                                            <Grid container spacing={matchDownSM ? 0 : 0} sx={{ marginLeft: 2 }}>
                                                                <Grid item xs={12} sx={{ marginTop: 2 }} key={index}>
                                                                    <input
                                                                        type="file"
                                                                        name="file"
                                                                        id="file"
                                                                        label="Foto"
                                                                        disabled={isDisabled}
                                                                        onChange={(evnt) => handleChangeFile(index, evnt.target.files[0])}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                    <Grid container alignItems="end" justifyContent="end" sx={{ mt: 3 }}>
                                        <Grid item>
                                            <Box sx={{ mt: 2, mr: 3 }}>
                                                <ThemeProvider theme={themeButton}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="button"
                                                            variant="contained"
                                                            color="neutral"
                                                            onClick={() => navigate(-1)}
                                                        >
                                                            Voltar
                                                        </Button>
                                                    </AnimateButton>
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                        {params.action === 'view' ? (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            // disabled={isSubmitting}
                                                            component={Link}
                                                            to={`/arquivo/${params.id}/edit`}
                                                            fullWidth
                                                            size="large"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Editar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        ) : (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting || disableButton}
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Salvar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                </form>
                            </div>
                        </MainCard>
                    </>
                )}
            </Formik>
        </>
    );
};

export default Library;
