export const initialState = {
    page: 0,
    rowsPerPage: 10,
    dateClass: '',
    valueDateClass: '',
    nameCourse: ''
};

const classeReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_PAGE_CLASSE':
            return {
                ...state,
                page: actions.payload
            };
        case 'SET_ROWS_PER_PAGE_CLASSE':
            return {
                ...state,
                rowsPerPage: actions.payload
            };
        case 'SET_DATECLASS_CLASS':
            return {
                ...state,
                dateClass: actions.payload
            };
        case 'SET_VALUEDATECLASS_CLASS':
            return {
                ...state,
                valueDateClass: actions.payload
            };
        case 'SET_NAMECOURSE_CLASS':
            return {
                ...state,
                nameCourse: actions.payload
            };
        case 'SET_CLEAR_CLASS':
            return {
                ...state,
                dateClass: '',
                valueDateClass: ''
            };
        default:
            return state;
    }
};

export default classeReducer;
