import PropTypes from 'prop-types';
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router';

// const HomeComponent = Loadable(lazy(() => import('views/pages/HomeComponent/home')));

// ==============================|| MAIN ROUTING ||============================== //
const AuthRouter = (props) => {
    let token = localStorage.getItem('token');
    return token ? props.children : <Navigate to="/login" />;
};

AuthRouter.propTypes = {
    children: PropTypes.object
};

const ClientRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        // :
        // // {
        //     path: '/fechamento_fazenda',
        //     element: (
        //         <AuthRouter>
        //             <CloseFarm />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: '/painel/:id',
        //     element: (
        //         <AuthRouter>
        //             <PanelBi />
        //         </AuthRouter>
        //     )
        // },
        // {
        //     path: '/perfil',
        //     element: (
        //         <AuthRouter>
        //             <ManagerProfile />
        //         </AuthRouter>
        //     )
        // }
    ]
};

export default ClientRoutes;
