import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import config from 'config';
import SystemRoutes from './SystemRoutes';
import { useSelector } from 'react-redux';
import ClientRoutes from './ClientRoutes';
import AdminRoutes from './AdminRoutes';
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const role = useSelector((state) => state.auth.user.id_role);
    const route = role == 3 ? [ClientRoutes, AuthenticationRoutes] : [AuthenticationRoutes, MainRoutes, SystemRoutes, AdminRoutes];
    return useRoutes(route, config.basename);
}
