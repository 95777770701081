import { URLBI } from 'baseURL/baseURL';
import { app } from './ApiBi';

const getCourses = async () => {
    return await fetch(`${URLBI}course`)
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            console.error(error);
        });
};

const getEvents = async () => {
    return await fetch(`${URLBI}event`)
        .then((data) => {
            return data.json();
        })
        .catch((error) => {
            console.error(error);
        });
};

const getLibraryBI = async () => {
    return await fetch(`${URLBI}library`)
        .then((data) => data.json())
        .catch((error) => console.log(error));
};

const getFavoriteBI = async () => {
    return await fetch(`${URLBI}favorites`)
        .then((data) => data.json())
        .catch((error) => console.log(error));
};

const getRankingBI = async (fullRanking) => {
    return app
        .get(`ranking?full=${fullRanking || false}`)
        .then((resp) => resp.data)
        .catch((error) => console.log(error));
};

const getBiAccess = async () => {
    return app.get(`access`);
};

const getBiEngagement = async () => {
    return await fetch(`${URLBI}engagement`)
        .then((data) => data.json())
        .catch((error) => console.log(error));
};
const getRealTimeUsersBI = async () => {
    return app
        .get('real_time_users')
        .then((resp) => resp.data)
        .catch((error) => console.log(error));
};

const getBiAccessMonth = async () => {
    return app.get(`access_month`);
};

export {
    getCourses,
    getEvents,
    getLibraryBI,
    getBiAccess,
    getBiAccessMonth,
    getBiEngagement,
    getRankingBI,
    getFavoriteBI,
    getRealTimeUsersBI
};
