import { GridMain, GridFilter, GridMainContainer } from './styles';

import GraphicBar from '../graphicBar/index';
import { GraphicArea } from '../graphicArea';
import FilterDates from '../filterDates';
import { getEvents } from 'services/bi';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterList from '../filterList';

const BIEvents = () => {
    const [allData, setAllData] = useState([]);
    const [eventFiltered, setEventFiltered] = useState(['']);
    const [likesFiltered, setLikesFiltered] = useState(['']);
    const [filter, setFilter] = useState([]);
    const dispatch = useDispatch();
    const filtered = useSelector((state) => state.bi.filtered);
    const initialDate = useSelector((state) => state.bi.initialDate);
    const competencesSelect = useSelector((state) => state.bi.competenceFilter);
    const eventsSelect = useSelector((state) => state.bi.eventFilter);
    const speakersSelect = useSelector((state) => state.bi.speakerFilter);
    const typeEventSelect = useSelector((state) => state.bi.typeEventFilter);
    const competencesFilter = [];
    const speakerFilter = [];
    const typeFilter = [];

    const getAllEvents = async () => {
        await getEvents()
            .then((response) => {
                setAllData(response.content);
                setFilter(response.filter);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    async function filterEvent(allEvents) {
        var participationInEvents = [['Nome', 'Inscritos', 'Participantes ao vivo', 'Acessos pela biblioteca']];
        var likesInEvents = [['Nome', 'Likes']];
        var competenceValid = null;
        var eventValid = null;
        var speakerValid = null;
        var typeEventSelectValid = null;
        allEvents?.map((value) => {
            if (eventsSelect != null) {
                if (eventsSelect.includes(value.id)) {
                    eventValid = true;
                } else {
                    eventValid = false;
                }
            }

            if (eventValid || eventValid == null) {
                if (speakersSelect != null) {
                    value.speakers.filter(function (item) {
                        if (speakersSelect.includes(item.id_speaker)) {
                            speakerValid = true;
                        } else {
                            speakerValid = false;
                        }
                    });
                }
                if (competencesSelect != null) {
                    value.competences.filter(function (item) {
                        if (competencesSelect.includes(item.id_competence)) {
                            competenceValid = true;
                        } else {
                            competenceValid = false;
                        }
                    });
                }

                if (typeEventSelect != null) {
                    if (typeEventSelect.includes(value.id_type_event)) {
                        typeEventSelectValid = true;
                    } else {
                        typeEventSelectValid = false;
                    }
                }

                if (speakerValid || speakerValid == null) {
                    if (typeEventSelectValid || typeEventSelectValid == null) {
                        if (competenceValid || competencesSelect == null) {
                            let eventLive = value.metrics.eventLive;
                            let registered = value.metrics.registered;
                            let eventLibrary = value.metrics.eventLibrary;
                            let likes = value.metrics.like;

                            if (Array.isArray(eventLive)) {
                                eventLive.map((live) => {
                                    if (live.date == initialDate) {
                                        value.metrics.eventLive = live;
                                    }
                                });
                            }
                            if (Array.isArray(registered)) {
                                registered.map((regis) => {
                                    if (regis.date == initialDate) {
                                        value.metrics.registered = regis;
                                    }
                                });
                            }
                            if (Array.isArray(eventLibrary)) {
                                eventLibrary.map((inLibrary) => {
                                    if (inLibrary.date == initialDate) {
                                        value.metrics.eventLibrary = inLibrary;
                                    }
                                });
                            }
                            if (Array.isArray(likes)) {
                                likes.map((like) => {
                                    if (like.date == initialDate) {
                                        value.metrics.like = like;
                                    }
                                });
                            }
                            if (initialDate == null) {
                                participationInEvents.push([
                                    value.title.substr(0, 15) + '...',
                                    value.metrics.eventLive.total,
                                    value.metrics.registered.total,
                                    value.metrics.eventLibrary.total
                                ]);
                                likesInEvents.push([value.title.substr(0, 15), value.metrics.like.total]);
                            } else {
                                participationInEvents.push([
                                    value.title.substr(0, 15) + '...',
                                    value.metrics.eventLive.value,
                                    value.metrics.registered.value,
                                    value.metrics.eventLibrary.value
                                ]);
                                likesInEvents.push([value.title, value.metrics.like.value]);
                            }
                        }
                    }
                }
            }
        });
        setEventFiltered(participationInEvents);
        setLikesFiltered(likesInEvents);
        getAllEvents();
    }
    filter?.competence?.map((value) => {
        competencesFilter.push(value);
    });
    filter?.speaker?.map((value) => {
        speakerFilter.push(value);
    });
    filter?.type?.map((value) => {
        typeFilter.push(value);
    });

    useEffect(() => {
        getAllEvents();
    }, []);

    useEffect(() => {
        filterEvent(allData);
    }, [filtered]);

    useEffect(() => {
        setTimeout(() => {
            dispatch({ type: 'SET_FILTERED', payload: !filtered });
        }, 2000);
    }, []);

    return (
        <GridMainContainer container>
            <GridMain item xs={9}>
                <GraphicBar allData={eventFiltered} width="2000px" />
            </GridMain>
            <GridFilter item xs={2}>
                <FilterDates />
                <FilterList competences={competencesFilter} originFilter="competence" title="Por Competência" />
            </GridFilter>
            <GridMain item xs={9}>
                <GraphicArea allData={likesFiltered} width="1500px" />
            </GridMain>
            <GridFilter item xs={2}>
                <FilterList competences={allData} originFilter="event" title="Por Evento" />
                <FilterList competences={speakerFilter} originFilter="speaker" title="Por Professor" />
                <FilterList competences={typeFilter} originFilter="typeEvent" title="Por Tipo" />
            </GridFilter>
            {/* <GridMain item xs={4.4}>
                <GraphicBar />
            </GridMain>
            <GridMain item xs={4.3}>
                <GraphicBar />
            </GridMain> */}
        </GridMainContainer>
    );
};

export default BIEvents;
