import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import FilterListIcon from '@mui/icons-material/FilterList';

export default function ControlledOpenSelect(props) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const filterType = useSelector((state) => state.publication.filterType);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    var i = null;
    return (
        <div>
            {open === false ? (
                <FilterListIcon sx={{ display: 'block', mt: 2 }} onClick={handleOpen}></FilterListIcon>
            ) : (
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-controlled-open-select-label">Filtrar por</InputLabel>
                    <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        value={filterType}
                        label="Filtrar por"
                        onChange={(e) => dispatch({ type: 'SET_FILTER_TYPE', payload: e.target.value })}
                    >
                        {props.filterType.map((value) => ((i = i + 1), (<MenuItem value={i - 1}>{value}</MenuItem>)))}
                    </Select>
                </FormControl>
            )}
        </div>
    );
}
