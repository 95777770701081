import { app } from './Api';

const getCourses = async () => {
    return app.get(`get_resource_course_quiz`);
};
const getClass = async (idCourse) => {
    return app.get(`get_resource_class_quiz/?idCourse=${idCourse}`);
};
const getQuiz = async (page, qtdPerPage) => {
    return app.get(`quiz?page=${page}&qtdPerPage=${qtdPerPage}`);
};
const postQuiz = async (data) => {
    return app.post('quiz', data);
};
const getQuizPerId = async (id) => {
    return app.get(`quiz/${id}`);
};
const updateQuiz = async (id, data) => {
    return app.put(`quiz/${id}`, data);
};
const deleteQuiz = async (id) => {
    return app.delete(`quiz/${id}`);
};

export { getCourses, getClass, postQuiz, getQuizPerId, getQuiz, updateQuiz, deleteQuiz };
