import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import CheckboxInput from '../CheckboxInput';
import DateInput from '../DateInput';
import { useFormikContext } from 'formik';

const SchedulingFormInput = ({ schedule, id, label, minDate, disabled }) => {
    const [checked, setChecked] = useState(!!schedule);
    const { setFieldValue } = useFormikContext();

    const changeCheck = () => {
        setFieldValue(id, null);
        setChecked(!checked);
    };

    useEffect(() => {
        if (checked) {
            return;
        }

        setChecked(!!schedule);
    }, [schedule]);

    return (
        <Container>
            <CheckboxInput
                item={{
                    label: label,
                    id: `${id}_checkbox`,
                    optional: true
                }}
                value={checked || !!schedule}
                setValue={changeCheck}
                disabled={disabled}
            />

            {(checked || !!schedule) && (
                <DateInput
                    handleBlur={() => {}}
                    value={schedule}
                    item={{
                        label: label,
                        id: id,
                        minDate: minDate
                    }}
                    error={null}
                    id={id}
                    disabled={disabled}
                />
            )}
        </Container>
    );
};

SchedulingFormInput.propTypes = {
    schedule: PropTypes.any,
    id: PropTypes.string,
    label: PropTypes.string,
    minDate: PropTypes.object,
    disabled: PropTypes.bool
};

export default SchedulingFormInput;
