import React, { useState, useEffect } from 'react';
import { Container, RankColumn, RankItem } from './styles';
import { CircularProgress } from '@mui/material';
import { getRealTimeUsersBI } from '../../../services/bi';
import { useNavigate } from 'react-router-dom';

const RealTimeUsers = () => {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        getRealTimeUsersBI().then((resp) => {
            setUsers(() => resp);
            setLoading(() => false);
        });
    }, []);

    if (loading) {
        return <CircularProgress />;
    }
    const handleClickUser = (id_user) => {
        console.log(id_user);
    };
    return (
        <>
            {users.length >= 1 && users.length <= 50 ? (
                <Container>
                    <h1>Usuários em tempo real</h1>
                    <RankColumn totalItems={users.length}>
                        {users.slice(0, 10).map((rank, key) => (
                            <RankItem onClick={() => navigate('/usuario/' + rank.id_user + '/view')}>
                                <strong>{key + 1}</strong>
                                <span className={'image'}>
                                    <img src={rank.url_image} alt="" />
                                </span>
                                <span className={'user'}>{rank.name}</span>
                            </RankItem>
                        ))}
                    </RankColumn>
                    <RankColumn>
                        {users.slice(10, 20).map((rank, key) => (
                            <RankItem onClick={() => navigate('/usuario/' + rank.id_user + '/view')}>
                                <strong>{key + 10 + 1}</strong>
                                <span className={'image'}>
                                    <img src={rank.url_image} alt="" />
                                </span>
                                <span className={'user'}>{rank.name}</span>
                            </RankItem>
                        ))}
                    </RankColumn>
                    <RankColumn>
                        {users.slice(20, 30).map((rank, key) => (
                            <RankItem onClick={() => navigate('/usuario/' + rank.id_user + '/view')}>
                                <strong>{key + 20 + 1}</strong>
                                <span className={'image'}>
                                    <img src={rank.url_image} alt="" />
                                </span>
                                <span className={'user'}>{rank.name}</span>
                            </RankItem>
                        ))}
                    </RankColumn>
                    <RankColumn>
                        {users.slice(30, 40).map((rank, key) => (
                            <RankItem onClick={() => navigate('/usuario/' + rank.id_user + '/view')}>
                                <strong>{key + 30 + 1}</strong>
                                <span className={'image'}>
                                    <img src={rank.url_image} alt="" />
                                </span>
                                <span className={'user'}>{rank.name}</span>
                            </RankItem>
                        ))}
                    </RankColumn>
                    <RankColumn>
                        {users.slice(40, 50).map((rank, key) => (
                            <RankItem onClick={() => navigate('/usuario/' + rank.id_user + '/view')}>
                                <strong>{key + 20 + 1}</strong>
                                <span className={'image'}>
                                    <img src={rank.url_image} alt="" />
                                </span>
                                <span className={'user'}>{rank.name}</span>
                            </RankItem>
                        ))}
                    </RankColumn>
                </Container>
            ) : users.length >= 50 ? (
                <RankColumn>
                    {users.slice(50).map((rank, key) => (
                        <RankItem onClick={() => navigate('/usuario/' + rank.id_user + '/view')}>
                            <strong>{key + 20 + 1}</strong>
                            <span className={'image'}>
                                <img src={rank.url_image} alt="" />
                            </span>
                            <span className={'user'}>{rank.name}</span>
                        </RankItem>
                    ))}
                </RankColumn>
            ) : (
                <Container>
                    <h1>Nenhum usuário em tempo real...</h1>
                </Container>
            )}
        </>
    );
};

export default RealTimeUsers;
