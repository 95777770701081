import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    useMediaQuery,
    MenuItem,
    Select,
    Alert,
    ListItemText,
    Snackbar
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import Loading from 'components/Loading/Loading';
import { getEventPerId, getResourcerEvent, postEvent, updateEvent } from 'services/event';
import NumberFormat from 'react-number-format';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ptBR } from 'date-fns/locale';
import Stack from '@mui/material/Stack';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';
import SchedulingFormInput from 'components/Forms/SchedulingFormInput';

const Event = ({ ...others }) => {
    const params = useParams();
    const themeButton = createTheme({
        status: {
            danger: '#e53e3e'
        },
        palette: {
            primary: {
                main: '#0971f1',
                darker: '#053e85'
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff'
            }
        }
    });
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };
    const initialStateOptions = {
        competences: [],
        speakers: [],
        typeEvents: []
    };
    const initialValuesEdit = {
        idTypeEvent: '',
        title: '',
        point: '',
        initialDate: '',
        finalDate: '',
        description: EditorState.createEmpty(),
        url: '',
        file: '',
        speaker: [],
        competence: [],
        schedulingPost: null,
        schedulingTimeline: null
    };
    const theme = useTheme();
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [options, setOptions] = useState(initialStateOptions);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [errorValidate, setErrorValidate] = useState({});
    const [valuesEdit, setValuesEdit] = useState(initialValuesEdit);

    const titlePage =
        params.action === 'view' ? 'Visualização de Eventos' : params.action === 'edit' ? 'Edição de Eventos' : 'Cadastro de Eventos';
    const isDisabled = params.action === 'view' ? true : false;
    useEffect(() => {
        getResource();
    }, []);
    useEffect(() => {
        viewPerId();
    }, [params.id]);
    function viewPerId() {
        params.action &&
            getEventPerId(params.id).then((resp) => {
                setValuesEdit({
                    active: resp.data.active,
                    idTypeEvent: resp.data.id_type_event,
                    title: resp.data.title,
                    point: resp.data.point,
                    url: resp.data.url,
                    description: EditorState.createWithContent(
                        ContentState.createFromBlockArray(convertFromHTML(`${resp.data.description}`))
                    ),
                    url: resp.data.url,
                    speaker: resp.data.speaker.map((res) => res.name),
                    competence: resp.data.competence.map((res) => res.desription),
                    initialDate: new Date(resp.data.initial_date),
                    finalDate: new Date(resp.data.final_date),
                    file: '',
                    schedulingPost: resp.data.scheduling_post,
                    schedulingTimeline: resp.data.scheduling_timeline,
                    timelineActive: resp.data.timelineActive
                });
            });
    }
    function getResource() {
        getResourcerEvent().then((resp) => setOptions(resp.data));
    }
    function setIdsSpeaker(valueSpeaker) {
        let ids = [];
        valueSpeaker.forEach((element) => {
            let valueId = options.speakers.filter((desc) => desc.name === element)[0];
            ids = [...ids, valueId];
        });
        return ids;
    }

    function setIdsCompetence(valueCompetence) {
        let ids = [];
        valueCompetence.forEach((element) => {
            let valueId = options.competences.filter((desc) => desc.desription === element)[0];
            ids = [...ids, valueId];
        });
        return ids;
    }

    const getUTCDate = (dateString) => {
        const padTo2Digits = (num) => {
            return num.toString().padStart(2, '0');
        };
        const date = dateString;
        const dateF =
            [date.getFullYear(), padTo2Digits(date.getUTCMonth() + 1), padTo2Digits(date.getUTCDate())].join('-') +
            ' ' +
            [date.getUTCHours() - 3, date.getUTCMinutes()].join(':');
        return dateF;
    };
    return (
        <>
            <Formik
                initialValues={{
                    idTypeEvent: valuesEdit.idTypeEvent,
                    title: valuesEdit.title,
                    point: valuesEdit.point,
                    initialDate: valuesEdit.initialDate,
                    finalDate: valuesEdit.finalDate,
                    description: valuesEdit.description,
                    url: valuesEdit.url,
                    file: valuesEdit.file,
                    speaker: valuesEdit.speaker || [],
                    competence: valuesEdit.competence || [],
                    submit: null,
                    schedulingPost: valuesEdit.schedulingPost ? new Date(valuesEdit.schedulingPost) : null,
                    schedulingTimeline: valuesEdit.schedulingTimeline ? new Date(valuesEdit.schedulingTimeline) : null,
                    timelineActive: valuesEdit.timelineActive
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    idTypeEvent: Yup.number().required('Tipo de Evento obrigatório'),
                    title: Yup.string().max(255).trim().required('Título obrigatório'),
                    point: Yup.number().required('Pontos obrigatório'),
                    initialDate: Yup.string().required('Data Inicial obrigatório'),
                    finalDate: Yup.string().required('Data Final obrigatório'),
                    //description: Yup.string().trim().required('Descrição obrigatório'),
                    url: Yup.string().trim().required('URL obrigatório'),
                    speaker: Yup.array().required('Palestrante Obrigatório').min(1, 'Necessário escolher palestrante'),
                    competence: Yup.array().required('Competência Obrigatório').min(1, 'Necessário escolher competência'),
                    schedulingTimeline: Yup.date()
                        .nullable()
                        .min(Yup.ref('schedulingPost'), 'Data de post na timeline não deve ser menor que a data da postagem'),
                    schedulingPost: Yup.date()
                        .nullable()
                        .when(
                            'initialDate',
                            (initialDate, schema) =>
                                initialDate && schema.max(initialDate, 'Data de post não deve ser maior que a data inicial')
                        )
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                        const initialDate = getUTCDate(values.initialDate);
                        const finalDate = getUTCDate(values.finalDate);

                        setLoading(true);
                        let idsSpeaker = setIdsSpeaker(values.speaker);
                        let idsCompetence = setIdsCompetence(values.competence);
                        const data = new FormData();
                        let arraySpeaker = [];
                        let arrayCompetence = [];
                        for (let i = 0; i < idsSpeaker.length; i++) {
                            arraySpeaker.push(idsSpeaker[i].id);
                        }
                        for (let i = 0; i < idsCompetence.length; i++) {
                            arrayCompetence.push(idsCompetence[i].id);
                        }
                        data.append(`competence`, JSON.stringify(arrayCompetence));
                        data.append(`speaker`, JSON.stringify(arraySpeaker));
                        data.append('idTypeEvent', values.idTypeEvent);
                        data.append('title', values.title);
                        data.append('point', values.point);
                        data.append('initialDate', initialDate);
                        data.append('finalDate', finalDate);
                        data.append('description', `${draftToHtml(convertToRaw(values.description.getCurrentContent()))}`);
                        data.append('url', values.url);
                        data.append('file', values.file);

                        if (values?.schedulingPost) {
                            data.append('schedulingPost', moment(values?.schedulingPost).format('YYYY-MM-DD HH:mm:ss'));
                        }

                        if (values?.schedulingTimeline) {
                            data.append('schedulingTimeline', moment(values?.schedulingTimeline).format('YYYY-MM-DD HH:mm:ss'));
                        }

                        if (params.action === 'edit') {
                            data.append('_method', 'put');
                            updateEvent(params.id, data, {
                                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                            })
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    viewPerId();
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        setSuccess('');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    setErrorValidate(e.response.data.errorValidate);
                                    setError(e.response.data.error || e.response.data.errorValidate['file']);
                                });
                        } else {
                            postEvent(data, {
                                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                            })
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        setSuccess('');
                                        resetForm();
                                    }, 3000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    setErrorValidate(e.response.data.errorValidate);
                                    setError(e.response.data.error || e.response.data.errorValidate['file']);
                                });
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <>
                        {loading && (
                            <Grid container alignItems="center" justifyContent="center">
                                <MainCard style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Loading color="#015641" type="cubes" />
                                </MainCard>
                            </Grid>
                        )}
                        <MainCard spacing={gridSpacing} style={{ padding: 15, margin: 25 }}>
                            {error || alert ? (
                                <Snackbar open={true} autoHideDuration={6000}>
                                    <Alert
                                        severity={error ? 'error' : success ? 'success' : ''}
                                        sx={{
                                            width: '100%',
                                            backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                            color: '#FFF'
                                        }}
                                    >
                                        {error ? error : success ? success : ''}
                                    </Alert>
                                </Snackbar>
                            ) : (
                                ''
                            )}
                            <div style={{ display: loading ? 'none' : 'block' }}>
                                <form noValidate onSubmit={handleSubmit} {...others}>
                                    <h3>{titlePage}</h3>
                                    <hr></hr>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            <input
                                                error={Boolean(touched.file && errors.file)}
                                                disabled={isDisabled}
                                                type="file"
                                                label="Foto"
                                                onChange={(e) => setFieldValue('file', e.target.files[0])}
                                                helperText={
                                                    touched.file && errors.file ? errors.file : errorValidate.file ? errorValidate.file : ''
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.idTypeEvent && errors.idTypeEvent)}
                                                select
                                                label="Tipo de Evento"
                                                id="idTypeEvent"
                                                type="text"
                                                value={values.idTypeEvent || ''}
                                                name="idTypeEvent"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.idTypeEvent && errors.idTypeEvent
                                                        ? errors.idTypeEvent
                                                        : errorValidate.idTypeEvent
                                                        ? errorValidate.idTypeEvent
                                                        : ''
                                                }
                                            >
                                                {options.typeEvents.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.description}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.title && errors.title) || Boolean(errorValidate.title)}
                                                id="outlined-title"
                                                type="text"
                                                label="Nome"
                                                value={values.title}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="title"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.title && errors.title
                                                        ? errors.title
                                                        : errorValidate.title
                                                        ? errorValidate.title
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} sx={{ marginTop: 3 }}>
                                            <NumberFormat
                                                fullWidth
                                                error={Boolean(touched.point && errors.point) || Boolean(errorValidate.point)}
                                                id="outlined-point"
                                                type="text"
                                                label="Pontos"
                                                value={values.point}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="point"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.point && errors.point
                                                        ? errors.point
                                                        : errorValidate.point
                                                        ? errorValidate.point
                                                        : ''
                                                }
                                                customInput={TextField}
                                                isAllowed={(values) => {
                                                    const { floatValue, formattedValue } = values;
                                                    return formattedValue === '' || (floatValue >= 1 && floatValue <= 99);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.url && errors.url) || Boolean(errorValidate.url)}
                                                id="outlined-url"
                                                type="text"
                                                label="URL"
                                                value={values.url}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="url"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.url && errors.url ? errors.url : errorValidate.url ? errorValidate.url : ''
                                                }
                                            />
                                        </Grid>
                                    </Grid>

                                    {!valuesEdit?.active && (
                                        <Grid item xs={6} sm={0} sx={{ marginTop: 0 }}>
                                            <FormControl
                                                sx={{ width: '100%' }}
                                                error={
                                                    Boolean(touched?.schedulingPost && errors?.schedulingPost) ||
                                                    Boolean(errorValidate?.schedulingPost)
                                                }
                                            >
                                                <SchedulingFormInput
                                                    schedule={values.schedulingPost}
                                                    setSchedule={handleChange}
                                                    id="schedulingPost"
                                                    label="Agendar Publicação de evento"
                                                    minDate={new Date()}
                                                    disabled={params.action === 'view'}
                                                />

                                                {errorValidate.schedulingPost && (
                                                    <FormHelperText error id="standard-weight-helper-text--schedulingPost">
                                                        {errorValidate.schedulingPost}
                                                    </FormHelperText>
                                                )}
                                                {touched.schedulingPost && errors.schedulingPost && (
                                                    <FormHelperText error id="standard-weight-helper-text--schedulingPost">
                                                        {errors.schedulingPost}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    )}

                                    {(!valuesEdit?.timelineActive || values.schedulingTimeline) && (
                                        <Grid item xs={6} sm={0} sx={{ marginTop: 0 }}>
                                            <FormControl
                                                sx={{ width: '100%' }}
                                                error={
                                                    Boolean(touched?.schedulingTimeline && errors?.schedulingTimeline) ||
                                                    Boolean(errorValidate?.schedulingTimeline)
                                                }
                                            >
                                                <SchedulingFormInput
                                                    schedule={values.schedulingTimeline}
                                                    setSchedule={handleChange}
                                                    id="schedulingTimeline"
                                                    label="Agendar Publicação na Timeline"
                                                    minDate={new Date()}
                                                    disabled={params.action === 'view'}
                                                />

                                                {errorValidate.schedulingTimeline && (
                                                    <FormHelperText error id="standard-weight-helper-text--schedulingTimeline">
                                                        {errorValidate.schedulingTimeline}
                                                    </FormHelperText>
                                                )}
                                                {touched.schedulingTimeline && errors.schedulingTimeline && (
                                                    <FormHelperText error id="standard-weight-helper-text--schedulingTimeline">
                                                        {errors.schedulingTimeline}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    )}

                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                            <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                                                <Stack spacing={3}>
                                                    <DateTimePicker
                                                        fullWidth
                                                        ampm={false}
                                                        error={Boolean(touched.initialDate && errors.initialDate)}
                                                        label="Data de Início"
                                                        id="initialDate"
                                                        type="date"
                                                        value={values.initialDate}
                                                        name="initialDate"
                                                        onBlur={handleBlur}
                                                        disabled={isDisabled}
                                                        onChange={(e) => setFieldValue('initialDate', e)}
                                                        helperText={touched.initialDate && errors.initialDate ? errors.initialDate : ''}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </Stack>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                            <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                                                <Stack spacing={3}>
                                                    <DateTimePicker
                                                        fullWidth
                                                        ampm={false}
                                                        error={Boolean(touched.finalDate && errors.finalDate)}
                                                        label="Data de Término"
                                                        id="finalDate"
                                                        type="date"
                                                        value={values.finalDate}
                                                        name="finalDate"
                                                        onBlur={handleBlur}
                                                        disabled={isDisabled}
                                                        onChange={(e) => setFieldValue('finalDate', e)}
                                                        helperText={touched.finalDate && errors.finalDate ? errors.finalDate : ''}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </Stack>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            {values.description && (
                                                <Editor
                                                    editorStyle={{
                                                        height: '200px',
                                                        width: '100%',
                                                        border: '1px solid #DCDCDC',
                                                        borderRadius: '25px',
                                                        padding: '20px'
                                                    }}
                                                    toolbar={{
                                                        options: ['inline', 'list', 'textAlign', 'fontFamily', 'fontSize', 'link'],
                                                        inline: {
                                                            options: ['bold', 'italic', 'underline']
                                                        },
                                                        list: {
                                                            options: ['unordered', 'ordered', 'indent', 'outdent']
                                                        },
                                                        textAlign: {
                                                            options: ['left', 'center', 'right']
                                                        },
                                                        fontFamily: {
                                                            options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana']
                                                        }
                                                    }}
                                                    editorState={values.description}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={(e) => setFieldValue('description', e)}
                                                    disabled={isDisabled}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 2 }}>
                                            <FormControl
                                                sx={{ width: '100%' }}
                                                error={Boolean(touched.speaker && errors.speaker) || Boolean(errorValidate.speaker)}
                                            >
                                                <InputLabel id="speaker">Palestrantes</InputLabel>
                                                <Select
                                                    labelId="speaker"
                                                    onBlur={handleBlur}
                                                    multiple
                                                    name="speaker"
                                                    id="speaker"
                                                    value={values.speaker}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            'speaker',
                                                            typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                                                        )
                                                    }
                                                    input={<OutlinedInput label="Palestrantes" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                    disabled={isDisabled}
                                                >
                                                    {options.speakers.map((option) => (
                                                        <MenuItem key={option.id} value={option.name}>
                                                            <Checkbox checked={values.speaker.indexOf(option.name) > -1} />
                                                            <ListItemText primary={option.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errorValidate.speaker && (
                                                    <FormHelperText error id="standard-weight-helper-text--speaker">
                                                        {errorValidate.speaker}
                                                    </FormHelperText>
                                                )}
                                                {touched.speaker && errors.speaker && (
                                                    <FormHelperText error id="standard-weight-helper-text--speaker">
                                                        {errors.speaker}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 2 }}>
                                            <FormControl
                                                sx={{ width: '100%' }}
                                                error={
                                                    Boolean(touched.competence && errors.competence) || Boolean(errorValidate.competence)
                                                }
                                            >
                                                <InputLabel id="competence">Competências</InputLabel>
                                                <Select
                                                    labelId="competence"
                                                    onBlur={handleBlur}
                                                    multiple
                                                    name="competence"
                                                    id="competence"
                                                    value={values.competence}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            'competence',
                                                            typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                                                        )
                                                    }
                                                    input={<OutlinedInput label="Competências" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                    disabled={isDisabled}
                                                >
                                                    {options.competences.map((option) => (
                                                        <MenuItem key={option.id} value={option.desription}>
                                                            <Checkbox checked={values.competence.indexOf(option.desription) > -1} />
                                                            <ListItemText primary={option.desription} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errorValidate.competence && (
                                                    <FormHelperText error id="standard-weight-helper-text--competence">
                                                        {errorValidate.competence}
                                                    </FormHelperText>
                                                )}
                                                {touched.competence && errors.competence && (
                                                    <FormHelperText error id="standard-weight-helper-text--competence">
                                                        {errors.competence}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid container alignItems="end" justifyContent="end" sx={{ mt: 3 }}>
                                        <Grid item>
                                            <Box sx={{ mt: 2, mr: 3 }}>
                                                <ThemeProvider theme={themeButton}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="button"
                                                            variant="contained"
                                                            color="neutral"
                                                            onClick={() => navigate(-1)}
                                                        >
                                                            Voltar
                                                        </Button>
                                                    </AnimateButton>
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                        {params.action === 'view' ? (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            // disabled={isSubmitting}
                                                            component={Link}
                                                            to={`/evento/${params.id}/edit`}
                                                            fullWidth
                                                            size="large"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Editar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        ) : (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Salvar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                </form>
                            </div>
                        </MainCard>
                    </>
                )}
            </Formik>
        </>
    );
};

export default Event;
