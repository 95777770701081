export const initialState = {
    optionMenu: 'DadosGerais',
    initialDate: null,
    finalDate: null,
    competenceFilter: null,
    courseFilter: null,
    eventFilter: null,
    speakerFilter: null,
    typeEventFilter: null,
    filtered: false,
    typeResourceFilter: [1, 2, 3, 4, 5, 6, 7, 8]
};

const BIReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_OPTION_MENU':
            return {
                ...state,
                optionMenu: actions.payload
            };
        case 'SET_INITIAL_DATE':
            return {
                ...state,
                initialDate: actions.payload
            };
        case 'SET_COMPETENCE_FILTER':
            return {
                ...state,
                competenceFilter: actions.payload
            };
        case 'SET_COURSE_FILTER':
            return {
                ...state,
                courseFilter: actions.payload
            };
        case 'SET_EVENT_FILTER':
            return {
                ...state,
                eventFilter: actions.payload
            };
        case 'SET_TYPE_EVENT_FILTER':
            return {
                ...state,
                typeEventFilter: actions.payload
            };
        case 'SET_TYPE_RESOURCE_FILTER':
            actions.payload === null ? (actions.payload = [1, 2, 3, 4, 5, 6, 7, 8]) : actions.payload;
            return {
                ...state,
                typeResourceFilter: actions.payload
            };
        case 'SET_SPEAKER_FILTER':
            return {
                ...state,
                speakerFilter: actions.payload
            };
        case 'SET_FILTERED':
            return {
                ...state,
                filtered: actions.payload
            };
        default:
            return state;
    }
};

export default BIReducer;
