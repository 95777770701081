export const initialState = {
    page: 0,
    rowsPerPage: null,
    idFarmFilter: '',
    emailUserFilter: ''
};

// ==============================|| AUTH REDUCER ||============================== //

const userReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_PAGE_USER':
            return {
                ...state,
                page: actions.payload
            };
        case 'SET_ROWS_PER_PAGE_USER':
            return {
                ...state,
                rowsPerPage: actions.payload
            };
        case 'SET_IDFARMFILTER_USER':
            return {
                ...state,
                idFarmFilter: actions.payload
            };
        case 'SET_EMAILUSERFILTER_USER':
            return {
                ...state,
                emailUserFilter: actions.payload
            };
        case 'SET_CLEAR_USER_FILTER':
            return {
                ...state,
                idFarmFilter: '',
                idUserFilter: '',
                emailUserFilter: ''
            };
        default:
            return state;
    }
};

export default userReducer;
