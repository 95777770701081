import { Graphic, Buttons, Text } from './styles';
import { GraphicLine } from '../graphicLine';
import { useEffect, useState } from 'react';
import { getBiEngagement } from 'services/bi';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import moment from 'moment';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Text>{children}</Text>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const BIUserEngagement = () => {
    const [numUsuarios, setNumUsuarios] = useState('');
    const [numNovosUsuarios, setNumNovosUsuarios] = useState('');
    const [dataUser, setDataUser] = useState('');
    const [dataGraph, setDataGraph] = useState([]);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        handleFilter(1);
    };
    useEffect(() => {
        getDataBiEngagement();
    }, []);

    function getDataBiEngagement() {
        getBiEngagement().then((resp) => {
            setDataUser(resp);
            setNumUsuarios(resp.totalUsersOneWeek);
            setNumNovosUsuarios(resp.newUsersOneWeek);
            let numDiasSemana = resp.totalUsersOneWeekByDay;
            const graphic = [];
            const start = moment().subtract(6, 'days');
            const end = moment();
            graphic[0] = ['Dias', 'Usuários'];
            let d = 1;
            while (start.isSameOrBefore(end, 'day')) {
                let numDay = start.day();
                let strDay = diaSemana(numDay);
                numDiasSemana.forEach((dia) => {
                    if (dia['dia'] === strDay) {
                        graphic[d] = [strDay, dia['total']];
                    } else {
                        graphic[d] ? '' : (graphic[d] = [strDay, 0]);
                    }
                });
                start.add(1, 'day');
                d++;
            }
            setDataGraph(graphic);
        });
    }

    function diaSemana(numero) {
        let diasemana;
        switch (numero) {
            case 0:
                diasemana = 'Dom';
                break;
            case 1:
                diasemana = 'Seg';
                break;
            case 2:
                diasemana = 'Ter';
                break;
            case 3:
                diasemana = 'Qua';
                break;
            case 4:
                diasemana = 'Qui';
                break;
            case 5:
                diasemana = 'Sex';
                break;
            case 6:
                diasemana = 'Sab';
                break;
        }
        return diasemana;
    }

    function handleFilter(event) {
        if (event?.target?.value === '1' || event === 1) {
            if ((value === 1 && event?.target?.value) || (event === 1 && value === 0)) {
                setNumUsuarios(dataUser.totalUsersOneWeek);
                setNumNovosUsuarios(dataUser.newUsersOneWeek);
                let numDiasSemana = dataUser.newUsersOneWeekByDay;
                const graphic = [];
                const start = moment().subtract(6, 'days');
                const end = moment();
                graphic[0] = ['Dias', 'Novos Usuários'];
                let d = 1;
                while (start.isSameOrBefore(end, 'day')) {
                    let numDay = start.day();
                    let strDay = diaSemana(numDay);
                    numDiasSemana.forEach((dia) => {
                        if (dia['dia'] === strDay) {
                            graphic[d] = [strDay, dia['total']];
                        } else {
                            graphic[d] ? '' : (graphic[d] = [strDay, 0]);
                        }
                    });
                    start.add(1, 'day');
                    d++;
                }
                setDataGraph(graphic);
            } else {
                setNumUsuarios(dataUser.totalUsersOneWeek);
                setNumNovosUsuarios(dataUser.newUsersOneWeek);
                let numDiasSemana = dataUser.totalUsersOneWeekByDay;
                const graphic = [];
                const start = moment().subtract(6, 'days');
                const end = moment();
                graphic[0] = ['Dias', 'Usuários'];
                let d = 1;
                while (start.isSameOrBefore(end, 'day')) {
                    let numDay = start.day();
                    let strDay = diaSemana(numDay);
                    numDiasSemana.forEach((dia) => {
                        if (dia['dia'] === strDay) {
                            graphic[d] = [strDay, dia['total']];
                        } else {
                            graphic[d] ? '' : (graphic[d] = [strDay, 0]);
                        }
                    });
                    start.add(1, 'day');
                    d++;
                }
                setDataGraph(graphic);
            }
            let button = document.getElementsByName(`btn-7-days`);
            button[0].style.backgroundColor = 'blue';
            button[0].style.color = 'white';
            let button2 = document.getElementsByName(`btn-30-days`);
            button2[0].style.backgroundColor = 'white';
            button2[0].style.color = 'blue';
            let button3 = document.getElementsByName(`btn-1-year`);
            button3[0].style.backgroundColor = 'white';
            button3[0].style.color = 'blue';
        } else if (event.target.value === '2') {
            let button = document.getElementsByName(`btn-30-days`);
            button[0].style.backgroundColor = 'blue';
            button[0].style.color = 'white';
            let button2 = document.getElementsByName(`btn-7-days`);
            button2[0].style.backgroundColor = 'white';
            button2[0].style.color = 'blue';
            let button3 = document.getElementsByName(`btn-1-year`);
            button3[0].style.backgroundColor = 'white';
            button3[0].style.color = 'blue';
            if (value === 1) {
                setNumUsuarios(dataUser.totalUsersOneMonth);
                setNumNovosUsuarios(dataUser.newUsersOneMonth);
                let numDiasMes = dataUser.totalNewUsersOneMonthByDay;
                const graphic = [];
                const start = moment().subtract(30, 'days');
                const end = moment();
                graphic[0] = ['Dias', 'Novos Usuários'];
                let d = 1;
                while (start.isSameOrBefore(end, 'day')) {
                    let strDay = start.format('DD');
                    numDiasMes.forEach((dia) => {
                        if (dia['dia'] == strDay) {
                            graphic[d] = [strDay, dia['total']];
                        } else {
                            graphic[d] ? '' : (graphic[d] = [strDay, 0]);
                        }
                    });
                    start.add(1, 'day');
                    d++;
                }
                setDataGraph(graphic);
            } else {
                setNumUsuarios(dataUser.totalUsersOneMonth);
                setNumNovosUsuarios(dataUser.newUsersOneMonth);
                let numDiasMes = dataUser.totalUsersOneMonthByDay;
                const graphic = [];
                const start = moment().subtract(30, 'days');
                const end = moment();
                graphic[0] = ['Dias', 'Usuários'];
                let d = 1;
                while (start.isSameOrBefore(end, 'day')) {
                    let strDay = start.format('DD');
                    numDiasMes.forEach((dia) => {
                        if (dia['dia'] == strDay) {
                            graphic[d] = [strDay, dia['total']];
                        } else {
                            graphic[d] ? '' : (graphic[d] = [strDay, 0]);
                        }
                    });
                    start.add(1, 'day');
                    d++;
                }
                setDataGraph(graphic);
            }
        } else if (event.target.value === '3') {
            let button = document.getElementsByName(`btn-1-year`);
            button[0].style.backgroundColor = 'blue';
            button[0].style.color = 'white';
            let button2 = document.getElementsByName(`btn-7-days`);
            button2[0].style.backgroundColor = 'white';
            button2[0].style.color = 'blue';
            let button3 = document.getElementsByName(`btn-30-days`);
            button3[0].style.backgroundColor = 'white';
            button3[0].style.color = 'blue';
            if (value === 1) {
                setNumUsuarios(dataUser.totalUsersOneYear);
                setNumNovosUsuarios(dataUser.newUsersOneYear);
                let numMesesAno = dataUser.totalNewUsersYearByMonth;
                const graphic = [];
                const start = moment().subtract(11, 'months');
                const end = moment();
                graphic[0] = ['Meses', 'Novos Usuários'];
                let m = 1;
                while (start.isSameOrBefore(end, 'day')) {
                    let strMonth = start.format('MMM');
                    let label = start.format('MMM/YY');
                    numMesesAno.forEach((mes) => {
                        if (mes['mes'] === strMonth) {
                            graphic[m] = [label, mes['total']];
                        } else {
                            graphic[m] ? '' : (graphic[m] = [label, 0]);
                        }
                    });
                    start.add(1, 'month');
                    m++;
                }
                setDataGraph(graphic);
            } else {
                setNumUsuarios(dataUser.totalUsersOneYear);
                setNumNovosUsuarios(dataUser.newUsersOneYear);
                let numMesesAno = dataUser.totalUsersYearByMonth;
                const graphic = [];
                const start = moment().subtract(11, 'months');
                const end = moment();
                graphic[0] = ['Meses', 'Usuários'];
                let m = 1;
                while (start.isSameOrBefore(end, 'day')) {
                    let strMonth = start.format('MMM');
                    let label = start.format('MMM/YY');
                    numMesesAno.forEach((mes) => {
                        if (mes['mes'] === strMonth) {
                            graphic[m] = [label, mes['total']];
                        } else {
                            graphic[m] ? '' : (graphic[m] = [label, 0]);
                        }
                    });
                    start.add(1, 'month');
                    m++;
                }
                setDataGraph(graphic);
            }
        }
    }

    return (
        <Graphic>
            <h1>Engajamento</h1>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Usuários" {...a11yProps(0)} />
                        <Tab label="Novos usuários" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    {numUsuarios[0] ? numUsuarios[0].totalUsers : ''}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {numNovosUsuarios[0] ? numNovosUsuarios[0].newUsers : ''}
                </TabPanel>
            </Box>
            <GraphicLine data={dataGraph} />
            <Buttons>
                <Button
                    variant="contained"
                    size="small"
                    onClick={handleFilter}
                    value={1}
                    name={`btn-7-days`}
                    style={{ backgroundColor: 'blue', color: 'white' }}
                >
                    Últimos 7 dias
                </Button>
                <Button variant="outlined" size="small" onClick={handleFilter} value={2} name={`btn-30-days`}>
                    Últimos 30 dias
                </Button>
                <Button variant="outlined" size="small" onClick={handleFilter} value={3} name={`btn-1-year`}>
                    Último ano
                </Button>
            </Buttons>
        </Graphic>
    );
};
export default BIUserEngagement;
