import { Grid, Paper } from '@mui/material';
import styled from 'styled-components';

export const GridMainContainer = styled(Grid)`
    padding: 0.5rem;
`;

export const GraphicContainer = styled(Paper)`
    border: 1px solid black;
    border-radius: 7px;
    padding: 1rem;
    height: 300px;
    background-color: #d9d0ff;
    &.implemented {
        background-color: transparent;
    }
`;

export const GraphicTypeAccessContainer = styled(Paper)`
    border: 1px solid black;
    border-radius: 7px;
    padding: 1rem;
    height: 300px;
`;
