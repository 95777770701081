import * as React from 'react';
import Stack from '@mui/material/Stack';
import { ButtonStyled } from './styles';

export default function Button({ startIcon, text, marginRight, onClick }) {
    return (
        <Stack spacing={2} direction="row">
            <ButtonStyled startIcon={startIcon} variant="contained" marginRight={marginRight} onClick={onClick}>
                {text}
            </ButtonStyled>
        </Stack>
    );
}
