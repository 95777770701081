import styled from 'styled-components';
import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

export const GridMainContainer = styled(Grid)`
    border: 1px solid #c1c1c1;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    > h4 {
        margin: 5px;
    }
`;

export const Divider = styled.hr`
    width: 100%;
    border: none;
    border-top: 1px solid #c1c1c1;
`;

export const GridFilter = styled(Grid)`
    margin-bottom: 1rem;
`;

export const StackStyled = styled(Stack)`
    padding: 8px;
`;

export const ButtonStyled = styled(Button)`
    background-color: #3a6647;
    margin-top: 10px;
    &:hover {
        background-color: #6fb283;
    }
`;
