import { app } from './Api';

const getEvent = async () => {
    return app.get('event');
};
const postEvent = async (data) => {
    return app.post('event', data);
};
const getEvents = async (page, qtdPerPage, title, date) => {
    return app.get(`event?page=${page}&qtdPerPage=${qtdPerPage}&title=${title || ''}&date=${date || ''}`);
};
const searchEvents = async (title, dateEvent) => {
    return app.get(`event?title=${title || ''}&date=${dateEvent || ''}`);
};
const getEventPerId = async (id) => {
    return app.get(`event/${id}`);
};
const updateEvent = async (id, data) => {
    return app.post(`event/${id}`, data);
};
const deleteEvent = async (id) => {
    return app.delete(`event/${id}`);
};
const getResourcerEvent = async () => {
    return app.get(`get_resource_event`);
};
const changeStatusEvent = async (id, data) => {
    return app.put(`change_status_event/${id}`, data);
};
const backToTheTopEvent = async (data) => {
    return app.post(`back_event_top`, data);
};

export {
    getEvent,
    postEvent,
    getEvents,
    getEventPerId,
    updateEvent,
    deleteEvent,
    getResourcerEvent,
    changeStatusEvent,
    backToTheTopEvent,
    searchEvents
};
