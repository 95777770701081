import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import React from 'react';

import { useFormikContext } from 'formik';
import { ContainerDesktop, ContainerMobile, Input } from './styles';
import useWindowSize from 'hooks/useWindowSize';

const DateInput = ({ id, value, handleBlur, error, item, autoStartDate, disabled }) => {
    const { setFieldValue } = useFormikContext();
    const size = useWindowSize();
    const startDate = value || autoStartDate ? new Date() : null;

    const { label, maxDate, minDate, optional } = item;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} utils={DateFnsUtils} locale={ptBR}>
            {size.width > 768 ? (
                <ContainerDesktop
                    label={`${label}${optional ? '' : '*'}`}
                    inputFormat="dd/MM/yyyy HH:mm"
                    value={value || startDate}
                    onBlur={handleBlur}
                    onChange={(value) => setFieldValue(id, value)}
                    renderInput={(params) => <Input {...params} helperText={error} error={error} placeholder="Nenhuma data selecionada" />}
                    id={id}
                    name={id}
                    minDate={minDate}
                    maxDate={maxDate}
                    disabled={disabled}
                />
            ) : (
                <ContainerMobile
                    label={`${label}${optional ? '' : '*'}`}
                    inputFormat="dd/MM/yyyy HH:mm"
                    value={value || startDate}
                    onBlur={handleBlur}
                    onChange={(value) => setFieldValue(id, value)}
                    renderInput={(params) => <Input {...params} helperText={error} error={error} placeholder="Nenhuma data selecionada" />}
                    id={id}
                    name={id}
                    minDate={minDate}
                    maxDate={maxDate}
                    disabled={disabled}
                />
            )}
        </LocalizationProvider>
    );
};

DateInput.propTypes = {
    handleBlur: PropTypes.func.isRequired,
    value: PropTypes.any,
    error: PropTypes.string,
    id: PropTypes.string,
    item: PropTypes.object.isRequired,
    autoStartDate: PropTypes.bool,
    disabled: PropTypes.bool
};

export default DateInput;
