import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid, Box, TextField, IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import BasicModal from '../../../components/Modal/BasicModal';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { MENU_OPEN } from 'store/actions';
import { deleteAbility, getAbility, searchAbilitys } from 'services/ability';
import 'react-toggle/style.css';
import AddButton from 'components/Buttons/AddButton';
import debounce from 'lodash.debounce';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#015641',
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

export default function GridAbility() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const page = useSelector((state) => state.ability.page);
    const rowsPerPage = useSelector((state) => state.ability.rowsPerPage);
    const [ability, setAbility] = React.useState([]);
    const [success, setSuccess] = React.useState('');
    const [error, setError] = React.useState('');
    const [searchTerm, setSearchTerm] = React.useState('');
    const [idDestroyAbility, setIdDestroyAbility] = React.useState('');
    const [openModal, setOpenModal] = React.useState(false);

    const handleOpenDestroy = (idDestroy) => {
        setIdDestroyAbility(idDestroy);
        setOpenModal(true);
    };

    const handleCloseModal = () => setOpenModal(false);

    const handleChangePage = (ability, newPage) => {
        dispatch({ type: 'SET_PAGE_ABILITY', payload: newPage });
    };

    const handleChangeRowsPerPage = (ability) => {
        dispatch({ type: 'SET_ROWS_PER_PAGE_ABILITY', payload: parseInt(ability.target.value, 10) });
        dispatch({ type: 'SET_PAGE_ABILITY', payload: 0 });
    };

    function getAllAbility(name) {
        getAbility(page + 1, rowsPerPage, name).then((resp) => setAbility(resp.data));
    }

    const deleteAbilityById = () => {
        handleCloseModal();
        deleteAbility(idDestroyAbility)
            .then((resp) => {
                getAllAbility();
                setError('');
                setSuccess(resp.data.success);
                setIdDestroyAbility('');
                setTimeout(() => {
                    setSuccess('');
                }, 3000);
            })
            .catch((e) => {
                console.log(e);
                setSuccess('');
                setError(e.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    };

    const debouncedSearch = React.useCallback(
        debounce((searchTerm) => {
            searchAbilitys(searchTerm).then((resp) => setAbility(resp.data));
        }, 300),
        []
    );

    React.useEffect(() => {
        if (searchTerm) {
            debouncedSearch(searchTerm);
        } else {
            getAllAbility();
        }
    }, [searchTerm, page, rowsPerPage]);

    React.useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'Habilidades');
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: 'Habilidades' });
        }
    }, []);

    return (
        <>
            <BasicModal
                open={openModal}
                title="Excluir Habilidade"
                handleClose={handleCloseModal}
                description="Tem certeza que deseja excluir a habilidade?"
                onDelete={deleteAbilityById}
            />
            <TableContainer sx={{ mt: 2, height: '100%', padding: 3 }} component={Paper}>
                <Grid container spacing={matchDownSM ? 2 : 2}>
                    <Grid item xs={12} sm={6} md={8} sx={{ marginTop: 3 }}>
                        <h1
                            style={{
                                font: 'normal normal bold 35px/44px Roboto, sans-serif',
                                letterSpacing: '0px',
                                color: 'var(--unnamed-color-015641)',
                                Color: '#015641',
                                opacity: 1,
                                padding: 15,
                                marginLeft: '2%'
                            }}
                        >
                            <AutoAwesomeOutlinedIcon /> HABILIDADES
                        </h1>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ marginTop: 8 }}>
                        <Box display="flex" justifyContent="right" alignItems="right" marginRight={2}>
                            <TextField
                                id="search"
                                fullWidth
                                label="Pesquisar"
                                variant="outlined"
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <hr style={{ width: '95%', marginTop: 0 }}></hr>
                <h3
                    style={{
                        font: 'normal normal 300 18px/22px Roboto, sans-serif',
                        letterSpacing: '0px',
                        Color: '#00000',
                        opacity: 1,
                        padding: 15,
                        marginLeft: '2%'
                    }}
                >
                    Gerencie as habilidades
                </h3>
                {error || success ? (
                    <Snackbar open={true} autoHideDuration={6000}>
                        <Alert
                            severity={error ? 'error' : success ? 'success' : ''}
                            sx={{
                                width: '100%',
                                backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                color: '#FFF'
                            }}
                        >
                            {error ? error : success ? success : ''}
                        </Alert>
                    </Snackbar>
                ) : (
                    ''
                )}
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Nome</StyledTableCell>
                            <StyledTableCell align="center">Ações</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ability.data &&
                            ability.data.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell>{row.name}</StyledTableCell>
                                    <StyledTableCell style={{ width: 160 }} align="center">
                                        <Tooltip title="Visualizar">
                                            <IconButton onClick={() => navigate({ pathname: `/habilidade/${row.id}/view` })}>
                                                <ViewIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Editar">
                                            <IconButton onClick={() => navigate({ pathname: `/habilidade/${row.id}/edit` })}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Excluir">
                                            <IconButton onClick={() => handleOpenDestroy(row.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={3}
                                count={ability.total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'Registros por Página'
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <AddButton href="/nova_habilidade" />
        </>
    );
}
