import { useState, useEffect } from 'react';
import { getBiAccessMonth } from 'services/bi';
import { Container } from './styles';
import Bar from '../graphicBar';
import moment from 'moment';

const AccessMonth = () => {
    const [data, setData] = useState([]);
    const options = {
        title: 'Acessos',
        chartArea: { width: '100%', height: '90%' },
        legend: { position: 'none' },
        explorer: { axis: 'horizontal', keepInBounds: false },
        colors: ['#73A4F7'],
        tooltip: { ignoreBounds: true }
    };

    useEffect(() => {
        getDataBi();
    }, []);

    function formatData(data) {
        const graphic = [];
        const start = moment().subtract(6, 'months');
        const end = moment().subtract(1, 'month');
        graphic[0] = ['Mês', 'Acessos'];
        let m = 1;
        while (start.isSameOrBefore(end, 'day')) {
            let strMonth = start.format('MMM');
            let label = start.format('MMM/YY');
            data.forEach((mes) => {
                if (mes[0] === strMonth) {
                    graphic[m] = [label, mes[1]];
                } else {
                    graphic[m] ? '' : (graphic[m] = [label, 0]);
                }
            });
            start.add(1, 'month');
            m++;
        }
        setData(graphic);
    }

    function getDataBi() {
        getBiAccessMonth().then((resp) => {
            formatData(resp.data);
        });
    }

    return (
        <Container>
            <h1 style={{ color: 'black' }}>Quantidade de acessos</h1>
            <Bar height="90%" width="100%" allData={data} options={options} />
        </Container>
    );
};

export default AccessMonth;
