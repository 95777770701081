import styled from 'styled-components';
import Button from '@mui/material/Button';

export const ButtonStyled = styled(Button)`
    background-color: #015641;
    &:hover {
        background-color: #6fb283;
    }
    margin-right: ${({ marginRight }) => (marginRight ? marginRight : '')};
`;
