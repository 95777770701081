import { Grid } from '@mui/material';
import { Container, GridMain, GridFilter, GridMainContainer } from './styles';
import { useEffect } from 'react';
import { MENU_OPEN } from 'store/actions';
import Header from '../../../components/BI/header';
import BICourses from '../../../components/BI/courses';
import { useDispatch, useSelector } from 'react-redux';
import BIEvents from 'components/BI/events';
import BILibrary from 'components/BI/library';
import BIgeneralData from 'components/BI/generalData';
import BIFavorites from 'components/BI/favorites';
// ============================== Index ============================== //

const HomeComponent = () => {
    const dispatch = useDispatch();
    const optionSelected = useSelector((state) => state.bi.optionMenu);

    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'index');
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: 'index' });
        }
    }, []);

    return (
        <Container>
            <Header>
                <h1>Menu</h1>
            </Header>
            <main>
                {
                    {
                        Cursos: <BICourses />,
                        Eventos: <BIEvents />,
                        Biblioteca: <BILibrary />,
                        DadosGerais: <BIgeneralData />,
                        Favoritos: <BIFavorites />
                    }[optionSelected]
                }
            </main>
        </Container>
    );
};

export default HomeComponent;
