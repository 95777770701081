import styled from 'styled-components';
export const Graphic = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    border: 1px solid #c1c1c1;
    border-radius: 7px;
    padding: 1rem;
    height: 100%;
    width: 70%;
    > h1 {
        align-self: flex-start;
        font-weight: 700;
        font-size: 24px;
        color: #25272e;
    }
    ::-webkit-scrollbar {
        border-radius: 10px;
        width: 0.7rem;
        height: 0.4rem;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #aaa;
    }

    ::-webkit-scrollbar-thumb:hover {
        border-radius: 10px;
        background: #3a6647;
    }
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    width: 100%;
    justify-content: center;
    > button {
        margin-right: 20px;
        width: 150px;
        height: 30px;
    }
`;

export const Text = styled.span`
    font-weight: bold;
    font-size: 24px;
`;
