import styled from 'styled-components';

export const Container = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5%;
    margin-bottom: 1%;
    overflow: auto;
    max-height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: white;
    border: 2px solid #000;
    justify-content: ${(props) => (props.loading ? 'center' : 'flex-start')};
    align-items: ${(props) => (props.loading ? 'center' : 'flex-start')};
    > h1 {
        width: 100%;
        color: black;
    }
    padding: 6px;
`;
export const RankColumn = styled.div`
    width: ${(props) =>
        props.totalItems <= 10
            ? '100%'
            : props.totalItems <= 20
            ? '50%'
            : props.totalItems <= 30
            ? '33%'
            : props.totalItems <= 40
            ? '25%'
            : props.totalItems <= 50
            ? '20%'
            : '15'};
    display: flex;
    flex-direction: column;
`;
export const RankItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 2.5px 5px;
    gap: 10px;
    border-bottom: 1px solid #c1c1c1;
    color: #25272e;
    font-weight: 400;
    cursor: pointer;
    overflow: hidden;
    > strong {
        min-width: 20px;
        max-width: 20px;
        text-align: right;
    }
    > span.image {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 32px;
        max-width: 32px;
        > img {
            min-width: 32px;
            max-width: 32px;
            min-height: 32px;
            max-height: 32px;
            border-radius: 50%;
            overflow: hidden;
        }
    }
    > span.user {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
