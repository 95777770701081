import React from 'react';
import { Chart } from 'react-google-charts';
import PropTypes from 'prop-types';

export function GraphicLine({ data, title, height }) {
    const options = {
        title: title,
        vAxis: { minValue: 0 },
        axes: {
            x: {
                0: { side: 'bottom' }
            }
        },
        chartArea: { left: 30, width: '85%', height: '90%' },
        legend: { position: 'right' },
        pointSize: 5,
        colors: ['#ED9F4A', '#D35E4B', '#51A744', '#73A4F7', '#8144A7', '#DE71CD']
    };
    return data.length > 1 ? (
        <Chart chartType="LineChart" width={'1010px'} height={'400px'} data={data} options={options} />
    ) : (
        <h1>Sem resultado na busca...</h1>
    );
}

GraphicLine.propTypes = {
    data: PropTypes.array.isRequired,
    title: PropTypes.string,
    height: PropTypes.string
};
