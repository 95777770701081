import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    TextField,
    useMediaQuery,
    MenuItem,
    Select,
    Alert,
    Snackbar
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import Loading from 'components/Loading/Loading';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { getCourses, getClass, postQuiz, getQuizPerId, updateQuiz } from 'services/quiz';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const Quiz = ({ ...others }) => {
    const params = useParams();
    const themeButton = createTheme({
        status: {
            danger: '#e53e3e'
        },
        palette: {
            primary: {
                main: '#0971f1',
                darker: '#053e85'
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff'
            }
        }
    });
    const initialValuesEdit = {
        title: '',
        number: '',
        time: '',
        class: ''
    };
    const theme = useTheme();
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [errorValidate, setErrorValidate] = useState({});
    const [valuesEdit, setValuesEdit] = useState(initialValuesEdit);
    const titlePage =
        params.action === 'view'
            ? 'Visualização de Questionários'
            : params.action === 'edit'
            ? 'Edição de Questionários'
            : 'Cadastro de Questionários';
    const isDisabled = params.action === 'view' ? true : false;
    const [questionList, setQuestionList] = useState([{ question: '', answer: [] }]);
    const [answerList, setAnswerList] = useState([{ answer: '', feedback: '', correct: '', idQuestion: 0 }]);
    const [courses, setCourses] = useState('');
    const [classes, setClasses] = useState('');
    const [id, setId] = useState(params.id);

    useEffect(() => {
        getAllCourses();
    }, []);
    useEffect(() => {
        viewPerId();
    }, [id]);

    function viewPerId() {
        params.action && getQuizPerId(id).then((resp) => dataEdit(resp));
    }
    function dataEdit(resp) {
        setValuesEdit({
            title: resp.data.title,
            number: resp.data.number,
            time: resp.data.time,
            course: resp.data.id_course,
            class: resp.data.id_class,
            question: resp.data.question
        });
        let i = 0;
        var listAnswer = [];
        var listQuestion = [];
        resp.data.question.map((value) => {
            listQuestion.push({ question: value.question, answer: [] });
            value.answer.map((val) => {
                listAnswer.push({ answer: val.answer, feedback: val.feedback, correct: val.correct, idQuestion: i });
            });
            i++;
        });
        setAnswerList(listAnswer);
        setQuestionList(listQuestion);
    }

    function getAllCourses() {
        getCourses().then((resp) => setCourses(resp.data));
    }

    function getAllClasses(idCourse) {
        getClass(idCourse).then((resp) => setClasses(resp.data));
    }

    // handle question change
    const handleQuestionChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...questionList];
        list[index][name] = value;
        setQuestionList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...questionList];
        list.splice(index, 1);
        setQuestionList(list);
    };

    // handle click event of the Add button
    const handleAddClick = (index) => {
        setQuestionList([...questionList, { question: '', answer: [] }]);
        handleAddAnswerClick(index);
    };

    const handleAnswerChange = (e, index, i) => {
        const { name, value } = e.target;
        const list = [...answerList];
        list[index][name] = value;
        list[index]['idQuestion'] = i;
        setAnswerList(list);
    };

    const handleRemoveAnswerClick = (index) => {
        const list = [...answerList];
        list.splice(index, 1);
        setAnswerList(list);
    };

    // handle click event of the Add button
    const handleAddAnswerClick = (index) => {
        setAnswerList([...answerList, { answer: '', feedback: '', correct: '', idQuestion: index }]);
    };
    if (valuesEdit.course) {
        getAllClasses(valuesEdit.course);
    }
    return (
        <>
            <Formik
                initialValues={{
                    title: valuesEdit.title,
                    number: valuesEdit.number,
                    time: valuesEdit.time,
                    course: valuesEdit.course,
                    class: valuesEdit.class,
                    submit: null
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    title: Yup.string().required('Título do questionário obrigatório'),
                    number: Yup.number().required('Número do questionário obrigatório'),
                    time: Yup.number().required('Tempo estimado obrigatório'),
                    course: Yup.number().required('Courso obrigatório').min(1, 'Necessário escolher um curso')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                        setLoading(true);
                        for (let i = 0; i < answerList.length; i++) {
                            for (let j = 0; j < questionList.length; j++) {
                                if (answerList[i].idQuestion === j) {
                                    if (answerList[i].feedback === '') {
                                        answerList[i].feedback = 'null';
                                    }
                                    questionList[j].answer.push(answerList[i]);
                                }
                            }
                        }
                        const data = {
                            title: values.title,
                            number: values.number,
                            time: values.time,
                            idClass: values.class,
                            question: questionList
                        };
                        if (params.action === 'edit') {
                            updateQuiz(params.id, data)
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    viewPerId();
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        setSuccess('');
                                        navigate('/questionario');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    setErrorValidate(e.response.data.errorValidate);
                                    setError(e.response.data.error);
                                });
                        } else {
                            postQuiz(data)
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        setSuccess('');
                                        navigate('/questionario');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    setErrorValidate(e.response.data.errorValidate);
                                    setError(e.response.data.error);
                                });
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <>
                        {loading && (
                            <Grid container alignItems="center" justifyContent="center">
                                <MainCard style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Loading color="#015641" type="cubes" />
                                </MainCard>
                            </Grid>
                        )}
                        <MainCard spacing={gridSpacing} style={{ padding: 15, margin: 25 }}>
                            {error || alert ? (
                                <Snackbar open={true} autoHideDuration={6000}>
                                    <Alert
                                        severity={error ? 'error' : success ? 'success' : ''}
                                        sx={{
                                            width: '100%',
                                            backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                            color: '#FFF'
                                        }}
                                    >
                                        {error ? error : success ? success : ''}
                                    </Alert>
                                </Snackbar>
                            ) : (
                                ''
                            )}
                            <div style={{ display: loading ? 'none' : 'block' }}>
                                <form noValidate onSubmit={handleSubmit} {...others}>
                                    <h3>{titlePage}</h3>
                                    <hr></hr>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.title && errors.title) || Boolean(errorValidate.title)}
                                                id="outlined-title"
                                                type="text"
                                                label="Título"
                                                value={values.title}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="title"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.title && errors.title
                                                        ? errors.title
                                                        : errorValidate.title
                                                        ? errorValidate.title
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.number && errors.number) || Boolean(errorValidate.number)}
                                                id="outlined-title"
                                                type="number"
                                                label="Número"
                                                value={values.number}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="number"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.number && errors.number
                                                        ? errors.number
                                                        : errorValidate.number
                                                        ? errorValidate.number
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.time && errors.time) || Boolean(errorValidate.time)}
                                                id="outlined-title"
                                                type="number"
                                                label="Tempo estimado (minutos)"
                                                value={values.time}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="time"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.time && errors.time ? errors.time : errorValidate.time ? errorValidate.time : ''
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                            <FormControl
                                                sx={{ width: '100%' }}
                                                error={Boolean(touched.course && errors.course) || Boolean(errorValidate.course)}
                                            >
                                                <InputLabel id="course">Curso</InputLabel>
                                                <Select
                                                    fullWidth
                                                    error={Boolean(touched.course && errors.course)}
                                                    label="Curso"
                                                    id="course"
                                                    type="text"
                                                    value={values.course || ''}
                                                    name="course"
                                                    onBlur={handleBlur}
                                                    onChange={function idCourse(e) {
                                                        setFieldValue('course', e.target.value), getAllClasses(e.target.value);
                                                    }}
                                                    disabled={isDisabled}
                                                    helperText={
                                                        touched.course && errors.course
                                                            ? errors.course
                                                            : errorValidate.course
                                                            ? errorValidate.course
                                                            : ''
                                                    }
                                                >
                                                    {courses !== '' &&
                                                        courses.map((course) => (
                                                            <MenuItem key={course.id} value={course.id}>
                                                                {course.name}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                            <FormControl
                                                sx={{ width: '100%' }}
                                                error={Boolean(touched.class && errors.class) || Boolean(errorValidate.class)}
                                            >
                                                <InputLabel id="class">Aula</InputLabel>
                                                <Select
                                                    fullWidth
                                                    error={Boolean(touched.course && errors.course)}
                                                    select
                                                    label="Aula"
                                                    id="class"
                                                    type="text"
                                                    value={values.class || ''}
                                                    name="class"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    disabled={isDisabled}
                                                    helperText={
                                                        touched.class && errors.class
                                                            ? errors.class
                                                            : errorValidate.class
                                                            ? errorValidate.class
                                                            : ''
                                                    }
                                                >
                                                    {classes !== '' &&
                                                        classes.map((value) => (
                                                            <MenuItem key={value.id} value={value.id}>
                                                                {value.title}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {questionList &&
                                        questionList.map((x, i) => {
                                            return (
                                                <>
                                                    <Grid container spacing={matchDownSM ? 0 : 0} key={i} border="1px">
                                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                                            <TextField
                                                                fullWidth
                                                                error={
                                                                    Boolean(touched.tiquestionme && errors.question) ||
                                                                    Boolean(errorValidate.question)
                                                                }
                                                                id="outlined-title"
                                                                type="text"
                                                                label="Pergunta"
                                                                value={x.question}
                                                                onChange={(e) => handleQuestionChange(e, i)}
                                                                onBlur={handleBlur}
                                                                name="question"
                                                                disabled={isDisabled}
                                                                helperText={
                                                                    touched.question && errors.question
                                                                        ? errors.question
                                                                        : errorValidate.question
                                                                        ? errorValidate.question
                                                                        : ''
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    {answerList &&
                                                        answerList.map(
                                                            (z, j) =>
                                                                z.idQuestion == i && (
                                                                    <Grid container spacing={matchDownSM ? 0 : 2} key={j}>
                                                                        <Grid item xs={12} sm={4} sx={{ marginTop: 3 }}>
                                                                            <TextField
                                                                                fullWidth
                                                                                error={
                                                                                    Boolean(touched.tiquestionme && errors.answer) ||
                                                                                    Boolean(errorValidate.answer)
                                                                                }
                                                                                id="outlined-title"
                                                                                type="text"
                                                                                label="Resposta"
                                                                                value={z.answer}
                                                                                onChange={(e) => handleAnswerChange(e, j, i)}
                                                                                onBlur={handleBlur}
                                                                                name="answer"
                                                                                disabled={isDisabled}
                                                                                helperText={
                                                                                    touched.answer && errors.answer
                                                                                        ? errors.answer
                                                                                        : errorValidate.answer
                                                                                        ? errorValidate.answer
                                                                                        : ''
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={3} sx={{ marginTop: 3 }}>
                                                                            <TextField
                                                                                fullWidth
                                                                                error={
                                                                                    Boolean(touched.tiquestionme && errors.feedback) ||
                                                                                    Boolean(errorValidate.feedback)
                                                                                }
                                                                                id="outlined-title"
                                                                                type="text"
                                                                                label="Feedback"
                                                                                value={z.feedback}
                                                                                onChange={(e) => handleAnswerChange(e, j, i)}
                                                                                onBlur={handleBlur}
                                                                                name="feedback"
                                                                                disabled={isDisabled}
                                                                                helperText={
                                                                                    touched.feedback && errors.feedback
                                                                                        ? errors.feedback
                                                                                        : errorValidate.feedback
                                                                                        ? errorValidate.feedback
                                                                                        : ''
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={3} sx={{ marginTop: 4 }}>
                                                                            <RadioGroup
                                                                                row
                                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                                name="correct"
                                                                                value={z.correct}
                                                                            >
                                                                                <FormControlLabel
                                                                                    disabled={isDisabled}
                                                                                    value={1}
                                                                                    control={<Radio />}
                                                                                    label="Correta"
                                                                                    onChange={(e) => handleAnswerChange(e, j, i)}
                                                                                />
                                                                                <FormControlLabel
                                                                                    disabled={isDisabled}
                                                                                    value={0}
                                                                                    control={<Radio />}
                                                                                    label="Errada"
                                                                                    onChange={(e) => handleAnswerChange(e, j, i)}
                                                                                />
                                                                            </RadioGroup>
                                                                        </Grid>
                                                                        {params.action !== 'view' && (
                                                                            <Grid item xs={12} sm={2} sx={{ marginTop: 4 }}>
                                                                                {answerList.length - 1 === j && (
                                                                                    <Button onClick={(e) => handleAddAnswerClick(i)}>
                                                                                        <AddCircleIcon />
                                                                                    </Button>
                                                                                )}
                                                                                <Button onClick={() => handleRemoveAnswerClick(j)}>
                                                                                    <DeleteOutlineOutlinedIcon />
                                                                                </Button>
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>
                                                                )
                                                        )}
                                                    {params.action !== 'view' && (
                                                        <Grid container alignItems="end" justifyContent="end" sx={{ mt: 3 }}>
                                                            {questionList.length - 1 === i && (
                                                                <Grid item>
                                                                    <Box sx={{ mt: 2, mr: 3 }}>
                                                                        <ThemeProvider theme={themeButton}>
                                                                            <AnimateButton>
                                                                                <Button
                                                                                    disableElevation
                                                                                    disabled={isSubmitting}
                                                                                    fullWidth
                                                                                    size="large"
                                                                                    type="button"
                                                                                    variant="contained"
                                                                                    color="neutral"
                                                                                    onClick={() => handleAddClick(i + 1)}
                                                                                >
                                                                                    Adicionar pergunta
                                                                                </Button>
                                                                            </AnimateButton>
                                                                        </ThemeProvider>
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                            {questionList.length != 1 && (
                                                                <Grid item>
                                                                    <Box sx={{ mt: 2, mr: 3 }}>
                                                                        <ThemeProvider theme={themeButton}>
                                                                            <AnimateButton>
                                                                                <Button
                                                                                    disableElevation
                                                                                    disabled={isSubmitting}
                                                                                    fullWidth
                                                                                    size="large"
                                                                                    type="button"
                                                                                    variant="contained"
                                                                                    color="neutral"
                                                                                    onClick={() => handleRemoveClick(i)}
                                                                                >
                                                                                    Remover pergunta
                                                                                </Button>
                                                                            </AnimateButton>
                                                                        </ThemeProvider>
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    )}
                                                </>
                                            );
                                        })}
                                    <Grid container alignItems="end" justifyContent="end" sx={{ mt: 3 }}>
                                        <Grid item>
                                            <Box sx={{ mt: 2, mr: 3 }}>
                                                <ThemeProvider theme={themeButton}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="button"
                                                            variant="contained"
                                                            color="neutral"
                                                            onClick={() => navigate(-1)}
                                                        >
                                                            Voltar
                                                        </Button>
                                                    </AnimateButton>
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                        {params.action === 'view' ? (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            // disabled={isSubmitting}
                                                            component={Link}
                                                            to={`/questionario/${params.id}/edit`}
                                                            fullWidth
                                                            size="large"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Editar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        ) : (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Salvar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        )}
                                        ,
                                    </Grid>
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                </form>
                            </div>
                        </MainCard>
                    </>
                )}
            </Formik>
        </>
    );
};

export default Quiz;
