import styled from 'styled-components';
import Button from '@mui/material/Button';

export const HeaderStyled = styled.header`
    height: 21%;
    display: flex;
    margin-top: 3rem;
    align-items: end;
    justify-content: start;
    border-bottom: 1px solid black;
    margin-bottom: 2rem;
`;
export const ButtonMenu = styled(Button)`
    background-color: #fff;
    height: 50px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #616161;
    width: 200px;
    margin-left: 4px;
    &:hover {
        background-color: #3a6647 !important;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        color: #fff !important;
    }
`;
