export const initialState = {
    page: 0,
    rowsPerPage: 10,
    idPublication: 0
};

const publicationReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_FILTER_TYPE':
            return {
                ...state,
                idPublication: actions.payload
            };
        case 'SET_FILTER_CLEAR':
            return {
                ...state,
                idPublication: {}
            };
        case 'SET_PAGE_PUBLICATION':
            return {
                ...state,
                page: actions.payload
            };
        case 'SET_ROWS_PER_PAGE_PUBLICATION':
            return {
                ...state,
                rowsPerPage: actions.payload
            };
        case 'SET_IDPUBLICATION_PUBLICATION_FILTER':
            return {
                ...state,
                idPublication: actions.payload
            };
        case 'SET_CLEAR_PUBLICATION_FILTER':
            return {
                ...state,
                idPublication: ''
            };
        default:
            return state;
    }
};

export default publicationReducer;
