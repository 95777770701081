import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AnimateButton from 'ui-component/extended/AnimateButton';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { TablePaginationActions } from '../../../components/Pagination/TablePaginationActions';
import { useDispatch, useSelector } from 'react-redux';
import FilterIcon from '@mui/icons-material/FilterAlt';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import BasicModal from '../../../components/Modal/BasicModal';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LevelIcon from '@mui/icons-material/AutoGraph';
import { MENU_OPEN } from 'store/actions';
import ModalFilter from 'components/Modal/ModalFilter';
import { deleteLevel, getLevels } from 'services/level';
import AddButton from 'components/Buttons/AddButton';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#015641',
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));
export default function GridLevel() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const page = useSelector((state) => state.level.page);
    const name = useSelector((state) => state.level.name);
    const rowsPerPage = useSelector((state) => state.level.rowsPerPage);
    const [levels, setLevels] = React.useState([]);
    const [success, setSuccess] = React.useState('');
    const [error, setError] = React.useState('');
    const [idDestroyLevel, setIdDestroyLevel] = React.useState('');
    const [openModal, setOpenModal] = React.useState(false);

    const handleOpenDestroy = (idDestroy) => {
        setIdDestroyLevel(idDestroy);
        setOpenModal(true);
    };

    const handleCloseModal = () => setOpenModal(false);
    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - farms.total) : 0;
    const handleChangePage = (event, newPage) => {
        dispatch({ type: 'SET_PAGE_LEVEL', payload: newPage });
    };
    const handleChangeRowsPerPage = (event) => {
        dispatch({ type: 'SET_ROWS_PER_PAGE_LEVEL', payload: parseInt(event.target.value, 10) });
        dispatch({ type: 'SET_PAGE_LEVEL', payload: 0 });
    };

    function getAllLevels(nameAttr) {
        let nameLevel = nameAttr === '' ? nameAttr : name;
        getLevels(page + 1, rowsPerPage, nameLevel).then((resp) => setLevels(resp.data));
    }

    const deleteLevelById = () => {
        handleCloseModal();
        deleteLevel(idDestroyLevel)
            .then((resp) => {
                getAllLevels();
                setError('');
                setSuccess(resp.data.success);
                setIdDestroyLevel('');
                setTimeout(() => {
                    setSuccess('');
                }, 3000);
            })
            .catch((e) => {
                console.log(e);
                setSuccess('');
                setError(e.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    };

    React.useEffect(() => {
        getAllLevels();
    }, [page, rowsPerPage]);

    React.useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'niveis');
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: 'niveis' });
        }
    }, []);

    return (
        <>
            <BasicModal
                open={openModal}
                title="Excluir Nível"
                handleClose={handleCloseModal}
                description="Tem certeza que deseja excluir o nível ?"
                onDelete={deleteLevelById}
            />
            <TableContainer sx={{ mt: 2, height: '100%', padding: 3 }} component={Paper}>
                <h1
                    style={{
                        font: 'normal normal bold 35px/44px Roboto, sans-serif',
                        letterSpacing: '0px',
                        color: 'var(--unnamed-color-015641)',
                        Color: '#015641',
                        opacity: 1,
                        padding: 15,
                        marginLeft: '2%'
                    }}
                >
                    <LevelIcon /> NÍVEIS
                </h1>
                <hr style={{ width: '95%', marginTop: 0 }}></hr>
                <h3
                    style={{
                        font: 'normal normal 300 18px/22px Roboto, sans-serif',
                        letterSpacing: '0px',
                        Color: '#00000',
                        opacity: 1,
                        padding: 15,
                        marginLeft: '2%'
                    }}
                >
                    Gerencie seus níveis
                </h3>
                {error || success ? (
                    <Snackbar open={true} autoHideDuration={6000}>
                        <Alert
                            severity={error ? 'error' : success ? 'success' : ''}
                            sx={{
                                width: '100%',
                                backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                color: '#FFF'
                            }}
                        >
                            {error ? error : success ? success : ''}
                        </Alert>
                    </Snackbar>
                ) : (
                    ''
                )}
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Nome</StyledTableCell>
                            <StyledTableCell>Início</StyledTableCell>
                            <StyledTableCell>Fim</StyledTableCell>
                            <StyledTableCell>Ações</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {levels.data &&
                            levels.data.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell>{row.name}</StyledTableCell>
                                    <StyledTableCell>{row.start}</StyledTableCell>
                                    <StyledTableCell>{row.end}</StyledTableCell>
                                    <Tooltip title="Visualizar">
                                        <IconButton onClick={() => navigate({ pathname: `/nivel/${row.id}/view` })}>
                                            <ViewIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Editar">
                                        <IconButton onClick={() => navigate({ pathname: `/nivel/${row.id}/edit` })}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Excluir">
                                        <IconButton onClick={() => handleOpenDestroy(row.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </StyledTableRow>
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                                colSpan={3}
                                count={levels.total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'Registros por Página'
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <AddButton href="/novo_nivel" />
        </>
    );
}
