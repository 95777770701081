import { Graphic, GridFilter, GridMainContainer, LoadingContainer, Footer } from './styles';

import GraphicBar from '../graphicBar/index';
import FilterDates from '../filterDates';
import { getFavoriteBI } from 'services/bi';
import { useEffect, useState } from 'react';
import FilterList from '../filterList';
import { CircularProgress, Grid } from '@mui/material';
import { GraphicLine } from '../graphicLine';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pt-br';
import Button from '../buttons';
import ArticleIcon from '@mui/icons-material/Article';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const BIFavorites = () => {
    const [loadingPage, setLoadingPage] = useState(true);
    const [allDataTotal, setAllDataTotal] = useState([]);
    const [allData, setAllData] = useState([]);
    const [allCourse, setAllCourse] = useState([]);
    const [allVideo, setAllVideo] = useState([]);
    const [allText, setAllText] = useState([]);
    const [allImage, setAllImage] = useState([]);
    const [allPdf, setAllPdf] = useState([]);
    const [allExcel, setAllExcel] = useState([]);
    const [filters, setFilters] = useState([]);
    const [allGeneralData, setAllGeneralData] = useState([]);
    const initialDate = useSelector((state) => state.bi.initialDate);
    const competenceFilter = useSelector((state) => state.bi.competenceFilter);
    const resourceFilter = useSelector((state) => state.bi.typeResourceFilter);

    const getData = async () => {
        await getFavoriteBI()
            .then((response) => {
                setFilters(response.filter);
                return response;
            })
            .then((response) => {
                setAllGeneralData(response.content);
                setAllDataTotal(response.countsObject);
                setLoadingPage(false);
            });
    };

    function formatData(countsObject, filterDate, ids) {
        const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
        const idTypes = {
            1: 'video',
            2: 'text',
            3: 'image',
            4: 'course',
            5: 'event',
            6: 'audio',
            7: 'pdf',
            8: 'excel'
        };

        const filteredCountsObject = {};
        ids.forEach((id) => {
            const type = idTypes[id];
            if (type in countsObject) {
                filteredCountsObject[type] = countsObject[type];
            }
        });

        const types = Object.keys(filteredCountsObject);

        const currentMonth = new Date().getMonth();
        const currentMonthName = months[currentMonth];
        const allMonths = [...months.slice(currentMonth + 1), ...months.slice(0, currentMonth + 1)];
        const data = [
            ['Mês', ...types],
            ...allMonths.map((month) => [
                month,
                ...types.map(
                    (type) =>
                        filteredCountsObject[type][`2022-${months.indexOf(month) + 1 < 10 ? '0' : ''}${months.indexOf(month) + 1}`] || 0
                )
            ])
        ];

        if (filterDate) {
            const monthIndex = months.indexOf(filterDate.slice(5));
            let dataFiltered = [
                ['Mês', ...types],
                [months[monthIndex], ...types.map((type) => filteredCountsObject[type][filterDate] || 0)]
            ];
            return setAllData(dataFiltered);
        }

        return setAllData(data);
    }

    function processData(data, initialDate, competence = []) {
        const video = [['Título', 'Quantidade']];
        const text = [['Título', 'Quantidade']];
        const image = [['Título', 'Quantidade']];
        const pdf = [['Título', 'Quantidade']];
        const excel = [['Título', 'Quantidade']];
        const course = [['Título', 'Quantidade']];

        data.forEach(function (obj) {
            const courseName = obj.course_name || obj.library_title;
            const idType = obj.id_type;
            if (initialDate) {
                var total = 0;
                const value = obj.metrics.favorite.find(function (value) {
                    return value.date === initialDate;
                });
                total = value ? value.value : 0;
            } else {
                var total = obj.metrics.favorite.find((metric) => metric.hasOwnProperty('total')).total;
            }
            if (competence) {
                if (obj.competences.some((c) => competence.includes(c.id_competence))) {
                    switch (idType) {
                        case 1:
                            video.push([courseName, total]);
                            break;
                        case 2:
                            text.push([courseName, total]);
                            break;
                        case 3:
                            image.push([courseName, total]);
                            break;
                        case 7:
                            pdf.push([courseName, total]);
                            break;
                        case 8:
                            excel.push([courseName, total]);
                            break;
                        default:
                            break;
                    }
                    if (obj.id_course) {
                        course.push([courseName, total]);
                    }
                }
            } else {
                switch (idType) {
                    case 1:
                        video.push([courseName, total]);
                        break;
                    case 2:
                        text.push([courseName, total]);
                        break;
                    case 3:
                        image.push([courseName, total]);
                        break;
                    case 7:
                        pdf.push([courseName, total]);
                        break;
                    case 8:
                        excel.push([courseName, total]);
                        break;
                    default:
                        break;
                }
                if (obj.id_course) {
                    course.push([courseName, total]);
                }
            }
        });

        setAllCourse(course);
        setAllVideo(video);
        setAllText(text);
        setAllImage(image);
        setAllPdf(pdf);
        setAllExcel(excel);
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        formatData(allDataTotal, initialDate, resourceFilter);
    }, [allDataTotal, initialDate, resourceFilter]);

    useEffect(() => {
        processData(allGeneralData, initialDate, competenceFilter);
    }, [allGeneralData, initialDate, competenceFilter]);

    if (loadingPage)
        return (
            <LoadingContainer>
                <CircularProgress />
            </LoadingContainer>
        );
    return (
        <GridMainContainer container spacing={2}>
            <Grid item xs={9}>
                <GridMainContainer container spacing={2}>
                    <Grid item xs={12}>
                        <Graphic>
                            <h2>Quantidade de Recursos Favoritos</h2>
                            <GraphicLine data={allData} />
                        </Graphic>
                    </Grid>
                    {allCourse.length > 1 && resourceFilter !== null && resourceFilter.includes(4) && (
                        <Grid item xs={12} md={6}>
                            <Graphic>
                                <h2>Cursos</h2>
                                <GraphicBar
                                    allData={allCourse}
                                    width={'450px'}
                                    options={{ title: 'Cursos', colors: ['#ED9F4A'], legend: { position: 'none' } }}
                                />
                            </Graphic>
                        </Grid>
                    )}
                    {allImage.length > 1 && resourceFilter !== null && resourceFilter.includes(3) && (
                        <Grid item xs={12} md={6}>
                            <Graphic>
                                <h2>Imagens</h2>
                                <GraphicBar
                                    allData={allImage}
                                    width={'450px'}
                                    options={{ colors: ['#73A4F7'], legend: { position: 'none' } }}
                                />
                            </Graphic>
                        </Grid>
                    )}
                    {allText.length > 1 && resourceFilter !== null && resourceFilter.includes(2) && (
                        <Grid item xs={12} md={6}>
                            <Graphic>
                                <h2>Textos</h2>
                                <GraphicBar
                                    allData={allText}
                                    width={'450px'}
                                    options={{ colors: ['#8144A7'], legend: { position: 'none' } }}
                                />
                            </Graphic>
                        </Grid>
                    )}
                    {allVideo.length > 1 && resourceFilter !== null && resourceFilter.includes(1) && (
                        <Grid item xs={12} md={6}>
                            <Graphic>
                                <h2>Vídeos</h2>
                                <GraphicBar
                                    allData={allVideo}
                                    width={'700px'}
                                    options={{ colors: ['#DE71CD'], legend: { position: 'none' } }}
                                />
                            </Graphic>
                        </Grid>
                    )}
                    {allPdf.length > 1 && resourceFilter !== null && resourceFilter.includes(7) && (
                        <Grid item xs={12} md={6}>
                            <Graphic>
                                <h2>PDF</h2>
                                <GraphicBar
                                    allData={allPdf}
                                    width={'450px'}
                                    options={{ colors: ['#73A4F7'], legend: { position: 'none' } }}
                                />
                            </Graphic>
                        </Grid>
                    )}
                    {allExcel.length > 1 && resourceFilter !== null && resourceFilter.includes(8) && (
                        <Grid item xs={12} md={6}>
                            <Graphic>
                                <h2>Excel</h2>
                                <GraphicBar
                                    allData={allExcel}
                                    width={'450px'}
                                    options={{ colors: ['#51A744'], legend: { position: 'none' } }}
                                />
                            </Graphic>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Footer>
                            <Button startIcon={<ArticleIcon />} text="Exportar Excel" marginRight="20px" onClick={() => alert('teste')} />
                            <Button startIcon={<PictureAsPdfIcon />} text="Exportar PDF" onClick={() => alert('teste')} />
                        </Footer>
                    </Grid>
                </GridMainContainer>
            </Grid>
            <GridFilter item xs={2}>
                <FilterDates />
                <FilterList competences={filters?.competence} originFilter="competence" title="Por Competência" />
                <FilterList competences={filters?.typeResource} originFilter="resource" title="Por Recurso" />
            </GridFilter>
            {/* <GridMain item xs={4.4}>
                <GraphicBar />
            </GridMain>
            <GridMain item xs={4.3}>
                <GraphicBar />
            </GridMain> */}
        </GridMainContainer>
    );
};

export default BIFavorites;
