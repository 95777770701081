import { app } from './Api';

const getResourcerClass = async () => {
    return app.get(`get_resource_class`);
};
const postClass = async (data) => {
    return app.post('class', data);
};
const updateClass = async (id, data) => {
    return app.post(`class/${id}`, data);
};
const getClassPerId = async (id) => {
    return app.get(`class/${id}`);
};
const getClass = async () => {
    return app.get(`class`);
};
const deleteClasse = async (id) => {
    return app.delete(`class/${id}`);
};
const getClasses = async (page, qtdPerPage, title, dateClass, nameCourse) => {
    return app.get(
        `class?page=${page}&qtdPerPage=${qtdPerPage}&title=${title || ''}&date=${dateClass || ''}&nameCourse=${nameCourse || ''}`
    );
};
const searchClasse = async (title, dateClass, nameCourse) => {
    return app.get(`class?title=${title || ''}&date=${dateClass || ''}&nameCourse=${nameCourse || ''}`);
};
const deleteLearningObject = async (id) => {
    return app.delete(`learning_object/${id}`);
};
export { getResourcerClass, postClass, updateClass, getClassPerId, getClass, deleteClasse, getClasses, deleteLearningObject, searchClasse };
