import { GridMain, GridFilter, GridMainContainer } from './styles';

import GraphicBar from '../graphicBar/index';
import { GraphicArea } from '../graphicArea';
import FilterDates from '../filterDates';
import { getCourses } from 'services/bi';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterList from '../filterList';

const BICourses = () => {
    const [allData, setAllData] = useState([]);
    const [courseFiltered, setCourseFiltered] = useState(['']);
    const [likesFiltered, setLikesFiltered] = useState(['']);
    const [filter, setFilter] = useState([]);
    const dispatch = useDispatch();
    const filtered = useSelector((state) => state.bi.filtered);
    const initialDate = useSelector((state) => state.bi.initialDate);
    const competencesSelect = useSelector((state) => state.bi.competenceFilter);
    const coursesSelect = useSelector((state) => state.bi.courseFilter);
    const speakersSelect = useSelector((state) => state.bi.speakerFilter);
    const competencesFilter = [];
    const courseNameFilter = [];
    const speakerFilter = [];

    const getAllCourses = async () => {
        await getCourses()
            .then((response) => {
                setAllData(response.content);
                setFilter(response.filter);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    async function filterCourse(allCourses) {
        var participationInCourses = [['Nome', 'Concluídos', 'Inscritos', 'Em andamento']];
        var likesInCourses = [['Nome', 'Likes']];
        var competenceValid = null;
        var courseValid = null;
        var speakerValid = null;
        allCourses?.map((value) => {
            if (coursesSelect != null) {
                if (coursesSelect.includes(value.id)) {
                    courseValid = true;
                } else {
                    courseValid = false;
                }
            }

            if (courseValid || courseValid == null) {
                if (speakersSelect != null) {
                    value.speakers.filter(function (item) {
                        if (speakersSelect.includes(item.id_speaker)) {
                            speakerValid = true;
                        } else {
                            speakerValid = false;
                        }
                    });
                }
                if (competencesSelect != null) {
                    value.competences.filter(function (item) {
                        if (competencesSelect.includes(item.id_competence)) {
                            competenceValid = true;
                        } else {
                            competenceValid = false;
                        }
                    });
                }

                if (speakerValid || speakerValid == null) {
                    if (competenceValid || competencesSelect == null) {
                        let completed = value.metrics.completed;
                        let registered = value.metrics.registered;
                        let inProgress = value.metrics.inProgress;
                        let likes = value.metrics.like;

                        if (Array.isArray(completed)) {
                            completed.map((compl) => {
                                if (compl.date == initialDate) {
                                    value.metrics.completed = compl;
                                }
                            });
                        }
                        if (Array.isArray(registered)) {
                            registered.map((regis) => {
                                if (regis.date == initialDate) {
                                    value.metrics.registered = regis;
                                }
                            });
                        }
                        if (Array.isArray(inProgress)) {
                            inProgress.map((inPro) => {
                                if (inPro.date == initialDate) {
                                    value.metrics.inProgress = inPro;
                                }
                            });
                        }
                        if (Array.isArray(likes)) {
                            likes.map((like) => {
                                if (like.date == initialDate) {
                                    value.metrics.like = like;
                                }
                            });
                        }

                        if (initialDate == null) {
                            participationInCourses.push([
                                value.name.substr(0, 15),
                                value.metrics.completed.total,
                                value.metrics.registered.total,
                                value.metrics.inProgress.total
                            ]);
                            likesInCourses.push([value.name.substr(0, 15), value.metrics.like.total]);
                        } else {
                            participationInCourses.push([
                                value.name.substr(0, 15),
                                value.metrics.completed.value,
                                value.metrics.registered.value,
                                value.metrics.inProgress.value
                            ]);
                            likesInCourses.push([value.name.substr(0, 15), value.metrics.like.value]);
                        }
                    }
                }
            }
        });
        setCourseFiltered(participationInCourses);
        setLikesFiltered(likesInCourses);
        getAllCourses();
    }
    filter?.competence?.map((value) => {
        competencesFilter.push(value);
    });
    filter?.speaker?.map((value) => {
        speakerFilter.push(value);
    });
    allData?.map((value) => {
        courseNameFilter.push(value.name);
    });

    useEffect(() => {
        getAllCourses();
    }, []);

    useEffect(() => {
        const allCourses = allData;
        filterCourse(allCourses);
    }, [filtered]);

    useEffect(() => {
        setTimeout(() => {
            dispatch({ type: 'SET_FILTERED', payload: !filtered });
        }, 5000);
    }, []);

    return (
        <GridMainContainer container>
            <GridMain item xs={9}>
                <GraphicBar allData={courseFiltered} />
            </GridMain>
            <GridFilter item xs={2}>
                <FilterDates />
                <FilterList competences={competencesFilter} originFilter="competence" title="Por Competência" />
            </GridFilter>
            <GridMain item xs={9}>
                <GraphicArea allData={likesFiltered} />
            </GridMain>
            <GridFilter item xs={2}>
                <FilterList competences={allData} originFilter="course" title="Por Curso" />
                <FilterList competences={speakerFilter} originFilter="speaker" title="Por Professor" />
            </GridFilter>
            {/* <GridMain item xs={4.4}>
                <GraphicBar />
            </GridMain>
            <GridMain item xs={4.3}>
                <GraphicBar />
            </GridMain> */}
        </GridMainContainer>
    );
};

export default BICourses;
