import { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    Grid,
    TextField,
    OutlinedInput,
    useMediaQuery,
    MenuItem,
    Select,
    InputLabel,
    Alert,
    Snackbar
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import Loading from 'components/Loading/Loading';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import { postPublication, getSkill, getCompetence, getPublicationPerId, updatePublication } from 'services/publication';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import moment from 'moment';
import SchedulingFormInput from 'components/Forms/SchedulingFormInput';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const Library = ({ ...others }) => {
    const params = useParams();
    const themeButton = createTheme({
        status: {
            danger: '#e53e3e'
        },
        palette: {
            primary: {
                main: '#0971f1',
                darker: '#053e85'
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff'
            }
        }
    });
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
    };
    const opacity = {
        visibility: 'hidden'
    };
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 58;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };
    const Demo = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.background.paper
    }));
    const initialStateSkills = {
        skills: []
    };
    const initialStateCompetence = {
        competence: []
    };
    const initialValuesEdit = {
        idType: '',
        description: EditorState.createEmpty(),
        competence: [],
        skill: [],
        file: '',
        schedulingPost: ''
    };
    const theme = useTheme();
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [skills, setSkills] = useState(initialStateSkills);
    const [competences, setCompetences] = useState(initialStateCompetence);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [showAddObject, setShowAddObject] = useState(false);
    const [errorUrl, setErrorUrl] = useState([]);
    const [typeObject, setTypeObject] = useState('');
    const [dense, setDense] = useState(false);
    const [errorValidate, setErrorValidate] = useState({});
    const [disableButton, setDisableButton] = useState(false);
    const [valuesEdit, setValuesEdit] = useState(initialValuesEdit);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [inputFields, setInputFields] = useState([
        {
            url: [],
            file: [],
            type: []
        }
    ]);
    const [validVimeo, setValidVimeo] = useState(false);
    const titlePage =
        params.action === 'view' ? 'Visualização de Postagem' : params.action === 'edit' ? 'Edição de Postagem' : 'Cadastro de Postagem';
    const isDisabled = params.action === 'view' ? true : false;
    function viewPerId() {
        params.action &&
            getPublicationPerId(params.id).then((resp) => {
                setValuesEdit({
                    ['idType']: resp.data.idType,
                    ['description']: EditorState.createWithContent(
                        ContentState.createFromBlockArray(convertFromHTML(`${resp.data.description}`))
                    ),
                    ['competence']: resp.data.competence.map((res) => res.desription),
                    ['skill']: resp.data.skill.map((res) => res.name),
                    ['file']: resp.data.file,
                    ['schedulingPost']: resp.data.schedulingPost
                });
            });
    }

    function getResource() {
        getSkill().then((resp) => setSkills(resp.data.data));
        getCompetence().then((resp) => setCompetences(resp.data));
    }

    function AddObject() {
        setShowAddObject(true);
    }

    function optionObject(event) {
        setTypeObject(event.target.value);
        const list = [...inputFields];
        const value = null;
        const name = 'url';
        list[0][name] = value;
        setInputFields(list);
    }

    const handleChangeVimeo = (index) => (evt) => {
        const list = [...inputFields];
        list[index]['vimeoUrl'] = evt.target.value;
        setInputFields(list);
        if (/^(http\:\/\/|https\:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/.test(evt.target.value)) {
            setValidVimeo(true);
        } else {
            setValidVimeo(false);
        }
    };

    function handleChangeInput(index, evnt, typeObject) {
        const { name, value } = evnt.target;
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
    }

    function handleChangeFile(index, evnt) {
        const maxFileSize = 50; // Megabytes
        const value = evnt;
        const name = 'file';
        const list = [...inputFields];
        if (value) {
            if (value.size / 1000000 <= maxFileSize) {
                list[index][name] = value;
                setInputFields(list);
                handleUpload(value);
            } else {
                setError(`O arquivo deve ser menor ou igual a ${maxFileSize}MB`);
            }
        }
    }

    function addInputField(index, typeObject) {
        setInputFields([
            ...inputFields,
            {
                url: [],
                file: [],
                type: []
            }
        ]);
    }

    function removeInputFields(index) {
        const rows = [...inputFields];
        const rowsUpload = [...uploadedFiles];
        rows.splice(index, 1);
        rowsUpload.splice(index, 1);
        setInputFields(rows);
        setUploadedFiles(rowsUpload);
    }

    function handleUpload(files) {
        const file = [files];
        const uploadedFile = file.map((file) => ({
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            progress: 0,
            uploaded: false,
            error: false,
            url: null
        }));
        setUploadedFiles(uploadedFiles.concat(uploadedFile));
    }

    function getIdsCompetence(valueCompetence) {
        let ids = [];
        valueCompetence.forEach((element) => {
            let valueId = competences.filter((desc) => desc.desription === element)[0];
            ids = [...ids, valueId];
        });
        return ids;
    }

    function getIdsSkills(valueSkills) {
        let ids = [];
        valueSkills.forEach((element) => {
            let valueId = skills.filter((desc) => desc.name === element)[0];
            ids = [...ids, valueId];
        });
        return ids;
    }

    useEffect(() => {
        getResource();
    }, []);

    useEffect(() => {
        viewPerId();
    }, [params.id]);

    return (
        <>
            <Formik
                initialValues={{
                    idType: valuesEdit.idType,
                    description: valuesEdit.description,
                    skill: valuesEdit.skill || [],
                    competence: valuesEdit.competence || [],
                    file: valuesEdit.file,
                    submit: null,
                    schedulingPost: valuesEdit.schedulingPost ? new Date(valuesEdit.schedulingPost) : null,
                    vimeoUrl: ''
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    skill: Yup.array().required('Habilidade é obrigatório').min(1, 'Necessário escolher habilidade'),
                    competence: Yup.array().required('Competência é obrigatório').min(1, 'Necessário escolher competência')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                        if (typeObject == 3) {
                            if (inputFields[0].file.name !== undefined) {
                                if (
                                    inputFields[0].file.name.includes('.png') ||
                                    inputFields[0].file.name.includes('.PNG') ||
                                    inputFields[0].file.name.includes('.jpeg') ||
                                    inputFields[0].file.name.includes('.JPEG') ||
                                    inputFields[0].file.name.includes('.jpg') ||
                                    inputFields[0].file.name.includes('.JPG') ||
                                    inputFields[0].file.name.includes('.TIFF') ||
                                    inputFields[0].file.name.includes('.tiff') ||
                                    inputFields[0].file.name.includes('.svg') ||
                                    inputFields[0].file.name.includes('.SVG')
                                ) {
                                    const errors = [...errorUrl];
                                    errors[0] = null;
                                    setErrorUrl(errors);
                                    setDisableButton(false);
                                } else {
                                    throw 'Insira um formato válido de imagem';
                                }
                            }
                        }
                        if (typeObject == 1) {
                            let index = Object.keys(inputFields);
                            index.map((value) => {
                                let name = inputFields[value].vimeoUrl ? inputFields[value].vimeoUrl : inputFields[value].file.name;
                                console.log(name);
                                if (!/^(http\:\/\/|https\:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/.test(name)) {
                                    throw 'A url deve ser do vimeo';
                                }
                            });
                        }
                        const skillsIds = getIdsSkills(values.skill).map((item) => item.id);
                        const competencesIds = getIdsCompetence(values.competence).map((item) => item.id);

                        const data = new FormData();
                        setLoading(true);
                        uploadedFiles.map((value) => data.append('file[]', value.file));
                        data.append('idType', 1);
                        data.append('description', `${draftToHtml(convertToRaw(values.description.getCurrentContent()))}`);
                        data.append('learningObject', JSON.stringify(inputFields));
                        data.append(`competence`, JSON.stringify(competencesIds));
                        data.append(`skill`, JSON.stringify(skillsIds));
                        data.append('id_type', typeObject);
                        if (values.schedulingPost) {
                            data.append('schedulingPost', moment(values.schedulingPost).format('YYYY-MM-DD HH:mm:ss'));
                        }
                        if (params.action === 'edit') {
                            data.append('_method', 'put');
                            updatePublication(params.id, data, {
                                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                            })
                                .then((resp) => {
                                    setError('');
                                    viewPerId();
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        setLoading(false);
                                        navigate('/publicacoes');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    if (e.response.data.errorValidate.file[0]) {
                                        setError(e.response.data.errorValidate.file[0]);
                                    }
                                    setError(e.response.data.error);
                                });
                        } else {
                            postPublication(data, {
                                headers: { 'content-type': 'application/json', 'Access-Control-Allow-Origin': '*' }
                            })
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        navigate('/publicacoes');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    setErrorValidate(e.response.data.errorValidate.file[0]);
                                    setError(e.response.data.error);
                                });
                        }
                    } catch (err) {
                        console.error(err);
                        setError(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <>
                        {loading && (
                            <Grid container alignItems="center" justifyContent="center">
                                <MainCard
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Loading color="#015641" type="spinningBubbles" />
                                </MainCard>
                            </Grid>
                        )}
                        <MainCard spacing={gridSpacing} style={{ padding: 15, margin: 25 }}>
                            {error || alert ? (
                                <Snackbar open={true} autoHideDuration={6000}>
                                    <Alert
                                        severity={error ? 'error' : success ? 'success' : ''}
                                        sx={{
                                            width: '100%',
                                            backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                            color: '#FFF'
                                        }}
                                    >
                                        {error ? error : success ? success : ''}
                                    </Alert>
                                </Snackbar>
                            ) : (
                                ''
                            )}
                            {errorValidate.file && (
                                <Snackbar open={true} autoHideDuration={6000}>
                                    <Alert
                                        severity={'error'}
                                        sx={{
                                            width: '100%',
                                            backgroundColor: 'red',
                                            color: '#FFF'
                                        }}
                                    >
                                        {errorValidate.file}
                                    </Alert>
                                </Snackbar>
                            )}

                            <div style={{ display: loading ? 'none' : 'block' }}>
                                <form noValidate onSubmit={handleSubmit} {...others}>
                                    <h3>{titlePage}</h3>
                                    <hr></hr>
                                    <Grid container spacing={matchDownSM ? 2 : 2}>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                            <FormControl
                                                sx={{ width: '100%' }}
                                                error={Boolean(touched.skill && errors.skill) || Boolean(errorValidate.skill)}
                                            >
                                                <InputLabel id="skills">Habilidades</InputLabel>
                                                <Select
                                                    labelId="skill"
                                                    onBlur={handleBlur}
                                                    multiple
                                                    name="skill"
                                                    id="skill"
                                                    value={values.skill}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            'skill',
                                                            typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                                                        )
                                                    }
                                                    input={<OutlinedInput label="Habilidades" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                    disabled={isDisabled}
                                                >
                                                    {skills.skills == undefined &&
                                                        skills.map((skill) => (
                                                            <MenuItem key={skill.id} value={skill.name}>
                                                                <Checkbox checked={values.skill.indexOf(skill.name) > -1} />
                                                                <ListItemText primary={skill.name} />
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                                {errorValidate.skill && (
                                                    <FormHelperText error id="standard-weight-helper-text--skill">
                                                        {errorValidate.skill}
                                                    </FormHelperText>
                                                )}
                                                {touched.skill && errors.skill && (
                                                    <FormHelperText error id="standard-weight-helper-text--skill">
                                                        {errors.skill}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                            <FormControl
                                                sx={{ width: '100%' }}
                                                error={
                                                    Boolean(touched.competence && errors.competence) || Boolean(errorValidate.competence)
                                                }
                                            >
                                                <InputLabel id="competence">Competências</InputLabel>
                                                <Select
                                                    labelId="competence"
                                                    onBlur={handleBlur}
                                                    multiple
                                                    name="competence"
                                                    id="competence"
                                                    value={values.competence}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            'competence',
                                                            typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                                                        )
                                                    }
                                                    input={<OutlinedInput label="Competências" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                    disabled={isDisabled}
                                                >
                                                    {competences.competence == undefined &&
                                                        competences.map((competence) => (
                                                            <MenuItem key={competence.id} value={competence.desription}>
                                                                <Checkbox checked={values.competence.indexOf(competence.desription) > -1} />
                                                                <ListItemText primary={competence.desription} />
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                                {errorValidate.competence && (
                                                    <FormHelperText error id="standard-weight-helper-text--competence">
                                                        {errorValidate.competence}
                                                    </FormHelperText>
                                                )}
                                                {touched.competence && errors.competence && (
                                                    <FormHelperText error id="standard-weight-helper-text--competence">
                                                        {errors.competence}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>

                                        {((params.action !== 'view' && params.action !== 'edit') ||
                                            (valuesEdit && valuesEdit.schedulingPost)) && (
                                            <Grid item xs={6} sm={0} sx={{ marginTop: 0 }}>
                                                <SchedulingFormInput
                                                    schedule={values.schedulingPost}
                                                    setSchedule={handleChange}
                                                    id="schedulingPost"
                                                    label="Agendar Publicação na Timeline"
                                                    minDate={new Date()}
                                                    disabled={params.action === 'view'}
                                                />
                                            </Grid>
                                        )}

                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            {values.description && (
                                                <Editor
                                                    editorStyle={{
                                                        height: '200px',
                                                        width: '100%',
                                                        border: '1px solid #DCDCDC',
                                                        borderRadius: '25px',
                                                        padding: '20px'
                                                    }}
                                                    toolbar={{
                                                        options: ['inline', 'list', 'textAlign', 'fontFamily', 'fontSize', 'link'],
                                                        inline: {
                                                            options: ['bold', 'italic', 'underline']
                                                        },
                                                        list: {
                                                            options: ['unordered', 'ordered', 'indent', 'outdent']
                                                        },
                                                        textAlign: {
                                                            options: ['left', 'center', 'right']
                                                        },
                                                        fontFamily: {
                                                            options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana']
                                                        }
                                                    }}
                                                    editorState={values.description}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={(e) => setFieldValue('description', e)}
                                                    disabled={isDisabled}
                                                />
                                            )}
                                        </Grid>

                                        <Grid item xs={12} sm={8} sx={{ marginTop: 3 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <h3>Arquivos</h3>
                                                    <Demo>
                                                        {valuesEdit.file.length > 0 &&
                                                            valuesEdit.file.map((value) => (
                                                                <List dense={dense} key={value.id}>
                                                                    <ListItem>
                                                                        <ListItemAvatar>
                                                                            <Avatar src={value.url} />
                                                                        </ListItemAvatar>
                                                                        <ListItemText primary="Upload" secondary={value.name} />
                                                                    </ListItem>
                                                                </List>
                                                            ))}
                                                    </Demo>
                                                </Grid>
                                            </Grid>

                                            {params.action !== 'edit' && params.action !== 'view' && (
                                                <Button variant="text" onClick={AddObject}>
                                                    Adicionar objeto de aprendizado
                                                    <AddCircleOutlineRoundedIcon />
                                                </Button>
                                            )}
                                            {showAddObject && (
                                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="objects">
                                                    <FormControlLabel
                                                        value={3}
                                                        control={<Radio />}
                                                        label="Imagem"
                                                        onChange={optionObject}
                                                    />
                                                    <FormControlLabel value={1} control={<Radio />} label="Vídeo" onChange={optionObject} />
                                                </RadioGroup>
                                            )}
                                        </Grid>
                                        {typeObject == 3 && (
                                            <>
                                                <Grid container>
                                                    {inputFields.map((data, index) => {
                                                        const { url } = data;
                                                        return (
                                                            <Grid container spacing={matchDownSM ? 0 : 0} sx={{ marginLeft: 2 }}>
                                                                <Grid item xs={12} sm={2} sx={{ marginTop: 2 }} key={index}>
                                                                    <Button variant="contained" component="label" size="small">
                                                                        Upload
                                                                        <input
                                                                            type="file"
                                                                            name="file"
                                                                            opacity={opacity}
                                                                            id="file"
                                                                            style={{ display: 'none' }}
                                                                            label="Foto"
                                                                            disabled={isDisabled}
                                                                            onChange={(evnt) =>
                                                                                handleChangeFile(index, evnt.target.files[0])
                                                                            }
                                                                        />
                                                                    </Button>
                                                                    <b>
                                                                        {uploadedFiles[index] !== undefined
                                                                            ? uploadedFiles[index].name
                                                                            : ''}
                                                                    </b>
                                                                </Grid>
                                                                <Grid xs={12} sm={4} sx={{ marginTop: 2 }}>
                                                                    {inputFields.length !== 1 ? (
                                                                        <Button variant="text" onClick={() => removeInputFields(index)}>
                                                                            <DeleteOutlineOutlinedIcon />
                                                                        </Button>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    <Button variant="text" onClick={addInputField}>
                                                                        <AddOutlinedIcon />
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </>
                                        )}
                                        {typeObject == 1 && (
                                            <>
                                                <Grid container>
                                                    {inputFields.map((data, index) => {
                                                        const { url } = data;
                                                        return (
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                marginTop={0.5}
                                                                sx={{
                                                                    marginLeft: 2,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'flex-start'
                                                                }}
                                                            >
                                                                <Grid item xs={8} sm={4}>
                                                                    <TextField
                                                                        fullWidth
                                                                        size={'small'}
                                                                        label={'Vimeo'}
                                                                        placeholder={'Exemplo: https://vimeo.com/731428333'}
                                                                        value={inputFields[index]['vimeoUrl']}
                                                                        error={Boolean(
                                                                            inputFields[index]['vimeoUrl']?.length > 0 && !validVimeo
                                                                        )}
                                                                        onChange={handleChangeVimeo(index)}
                                                                        disabled={uploadedFiles[index] !== undefined}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={2} sm={1} sx={{ textAlign: 'center' }} key={index}>
                                                                    <b>- ou -</b>
                                                                </Grid>
                                                                <Grid item xs={2} sm={1} key={index}>
                                                                    <Button
                                                                        variant="contained"
                                                                        component="label"
                                                                        fullWidth
                                                                        size="small"
                                                                        disabled={inputFields[index]['vimeoUrl']?.length > 0}
                                                                    >
                                                                        Upload
                                                                        <input
                                                                            type="file"
                                                                            name="file"
                                                                            id="file"
                                                                            label="Foto"
                                                                            style={{ display: 'none' }}
                                                                            opacity={opacity}
                                                                            disabled={
                                                                                isDisabled || inputFields[index]['vimeoUrl']?.length > 0
                                                                            }
                                                                            onChange={(evnt) =>
                                                                                handleChangeFile(index, evnt.target.files[0])
                                                                            }
                                                                        />
                                                                    </Button>
                                                                    <b>
                                                                        {uploadedFiles[index] !== undefined
                                                                            ? uploadedFiles[index].name
                                                                            : ''}
                                                                    </b>
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    {inputFields.length !== 1 ? (
                                                                        <Button
                                                                            variant="text"
                                                                            onClick={() => removeInputFields(index)}
                                                                            size="small"
                                                                        >
                                                                            <DeleteOutlineOutlinedIcon />
                                                                        </Button>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    <Button variant="text" onClick={addInputField} size="small">
                                                                        <AddOutlinedIcon />
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                    <Grid container alignItems="end" justifyContent="end" sx={{ mt: 3 }}>
                                        <Grid item>
                                            <Box sx={{ mt: 2, mr: 3 }}>
                                                <ThemeProvider theme={themeButton}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="button"
                                                            variant="contained"
                                                            color="neutral"
                                                            onClick={() => navigate(-1)}
                                                        >
                                                            Voltar
                                                        </Button>
                                                    </AnimateButton>
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                        {params.action === 'view' ? (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            // disabled={isSubmitting}
                                                            component={Link}
                                                            to={`/postagem/${params.id}/edit`}
                                                            fullWidth
                                                            size="large"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Editar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        ) : (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting || disableButton}
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Salvar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                </form>
                            </div>
                        </MainCard>
                    </>
                )}
            </Formik>
        </>
    );
};

export default Library;
