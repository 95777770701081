import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { Box, Grid, TextField } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { TablePaginationActions } from '../../../components/Pagination/TablePaginationActions';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Link, useNavigate } from 'react-router-dom';
import BasicModal from '../../../components/Modal/BasicModal';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import { MENU_OPEN } from 'store/actions';
import { backPublicationToTop, changeStatusPublication, deletePublication, getPublication, searchPublication } from 'services/publication';
import 'react-toggle/style.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import Filter from '../../../components/Inputs/InputFilter';
import UpgradeOutlinedIcon from '@mui/icons-material/UpgradeOutlined';
import Toggle from 'react-toggle';
import debounce from 'lodash.debounce';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#015641',
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

export default function GridPublication() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const rowsPerPage = useSelector((state) => state.publication.rowsPerPage);
    const page = useSelector((state) => state.publication.page);
    const [publications, setPublications] = React.useState([]);
    const [openModalFilter, setOpenModalFilter] = React.useState(false);
    const [success, setSuccess] = React.useState('');
    const [error, setError] = React.useState('');
    const [idDestroyPublication, setIdDestroyPublication] = React.useState('');
    const [openModal, setOpenModal] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');
    const idPublication = useSelector((state) => state.publication.idPublication);
    var parse = require('html-react-parser');
    const filterType = ([] = ['Todos', 'Postagens', 'Oportunidades', 'Anúncios']);

    const debouncedSearch = React.useCallback(
        debounce((search) => searchPublications(idPublication, search), 300),
        []
    );

    React.useEffect(() => {
        getAllPublications(idPublication);
    }, [page, rowsPerPage, idPublication]);

    React.useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'publicacoes');
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: 'publicacoes' });
        }
    }, []);

    const handleOpenDestroy = (idDestroy) => {
        setIdDestroyPublication(idDestroy);
        setOpenModal(true);
    };

    const withLink = (to, children) => <Link to={to}>{children}</Link>;
    const actions = [
        { icon: withLink('/nova_postagem', <DynamicFeedOutlinedIcon style={{ color: '#015641' }} />), name: 'Nova postagem' },
        { icon: withLink('/nova_oportunidade', <BusinessCenterOutlinedIcon style={{ color: '#015641' }} />), name: 'Nova oportunidade' },
        { icon: withLink('/novo_anuncio', <CampaignOutlinedIcon style={{ color: '#015641' }} />), name: 'Novo anúncio' }
    ];

    const handleCloseModal = () => setOpenModal(false);

    const handleChangePage = (publication, newPage) => {
        dispatch({ type: 'SET_PAGE_PUBLICATION', payload: newPage });
    };

    const handleChangeRowsPerPage = (publication) => {
        dispatch({ type: 'SET_ROWS_PER_PAGE_PUBLICATION', payload: parseInt(publication.target.value, 10) });
        dispatch({ type: 'SET_PAGE_PUBLICATION', payload: 0 });
    };

    const getAllPublications = (idPublication, descriptionPost) => {
        let idType = idPublication === '' ? idPublication : 0;
        getPublication(page + 1, rowsPerPage, idType, descriptionPost).then((resp) => setPublications(resp.data));
    };

    const searchPublications = (idPublication, descriptionPost) => {
        let idType = idPublication === '' ? idPublication : idPublication;
        searchPublication(idType, descriptionPost).then((resp) => setPublications(resp.data));
    };

    const backTheTop = (idPub) => {
        backPublicationToTop(idPub)
            .then((resp) => {
                getAllPublications(idPublication);
                setError('');
                setSuccess(resp.data.success);
                setTimeout(() => {
                    setSuccess('');
                }, 3000);
            })
            .catch((e) => {
                setSuccess('');
                setError(e.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    };

    const deletePublicationById = () => {
        handleCloseModal();
        deletePublication(idDestroyPublication)
            .then((resp) => {
                getAllPublications(idPublication);
                setError('');
                setSuccess(resp.data.success);
                setIdDestroyPublication('');
                setTimeout(() => {
                    setSuccess('');
                }, 3000);
            })
            .catch((e) => {
                console.log(e);
                setSuccess('');
                setError(e.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    };

    const changeStatusActive = (event) => {
        const id = event.target.id;
        changeStatusPublication(id)
            .then((resp) => getAllPublications())
            .catch((resp) => getAllPublications());
    };

    const getStatus = (publication) => {
        if (publication.typePublication === 'Anúncio') {
            if (publication.advertisement.typeAdvertisement === 'Curso') {
                if (
                    publication.advertisement.course.scheduling_timeline &&
                    new Date(publication.advertisement.course.scheduling_timeline) > new Date()
                ) {
                    return 'Agendado';
                } else if (publication.publicationActive === 1) {
                    return 'Publicado';
                }

                return 'Desativado';
            }

            if (publication.advertisement.typeAdvertisement === 'Evento') {
                if (
                    publication.advertisement.event.scheduling_timeline &&
                    new Date(publication.advertisement.event.scheduling_timeline) > new Date()
                ) {
                    return 'Agendado';
                } else if (publication.publicationActive === 1) {
                    return 'Publicado';
                }

                return 'Desativado';
            }
            return 'Publicado';
        }

        if (publication.typePublication === 'Postagem') {
            if (publication.post.schedulingPost && new Date(publication.post.schedulingPost) > new Date()) {
                return 'Agendado';
            } else if (publication.publicationActive === 1) {
                return 'Publicado';
            }

            return 'Desativado';
        }

        if (publication?.typePublication === 'Oportunidade') {
            if (publication.opportunity.schedulingPost && new Date(publication.opportunity.schedulingPost) > new Date()) {
                return 'Agendado';
            } else if (publication.publicationActive === 1) {
                return 'Publicado';
            }

            return 'Desativado';
        }

        return 'Publicado';
    };

    React.useEffect(() => {
        if (searchTerm) {
            debouncedSearch(searchTerm);
        } else {
            getAllPublications(idPublication);
        }
    }, [searchTerm]);

    return (
        <>
            <BasicModal
                open={openModal}
                title="Excluir Arquivo"
                handleClose={handleCloseModal}
                description="Tem certeza que deseja excluir o publicação?"
                onDelete={deletePublicationById}
            />
            <TableContainer sx={{ mt: 2, height: '100%', padding: 3 }} component={Paper}>
                <Grid container spacing={matchDownSM ? 2 : 2}>
                    <Grid item xs={12} sm={6} md={8} sx={{ marginTop: 3 }}>
                        <h1
                            style={{
                                font: 'normal normal bold 35px/44px Roboto, sans-serif',
                                letterSpacing: '0px',
                                color: 'var(--unnamed-color-015641)',
                                Color: '#015641',
                                opacity: 1,
                                padding: 15,
                                marginLeft: '2%'
                            }}
                        >
                            <BallotOutlinedIcon /> PUBLICAÇÕES
                        </h1>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ marginTop: 8 }}>
                        <Box display="flex" justifyContent="right" alignItems="right" marginRight={2}>
                            <TextField
                                id="search"
                                label="Pesquisar"
                                fullWidth
                                variant="outlined"
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <hr style={{ width: '95%', marginTop: 0 }}></hr>
                <Grid container spacing={matchDownSM ? 2 : 2}>
                    <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                        <h3
                            style={{
                                font: 'normal normal 300 18px/22px Roboto, sans-serif',
                                letterSpacing: '0px',
                                Color: '#00000',
                                opacity: 1,
                                padding: 15,
                                marginLeft: '2%'
                            }}
                        >
                            Gerencie as publicações
                        </h3>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ marginTop: 5 }}>
                        <Box display="flex" justifyContent="right" alignItems="right" marginRight={2}>
                            <Filter filterType={filterType} />
                        </Box>
                    </Grid>
                </Grid>
                {error || success ? (
                    <Snackbar open={true} autoHideDuration={6000}>
                        <Alert
                            severity={error ? 'error' : success ? 'success' : ''}
                            sx={{
                                width: '100%',
                                backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                color: '#FFF'
                            }}
                        >
                            {error ? error : success ? success : ''}
                        </Alert>
                    </Snackbar>
                ) : (
                    ''
                )}
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Postar</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Voltar ao topo</StyledTableCell>
                            <StyledTableCell>Tipo de publicação</StyledTableCell>
                            <StyledTableCell>Descrição</StyledTableCell>
                            <StyledTableCell align="center">Ações</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {publications.data &&
                            publications.data.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell>
                                        <Toggle
                                            id={`${row.id}`}
                                            checked={row.publicationActive === 1 ? true : false}
                                            onChange={changeStatusActive}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>{getStatus(row)}</StyledTableCell>
                                    <StyledTableCell>
                                        <IconButton aria-label="Voltar ao topo" onClick={() => backTheTop(row.id)}>
                                            <UpgradeOutlinedIcon />
                                        </IconButton>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {(row.id_post !== null && 'Postagem') ||
                                            (row.id_advertisement !== null && 'Anúncio') ||
                                            (row.id_opportunity !== null && 'Oportunidade')}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {(row.id_opportunity !== null && (
                                            <span dangerouslySetInnerHTML={{ __html: row.opportunity.description }} />
                                        )) ||
                                            (row.id_post !== null && <span dangerouslySetInnerHTML={{ __html: row.post.description }} />) ||
                                            (row.advertisement.id_event !== null && (
                                                <span dangerouslySetInnerHTML={{ __html: row.advertisement.event.title }} />
                                            )) ||
                                            (row.advertisement.id_course !== null && (
                                                <span dangerouslySetInnerHTML={{ __html: row.advertisement.course.name }} />
                                            )) ||
                                            (row.advertisement.id_library !== null && (
                                                <span dangerouslySetInnerHTML={{ __html: row.advertisement.library.title }} />
                                            ))}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ width: 160 }} align="center">
                                        <Tooltip title="Visualizar">
                                            <IconButton
                                                onClick={() =>
                                                    navigate({
                                                        pathname: `/${
                                                            (row.id_post !== null && 'postagem') ||
                                                            (row.id_opportunity !== null && 'oportunidade') ||
                                                            (row.advertisement !== null && 'anuncio')
                                                        }/${row.id}/view`
                                                    })
                                                }
                                            >
                                                <ViewIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Editar">
                                            <IconButton
                                                onClick={() =>
                                                    navigate({
                                                        pathname: `/${
                                                            (row.id_post !== null && 'postagem') ||
                                                            (row.id_opportunity !== null && 'oportunidade') ||
                                                            (row.advertisement !== null && 'anuncio')
                                                        }/${row.id}/edit`
                                                    })
                                                }
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Excluir">
                                            <IconButton onClick={() => handleOpenDestroy(row.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={3}
                                count={publications.total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'Registros por Página'
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <SpeedDial
                direction="left"
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                FabProps={{
                    sx: {
                        bgcolor: '#015641',
                        '&:hover': {
                            bgcolor: '#87a851'
                        }
                    }
                }}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={(e) => (action.operation === 'filtros' ? [e.stopPropagation(), setOpenModalFilter(true)] : '')}
                    />
                ))}
            </SpeedDial>
        </>
    );
}
