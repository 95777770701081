import { Graphic, GridFilter, GridMainContainer, LoadingContainer } from './styles';

import GraphicBar from '../graphicBar/index';
import FilterDates from '../filterDates';
import { getLibraryBI } from 'services/bi';
import { useEffect, useState } from 'react';
import FilterList from '../filterList';
import { CircularProgress, Grid } from '@mui/material';
import { GraphicLine } from '../graphicLine';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pt-br';

const BILibrary = () => {
    const [loadingPage, setLoadingPage] = useState(true);
    const [allData, setAllData] = useState([]);
    const [filters, setFilters] = useState({});
    const [acessosData, setAcessosData] = useState([]);
    const [cursosData, setCursosData] = useState([]);
    const [eventosData, setEventosData] = useState([]);
    const [audiosData, setAudiosData] = useState([]);
    const [imagensData, setImagensData] = useState([]);
    const [arquivosData, setArquivosData] = useState([]);
    const [videosData, setVideosData] = useState([]);

    const types = {
        1: 'videos',
        2: 'arquivos',
        3: 'imagens',
        4: 'cursos',
        5: 'eventos',
        6: 'audios',
        7: 'arquivos',
        8: 'arquivos'
    };
    const filtered = useSelector((state) => state.bi.filtered);
    const speakersSelect = useSelector((state) => state.bi.speakerFilter);
    const competenceFilter = useSelector((state) => state.bi.competenceFilter);
    const initialDateFilter = useSelector((state) => state.bi.initialDate);

    const getData = async () => {
        await getLibraryBI()
            .then((response) => {
                setFilters(response.filter);
                return response;
            })
            .then((response) => {
                setAllData(response.content);
                setLoadingPage(false);
            });
    };

    const formatAcessosData = () => {
        const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
        const filteredData = allData.filter((data) => {
            if (competenceFilter) {
                return data.competences.some((competence) => competenceFilter.includes(competence.id_competence));
            }
            return true;
        });
        if (filteredData.length > 0) {
            const metrics = {
                cursos: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                eventos: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                audios: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                imagens: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                arquivos: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                videos: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            };
            filteredData.map((data) => {
                data.resources.map((resource) => {
                    const type = types[resource.id_type];
                    for (let i = 0; i < data.metrics.view.length; i++) {
                        if (initialDateFilter) {
                            if (data.metrics.view[i].date === initialDateFilter) {
                                metrics[type][i] += data.metrics.view[i].value;
                            }
                        } else {
                            metrics[type][i] += data.metrics.view[i].value;
                        }
                    }
                });
            });
            const graphic = [];
            const start = moment().subtract(11, 'months');
            const end = moment();
            let m = 0;
            while (start.isSameOrBefore(end, 'month')) {
                graphic[m] = [
                    start.format('MMM/YY'),
                    metrics.cursos[start.month()],
                    metrics.eventos[start.month()],
                    metrics.audios[start.month()],
                    metrics.imagens[start.month()],
                    metrics.arquivos[start.month()],
                    metrics.videos[start.month()]
                ];
                start.add(1, 'month');
                m++;
            }

            setAcessosData([acessosData[0], ...graphic]);
        }
    };
    const sortedData = (dataType = []) => {
        let sortedData = allData
            .map((data) => ({
                ...data,
                metrics: {
                    like: data.metrics.like.filter((like) => (initialDateFilter ? like.date === initialDateFilter : true)),
                    view: data.metrics.view.filter((view) => (initialDateFilter ? view.date === initialDateFilter : true))
                }
            }))
            .filter((curso) => {
                // Competence Filter
                if (competenceFilter?.length > 0) {
                    return curso.competences.some((competence) => competenceFilter.includes(competence.id_competence));
                } else {
                    return true;
                }
            })
            .filter((curso) => curso.resources.some((type) => dataType.includes(type.id_type)))
            .sort((c1, c2) => {
                const c1Total = c1.metrics.view.reduce((a, b) => a + (b.value || 0), 0);
                const c2Total = c2.metrics.view.reduce((a, b) => a + (b.value || 0), 0);
                return c1Total < c2Total ? 1 : c1Total > c2Total ? -1 : 0;
            })
            .slice(0, 7)
            .map((curso, key) => [curso.title.substring(0, 20), curso.metrics.view.reduce((a, b) => a + (b.value || 0), 0)]);

        return sortedData;
    };
    const formatVideosData = () => {
        let graphic = [['Video', 'Acessos']];
        graphic.push(...sortedData([1]));
        setVideosData(() => graphic);
    };
    const formatArquivosData = () => {
        let graphic = [['Arquivo', 'Acessos']];
        graphic.push(...sortedData([2, 7, 8]));
        setArquivosData(() => graphic);
    };
    const formatCursosData = () => {
        const graphic = [['Curso', 'Acessos']];
        graphic.push(...sortedData([4]));
        setCursosData(() => graphic);
    };
    const formatEventosData = () => {
        let graphic = [['Evento', 'Acessos']];
        graphic.push(...sortedData([5]));
        setEventosData(() => graphic);
    };
    const formatAudiosData = () => {
        let graphic = [['Audio', 'Acessos']];
        graphic.push(...sortedData([6]));
        setAudiosData(() => graphic);
    };
    const formatImagensData = () => {
        let graphic = [['Imagem', 'Acessos']];
        graphic.push(...sortedData([3]));
        setImagensData(() => graphic);
    };

    useEffect(() => {
        getData();
    }, []);
    useEffect(() => {
        if (filters.typeResource) {
            setAcessosData(() => [['Meses', 'Cursos', 'Eventos', 'Áudios', 'Imagens', 'Arquivos', 'Videos']]);
        }
    }, [filters]);
    useEffect(() => {
        formatArquivosData();
        formatVideosData();
        formatAcessosData();
        formatCursosData();
        formatEventosData();
        formatAudiosData();
        formatImagensData();
    }, [allData, competenceFilter, initialDateFilter]);

    if (loadingPage)
        return (
            <LoadingContainer>
                <CircularProgress />
            </LoadingContainer>
        );
    return (
        <GridMainContainer container spacing={2}>
            <Grid item xs={9}>
                <GridMainContainer container spacing={2}>
                    <Grid item xs={12}>
                        <Graphic>
                            <h2>Acessos na Biblioteca</h2>
                            <GraphicLine data={acessosData} />
                        </Graphic>
                    </Grid>
                    {cursosData.length > 1 && (
                        <Grid item xs={12} md={6}>
                            <Graphic>
                                <h2>Cursos</h2>
                                <GraphicBar
                                    allData={cursosData}
                                    width={'450px'}
                                    options={{ title: 'Cursos', colors: ['#ED9F4A'], legend: { position: 'none' } }}
                                />
                            </Graphic>
                        </Grid>
                    )}
                    {eventosData.length > 1 && (
                        <Grid item xs={12} md={6}>
                            <Graphic>
                                <h2>Eventos</h2>
                                <GraphicBar
                                    allData={eventosData}
                                    width={'450px'}
                                    options={{ colors: ['#D35E4B'], legend: { position: 'none' } }}
                                />
                            </Graphic>
                        </Grid>
                    )}
                    {audiosData.length > 1 && (
                        <Grid item xs={12} md={6}>
                            <Graphic>
                                <h2>Audios</h2>
                                <GraphicBar
                                    allData={audiosData}
                                    width={'450px'}
                                    options={{ colors: ['#51A744'], legend: { position: 'none' } }}
                                />
                            </Graphic>
                        </Grid>
                    )}
                    {imagensData.length > 1 && (
                        <Grid item xs={12} md={6}>
                            <Graphic>
                                <h2>Imagens</h2>
                                <GraphicBar
                                    allData={imagensData}
                                    width={'450px'}
                                    options={{ colors: ['#73A4F7'], legend: { position: 'none' } }}
                                />
                            </Graphic>
                        </Grid>
                    )}
                    {arquivosData.length > 1 && (
                        <Grid item xs={12} md={6}>
                            <Graphic>
                                <h2>Arquivos</h2>
                                <GraphicBar
                                    allData={arquivosData}
                                    width={'450px'}
                                    options={{ colors: ['#8144A7'], legend: { position: 'none' } }}
                                />
                            </Graphic>
                        </Grid>
                    )}
                    {videosData.length > 1 && (
                        <Grid item xs={12} md={6}>
                            <Graphic>
                                <h2>Videos</h2>
                                <GraphicBar
                                    allData={videosData}
                                    width={'450px'}
                                    options={{ colors: ['#DE71CD'], legend: { position: 'none' } }}
                                />
                            </Graphic>
                        </Grid>
                    )}
                </GridMainContainer>
            </Grid>
            <GridFilter item xs={2}>
                <FilterDates />
                <FilterList competences={filters?.competence} originFilter="competence" title="Por Competência" />
            </GridFilter>
            {/* <GridMain item xs={4.4}>
                <GraphicBar />
            </GridMain>
            <GridMain item xs={4.3}>
                <GraphicBar />
            </GridMain> */}
        </GridMainContainer>
    );
};

export default BILibrary;
