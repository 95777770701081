export const initialState = {
    page: 0,
    rowsPerPage: 10,
    nameList: ''
};

// ==============================|| AUTH REDUCER ||============================== //

const listReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_PAGE_LIST':
            return {
                ...state,
                page: actions.payload
            };
        case 'SET_ROWS_PER_PAGE_LIST':
            return {
                ...state,
                rowsPerPage: actions.payload
            };
        case 'SET_NAMELIST_LIST_FILTER':
            return {
                ...state,
                nameList: actions.payload
            };
        case 'SET_CLEAR_LIST_FILTER':
            return {
                ...state,
                nameList: ''
            };
        default:
            return state;
    }
};

export default listReducer;
